import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import { withRouter } from "react-router";

import IntlMessages from "util/IntlMessages";
import IconButton from "@material-ui/core/IconButton";
import {Scrollbars} from "react-custom-scrollbars";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import StudentAvatar from 'components/Avatar/StudentAvatar';
import SentMessageCell from "./Chats/SentMessageCell";
import ReceivedMessageCell from "./Chats/ReceivedMessageCell";
import {FormattedMessage} from "react-intl";
import RightsideIcon from "@material-ui/icons/Info";
import ConversationRightSidebar from "./ConversationRightSidebar";
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Chip from '@material-ui/core/Chip';

import {
    schoolMessengerFetchSelectedConversation,
    schoolMessengerAddNewWatcherSelectedConversation,
    schoolMessengerOnScrollSelectedConversation,
  schoolMessengerSelectedConversationFetchMore,
    schoolMessengerSubmitMessage,
    schoolMessengerMarkMessagesAsRead,
    schoolMessengerRemoveNewWatcherSelectedConversation
} from 'redux/actions/SchoolMessenger'

const drawerWidth = 280;

const styles = theme => ({
    drawer: {
        flexShrink: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerShift: {
        flexShrink: 0,
        width:0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerPaper: {
        width: drawerWidth,
        height: 'calc(100vh - 153px)',
        position: 'relative',
        borderLeft: 'solid 1px #e9ecef',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
});


class ShowConversation extends React.Component {

    //static whyDidYouRender = true;

    state = {
        newMessage: {},
        //conversation: null,
        //showOlderMessageLoader: false,
        //autoScrollToBottom: true,
        //allMessagesLoaded: false,
        rightSidebarOpen: false,
        //limit: 20
    };


    componentDidMount() {

        // Sync Data
        if(this.props.match.params.id){
            if(this.props.match.params.id && this.props.schoolMessenger.list.hasOwnProperty(this.props.match.params.id)){
                this.props.schoolMessengerAddNewWatcherSelectedConversation({
                    schoolId: this.props.auth.user.school,
                    conversationId: this.props.match.params.id
                });
                //this.refs.ChatWindowScrollbar && this.refs.ChatWindowScrollbar.scrollToBottom();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {


        if(this.state !== nextState){
            return true;
        }

        if(this.props.match.params.id !== nextProps.match.params.id){
            return true;
        }

        if(this.props.conversationMessages !== nextProps.conversationMessages){
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //console.log('componentDidUpdate')


        if(this.props.match.params.id !== prevProps.match.params.id){
            this.props.schoolMessengerFetchSelectedConversation({
                schoolId: this.props.auth.user.school,
                conversationId: this.props.match.params.id,
                //limit:this.state.limit
                limit: 20
            });

            let nMsg = this.state.newMessage;
            if(!this.state.newMessage.hasOwnProperty(this.props.match.params.id)){
                nMsg[this.props.match.params.id] = '';
                this.setState({
                    newMessage: nMsg
                });
            }


            this.props.schoolMessengerRemoveNewWatcherSelectedConversation();

            this.props.schoolMessengerAddNewWatcherSelectedConversation({
                schoolId: this.props.auth.user.school,
                conversationId: this.props.match.params.id
            });

            //this.refs.ChatWindowScrollbar.scrollToBottom();
        }




        /*
        if(this.props.conversationFetched){

            if(this.props.currentConversation !== undefined) {
                if(this.props.conversationMessages !== prevProps.conversationMessages && this.props.match.params.id === this.props.currentConversation.family){

                    if(
                      (this.props.conversationMessages.length && this.props.conversationMessages.length <= this.state.limit)
                      || (this.refs.ChatWindowScrollbar.getValues().top >= 0.90 && prevProps.conversationMessages[prevProps.conversationMessages.length-1].id !== this.props.conversationMessages[this.props.conversationMessages.length-1].id))  {
                        this.refs.ChatWindowScrollbar.scrollToBottom();
                    }

                }
            }

        }*/


        if(this.refs.ChatWindowScrollbar && this.props.currentConversation !== undefined && this.props.conversationLastMessageId !== prevProps.conversationLastMessageId) {

            this.refs.ChatWindowScrollbar.scrollToBottom();
        }

    }


    componentWillUnmount() {
        // Unsync Data.
        this.props.schoolMessengerRemoveNewWatcherSelectedConversation();
    }

    handleRightSidebarToggle = () => {
        this.setState(state => ({ rightSidebarOpen: !state.rightSidebarOpen }));
    };

    handleScrollReachTop = (values) => {

      let lastCreatedAt = false;

      if(this.props.conversationMessages){


          const messageListLenght = this.props.conversationMessages.length -1;


          //const lastItem = this.props.conversationMessages[messageListLenght];
          const lastItem = this.props.conversationMessages[0];

        if(lastItem){
          lastCreatedAt = lastItem.createdAt;
        }

      }



        const topValue = values.top;
        if (topValue <= 0.4 && !this.props.conversationFetchedAll && !this.props.conversationLoadingMore) {

            console.log('this.props.conversationMessages', this.props.conversationMessages)

            if(lastCreatedAt !== false){
                this.props.schoolMessengerSelectedConversationFetchMore({
                    schoolId: this.props.auth.user.school,
                    conversationId: this.props.match.params.id,
                    endAt: lastCreatedAt
                });
            }


           /*
            this.props.schoolMessengerFetchSelectedConversation({
                schoolId: this.props.auth.user.school,
                conversationId: this.props.match.params.id,
                //lastVisibleKey: this.props.conversationMessages[0].id,
                //limit:this.state.limit,
                limit:5,
                endAt: lastCreatedAt
            });
            */

           //console.log('handleScrollReachTop')
        } 
    };

    handleNewMessageBoxFocus = () => {

        // If lastMessage not read, will call markAsReadProcess

        let payload = {
            schoolId: this.props.auth.user.school,
            userId:  this.props.auth.user.uid,
            conversationId: this.props.match.params.id,
            lastMessageId: '',
            hasLastMessageUpdate: false,
            hasItemMessageUpdate: false,
            itemMessageUpdateList: {}
        };

        if(this.props.currentConversation.lastMessage.hasOwnProperty('readBy')) {
            // lastMessage has been read by someone
            if (!this.props.currentConversation.lastMessage.readBy.hasOwnProperty(this.props.auth.user.uid)) {
                // lastMessage has not been read by current user.
                payload.lastMessageId = this.props.currentConversation.lastMessage.mid;

                this.props.conversationMessages.forEach((messageItem) => {

                    if(messageItem.hasOwnProperty('readBy')){

                        if(messageItem.readBy.hasOwnProperty('office')){
                            if(!messageItem.readBy.hasOwnProperty(this.props.auth.user.uid) || !messageItem.readBy.office) {
                                // User not read message
                                payload.itemMessageUpdateList[messageItem.id] = true;
                                payload.hasItemMessageUpdate = true;
                            }

                        }else{

                            if(!messageItem.readBy.hasOwnProperty(this.props.auth.user.uid) || !messageItem.readBy.hasOwnProperty('office')) {
                                // User not read message
                                payload.itemMessageUpdateList[messageItem.id] = true;
                                payload.hasItemMessageUpdate = true;
                            }

                        }


                    }else{
                        payload.itemMessageUpdateList[messageItem.id] = true;
                        payload.hasItemMessageUpdate = true;
                    }
                });
            }
        }else{
            payload.lastMessageId = this.props.currentConversation.lastMessage.mid;
            this.props.conversationMessages.forEach((messageItem) => {

                if(messageItem.hasOwnProperty('readBy')){

                    if(messageItem.readBy.hasOwnProperty('office')){

                        if(!messageItem.readBy.hasOwnProperty(this.props.auth.user.uid) || !messageItem.readBy.office) {
                            // User not read message
                            payload.itemMessageUpdateList[messageItem.id] = true;
                            payload.hasItemMessageUpdate = true;

                        }

                    }else{

                        if(!messageItem.readBy.hasOwnProperty(this.props.auth.user.uid) || !messageItem.readBy.hasOwnProperty('office')) {
                            // User not read message
                            payload.itemMessageUpdateList[messageItem.id] = true;
                            payload.hasItemMessageUpdate = true;

                        }
                    }




                }else{
                    payload.itemMessageUpdateList[messageItem.id] = true;
                    payload.hasItemMessageUpdate = true;
                }
            });
        }

        if(payload.hasLastMessageUpdate || payload.hasItemMessageUpdate){
            this.props.schoolMessengerMarkMessagesAsRead(payload);
        }

    };


    handleSubmitMessage = () => {
        //this.state.newMessage[this.props.match.params.id]
        /*
        if (this.state.newMessage !== '') {

            const payload = {
                schoolId: this.props.auth.user.school,
                newMessage: this.state.newMessage,
                conversationId: this.props.match.params.id,
                userId: this.props.auth.user.uid,
                userType: this.props.auth.user.role,
                userProfile: this.props.auth.userProfile,
            };
            this.props.schoolMessengerSubmitMessage(payload);
            this.setState({
                newMessage: '',
            });
            this.refs.ChatWindowScrollbar.scrollToBottom();
        }
        */

        if (this.state.newMessage[this.props.match.params.id] !== '') {

            const payload = {
                schoolId: this.props.auth.user.school,
                newMessage: this.state.newMessage[this.props.match.params.id],
                conversationId: this.props.match.params.id,
                userId: this.props.auth.user.uid,
                userType: this.props.auth.user.role,
                userProfile: this.props.auth.userProfile,
            };
            this.props.schoolMessengerSubmitMessage(payload);

            let nMsg = this.state.newMessage;
            nMsg[this.props.match.params.id] = '';

            this.setState({
                newMessage: nMsg,
            });
            this.refs.ChatWindowScrollbar.scrollToBottom();
        }

    };


    render() {

        const {auth, locale, conversationFetched, currentConversation, conversationMessages, selectedConversationLoading, guardianArray} = this.props;

        const { classes} = this.props;


        //console.log('conversationMessages.length', conversationMessages.length)

        if(!selectedConversationLoading && !conversationFetched){
            return (
              <div className="chat-box-container">
                  <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                     <IntlMessages id="systemMessage_welcome"/>
                  </div>
              </div>
            )
        }

        if(!this.props.conversationMessages.length && !conversationFetched){
            return (
              <div className="chat-box-container">
                  <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                      <CircularProgress size={30}/>
                  </div>
              </div>
            )
        }

        if(this.props.match.params.id && this.props.schoolMessenger.list.hasOwnProperty(this.props.match.params.id)){
            return (
              <div className="chat-box-container">
                  <div className="chat-box-main">
                      <div className="chat-main-header">
                          <div className="chat-main-header-info d-flex justify-content-between w-100">

                              <div className="chat-contact-name d-flex justify-content-between">
                                  {currentConversation.students
                                    ? Object.values(currentConversation.students).map((studentItem, index, array) =>

                                      studentItem !== true &&
                                      <div className={array.length >= 1 ? "student-item d-flex mr-3" : "student-item d-flex"} key={index}>
                                          <StudentAvatar
                                            data={studentItem.profile}
                                            size={40}
                                            classes="mr-2"
                                          />
                                          <div className="media-body align-self-center">
                                              <div className="d-flex justify-content-between">
                                                  <div className="heading name text-left">
                                                      {
                                                          locale.locale === 'ja' || locale.locale === 'zh'
                                                            ? studentItem.profile.nameFurigana
                                                            : studentItem.profile.nameRomaji
                                                      }

                                                  </div>

                                              </div>
                                              <div className="sub-heading">
                                                  <p className="sub-heading mb-0 text-left" style={{height:17, overflow: 'hidden'}}>

                                                      { Object.keys(studentItem.classes).length !== 0 && studentItem.classes.constructor === Object
                                                        ? Object.values(studentItem.classes).map((classItem, index) => {
                                                            if(index >= 1){
                                                                return(
                                                                  locale.locale === 'ja' || locale.locale === 'zh' ? ' & ' + classItem.profile.nameFurigana : ' & ' + classItem.profile.nameRomaji
                                                                )
                                                            }else{
                                                                return(
                                                                  locale.locale === 'ja' || locale.locale === 'zh' ? classItem.profile.nameFurigana : classItem.profile.nameRomaji
                                                                )
                                                            }
                                                        })
                                                        : <span><IntlMessages id="common.noClass"/></span>
                                                      }
                                                  </p>
                                              </div>
                                          </div>
                                      </div>

                                    )
                                    : <div></div>


                                  }

                              </div>

                              <IconButton className="ml-3 mr-1 d-block chat-btn" aria-label="Right Sidebar" onClick={this.handleRightSidebarToggle}>
                                  <RightsideIcon/>
                              </IconButton>
                          </div>

                      </div>

                      <div className="chat-main">
                          <Scrollbars ref="ChatWindowScrollbar" className="chat-list-scroll scrollbar" style={{height: this.props.width >= 1200 ? 'calc(100vh - 317px)' : 'calc(100vh - 300px)', behavior: 'smooth'}} onScrollFrame={this.handleScrollReachTop}>
                                {
                                    this.props.conversationFetchedAll ? (
                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: '24px 0' }}>
                                            <Chip disabled variant="outlined" label={ <IntlMessages id="common.noMoreMessage"/>}/>
                                        </div>
                                    ) : ""
                                }

                                {
                                    selectedConversationLoading
                                    ? <div className="d-flex align-items-center justify-content-center" style={{ padding: '24px 0' }}> <CircularProgress size={30}/></div>
                                    : ''
                                }

                                <div className="chat-main-content h-100">
                                    {conversationMessages.length === 0 ?
                                      <div className="d-flex align-items-center h-100 justify-content-center">
                                          <IntlMessages id="appMessenger.appBar.noData"/>
                                      </div>

                                      : <div className="h-100">
                                          {conversationMessages.map((message) => {
                                              //console.log('message', message)
                                              return (
                                                guardianArray.indexOf(message.createdBy.id) > -1
                                                      ? <ReceivedMessageCell
                                                        key={message.id}
                                                        message={message}
                                                        locale={locale}
                                                        conversation={currentConversation}
                                                      />
                                                      : <SentMessageCell
                                                        key={message.id}
                                                        message={message}
                                                        locale={locale}
                                                        conversation={currentConversation}
                                                      />
                                              )
                                          }


                                          )}
                                      </div>
                                    }

                                </div>

                          </Scrollbars>

                          <div className="chat-main-footer">
                              <div className="messenger-textarea-container">
                                  <FormattedMessage id="common.messengerBoxPlaceholder" >
                                      {(transValue)=>(
                                        <textarea
                                          className="border-0 form-control chat-textarea"
                                          onChange={(event) => {
                                              let nMsg = this.state.newMessage;
                                              nMsg[this.props.match.params.id] = event.target.value;
                                              this.setState({newMessage: nMsg})
                                              //this.setState({newMessage: event.target.value})
                                          }}
                                          value={this.state.newMessage[this.props.match.params.id]}
                                          placeholder={transValue}
                                          onFocus={this.handleNewMessageBoxFocus}
                                        />
                                      )}
                                  </FormattedMessage>
                              </div>

                              <div className="messenger-sendBtn-container">
                                  <IconButton
                                    onClick={this.handleSubmitMessage.bind(this)}
                                    aria-label="Send message">
                                      <i className="zmdi  zmdi-mail-send"/>
                                  </IconButton>
                              </div>
                          </div>

                      </div>

                  </div>

                  {this.state.rightSidebarOpen &&
                  <Drawer variant="persistent"
                          anchor="right"
                          className={classNames(classes.drawer, {
                              [classes.drawerShift]: !this.state.rightSidebarOpen,
                          })}

                          classes={{ paper: classes.drawerPaper }}
                          open={this.state.rightSidebarOpen}
                          onClose={this.handleRightSidebarToggle}>
                      <ConversationRightSidebar locale={locale} authUser={auth} data={currentConversation}/>

                  </Drawer>
                  }

              </div>
            )
        }


        return (
          <div className="d-flex h-100 w-100 align-items-center justify-content-center">
              <IntlMessages id="warningMessage.messenger_no_conversation_yet" />
          </div>
        );



    };
}

const mapStateToProps = (props, state) => {

    const conversationId = state.match.params.id;

    const { settings, auth, school, schoolMessenger} = props;

    const { locale, width } = settings;

    const {conversationFetched, conversationLoading: selectedConversationLoading, conversationFetchedAll, conversationLoadingMore, conversationLastMessageId} = schoolMessenger;

    //console.log('schoolMessenger', schoolMessenger)

    let currentConversation = [];
    let conversationMessages = [];
    let guardianArray = [];



    if(conversationFetched){
        currentConversation = schoolMessenger.list[conversationId];
        conversationMessages = _(schoolMessenger.conversationMessages)
          .mapValues((value, id)=>_.merge({}, value, {id}))
          .values()
          .value();

        if(currentConversation){
            if(currentConversation.hasGuardians){
                guardianArray = Object.keys(currentConversation.guardians);
            }

            conversationMessages.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
            conversationMessages.reverse();

        }
    }

    //console.log('mapStateToProps', conversationMessages)

    return {
        width, locale, auth, school,
        currentConversation, conversationFetched, conversationFetchedAll, conversationMessages, selectedConversationLoading, conversationLoadingMore, conversationLastMessageId,
        schoolMessenger, guardianArray
    }
};



export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps, {
        schoolMessengerFetchSelectedConversation,
        schoolMessengerAddNewWatcherSelectedConversation,
        schoolMessengerOnScrollSelectedConversation,
        schoolMessengerSelectedConversationFetchMore,
        schoolMessengerSubmitMessage,
        schoolMessengerMarkMessagesAsRead,
        schoolMessengerRemoveNewWatcherSelectedConversation
    }
  )
)(ShowConversation)