import React from 'react';
import { Provider, ReactReduxContext } from "react-redux";
import configureStore from './redux/store';
import 'config/firebase';
import App from './routes/App';

export const store = configureStore();

const MainApp = () =>
  <Provider store={store} context={ReactReduxContext}>
    <App/>
  </Provider>;

export default MainApp;