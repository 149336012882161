import {
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE,
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_SUCCESS,
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_FAILURE
} from "redux/constants/ActionTypes";

export const schoolUsersFetchSchoolUsersListActive = payload => {
  return {
    type: SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE,
    payload: payload
  };
};

export const schoolUsersFetchSchoolUsersListActiveSuccess = payload => {
  return {
    type: SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_SUCCESS,
    payload: payload
  };
};

export const schoolUsersFetchSchoolUsersListActiveFailure = payload => {
  return {
    type: SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_FAILURE,
    payload: payload
  };
};
