
import axios from 'axios';

 export const getTranslatedText = async (parsedText, toLang) => {
  let url = `${process.env.REACT_APP_GOOGLE_TRANSLATE_APIURL}?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_KEY}`;

   const headers = {
     'Content-Type': 'application/json',
   };

   const config = {
     headers: headers
   };

   const pay = {
     "q": parsedText,
     "target": toLang
   };

   const data = JSON.stringify(pay);

  return axios
    .post(url, data, config)
    .then(res => res.data)
    .then(response => response.data.translations[0].translatedText)
    .catch(error => {

      console.log('There was an error with the translation request: ', error);
      console.log('error 1', error.message)

      return 'Sorry there was an error. Perhaps your text was too long to translate.';
    });

};