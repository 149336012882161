import {
  SCHOOL_CALENDAR_CREATE_EVENT,
  SCHOOL_CALENDAR_CREATE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_CREATE_EVENT_FAILURE,
  SCHOOL_CALENDAR_UPDATE_EVENT,
  SCHOOL_CALENDAR_UPDATE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_UPDATE_EVENT_FAILURE,
  SCHOOL_CALENDAR_DELETE_EVENT,
  SCHOOL_CALENDAR_DELETE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_DELETE_EVENT_FAILURE,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_SUCCESS,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_FAILURE,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_SUCCESS,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_FAILURE,
  SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID
} from "redux/constants/ActionTypes";

export const schoolCalendarCreateEvent = payload => {
  return {
    type: SCHOOL_CALENDAR_CREATE_EVENT,
    payload: payload
  };
};

export const schoolCalendarCreateEventSuccess = payload => {
  return {
    type: SCHOOL_CALENDAR_CREATE_EVENT_SUCCESS,
    payload: payload
  };
};

export const schoolCalendarCreateEventFailure = payload => {
  return {
    type: SCHOOL_CALENDAR_CREATE_EVENT_FAILURE,
    payload: payload
  };
};

export const schoolCalendarUpdateEvent = payload => {
  return {
    type: SCHOOL_CALENDAR_UPDATE_EVENT,
    payload: payload
  };
};

export const schoolCalendarUpdateEventSuccess = payload => {
  return {
    type: SCHOOL_CALENDAR_UPDATE_EVENT_SUCCESS,
    payload: payload
  };
};

export const schoolCalendarUpdateEventFailure = payload => {
  return {
    type: SCHOOL_CALENDAR_UPDATE_EVENT_FAILURE,
    payload: payload
  };
};

export const schoolCalendarDeleteEvent = payload => {
  return {
    type: SCHOOL_CALENDAR_DELETE_EVENT,
    payload: payload
  };
};

export const schoolCalendarDeleteEventSuccess = payload => {
  return {
    type: SCHOOL_CALENDAR_DELETE_EVENT_SUCCESS,
    payload: payload
  };
};

export const schoolCalendarDeleteEventFailure = payload => {
  return {
    type: SCHOOL_CALENDAR_DELETE_EVENT_FAILURE,
    payload: payload
  };
};

export const schoolCalendarFetchEventListByDate = payload => {
  return {
    type: SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE,
    payload: payload
  };
};

export const schoolCalendarFetchEventListByDateSuccess = payload => {
  return {
    type: SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_SUCCESS,
    payload: payload
  };
};

export const schoolCalendarFetchEventListByDateFailure = payload => {
  return {
    type: SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_FAILURE,
    payload: payload
  };
};

export const schoolCalendarSyncEventListByAcademicYearId = payload => {
  return {
    type: SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
    payload: payload
  };
};

export const schoolCalendarSyncEventListByAcademicYearIdSuccess = payload => {
  return {
    type: SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_SUCCESS,
    payload: payload
  };
};

export const schoolCalendarSyncEventListByAcademicYearIdFailure = payload => {
  return {
    type: SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_FAILURE,
    payload: payload
  };
};

export const schoolCalendarUnSyncEventListByAcademicYearId = payload => {
  return {
    type: SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
    payload: payload
  };
};
