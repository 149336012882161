import { all, call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "config/firebase";
import firebase from 'firebase/app';
//import 'firebase/database';
//import 'firebase/storage';

import { SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE } from "redux/constants/ActionTypes";

import { schoolUsersFetchSchoolUsersListActiveSuccess, schoolUsersFetchSchoolUsersListActiveFailure } from "redux/actions";

// HELPERS

// REQUESTS

// FUNCTIONS
function* fetchSchoolUsersListActive({ payload }) {
  const { schoolId } = payload;
  try {
    const schoolUsersListActive = yield call(
      rsf.database.read,
      firebase
        .database()
        .ref(`schoolUsers/${schoolId}`)
        .orderByChild("active")
        .equalTo(true)
    );
    yield put(
      schoolUsersFetchSchoolUsersListActiveSuccess({
        schoolUsersListActive
      })
    );
  } catch (error) {
    yield put(schoolUsersFetchSchoolUsersListActiveFailure({ error }));
  }
}

// EXPORTS

// SYNCS

// WATCHERS

export default function* rootSaga() {
  yield all([takeEvery(SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE, fetchSchoolUsersListActive)]);
}
