import { all, call, fork, put, takeEvery, takeLatest, take, cancel } from "redux-saga/effects";
import { rsf } from "config/firebase";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

import {
  CLASS_CREATE_CLASS,
  CLASS_FETCH_CLASS,
  CLASS_LOAD_CLASS,
  CLASS_UPDATE_CLASS,
  CLASS_DELETE_CLASS,
  CLASS_FETCH_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ACTIVE,
  CLASS_UNSYNC_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ARCHIVED,
  CLASS_UNSYNC_CLASS_LIST_ARCHIVED,
  CLASS_FETCH_CLASS_LIST_TODAY,
  CLASS_ARCHIVE_CLASS,
  CLASS_UNARCHIVE_CLASS
} from "redux/constants/ActionTypes";

import {
  classCreateClassSuccess,
  classCreateClassFailure,
  classLoadClassSuccess,
  classLoadClassFailure,
  classFetchClassSuccess,
  classFetchClassFailure,
  classUpdateClassSuccess,
  classUpdateClassFailure,
  classDeleteClassSuccess,
  classDeleteClassFailure,
  classFetchClassListActiveSuccess,
  classFetchClassListActiveFailure,
  classSyncClassListActiveSuccess,
  classSyncClassListActiveFailure,
  classSyncClassListArchivedSuccess,
  classSyncClassListArchivedFailure,
  classFetchClassListTodaySuccess,
  classFetchClassListTodayFailure,
  classArchiveClassSuccess,
  classArchiveClassFailure,
  classUnArchiveClassSuccess,
  classUnArchiveClassFailure
} from "redux/actions/Classes";

import { showAlert } from "redux/actions/Setting";
import moment from "moment";

const auth = rsf.app.auth();

// HELPERS
const getUserClaims = async () =>
  await auth.currentUser
    .getIdTokenResult()
    .then(idTokenResult => {
      return idTokenResult.claims;
    })
    .catch(error => error);



// REQUESTS

// FUNCTIONS
function* fetchClass({ payload }) {
  const { schoolId, classId } = payload;

  try {
    const selectedClass = yield call(rsf.database.read, `/classes/${schoolId}/${classId}`);

    yield put(
      classFetchClassSuccess({
        selectedClass
      })
    );
  } catch (error) {
    yield put(classFetchClassFailure({ error }));
  }
}

function* loadClass({ payload }) {
  try {
    yield put(
      classLoadClassSuccess({
        selectedClass: payload
      })
    );
  } catch (error) {
    yield put(classLoadClassFailure({ error }));
  }
}

function* createClass({ payload }) {
  let { schoolId, createdClass, image } = payload;

  try {

    const currentUserClaims = yield call(getUserClaims);

    createdClass["cid"] = currentUserClaims.cid || '';
    createdClass["metadata"]["cid"] = currentUserClaims.cid || '';

    const classId = yield call(rsf.database.create, `/classes/${schoolId}/`, createdClass);


    createdClass["id"] = classId;
    createdClass["metadata"]["id"] = classId;

    if (createdClass.metadata.hasOwnProperty("students")) {
      if (createdClass.metadata.students === null) {
        createdClass["metadata"]["students"] = "";
      }
    }

    if (image) {
      let fileName = image.path
        .substring(image.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/classData/${classId}.${fileName.split(".")[1]}`;

      const uploadedFile = rsf.storage.uploadFile(path, image.file);

      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });

      // Wait for upload to complete
      yield uploadedFile;

      // Get URL for uploaded file
      const imageUrl = yield call(rsf.storage.getDownloadURL, path);

      createdClass["profile"] = Object.assign(createdClass.profile, { imageUrl });
      createdClass["stats"] = {
        totalStudents: 0,
        totalTeachers: 0
      }
    }

    yield call(rsf.database.update, `/classes/${schoolId}/${classId}`, createdClass);

    yield put(
      classCreateClassSuccess({
        createdClass: createdClass
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.class_create_success"
      })
    );
  } catch (error) {
    yield put(classCreateClassFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateClass({ payload }) {
  let { schoolId, classId, updatedClass, image } = payload;

  if (updatedClass.metadata.hasOwnProperty("students")) {
    if (updatedClass.metadata.students === null) {
      updatedClass["metadata"]["students"] = "";
    }
  }

  try {
    if (image) {
      let fileName = image.path
        .substring(image.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `/schools/${schoolId}/classes/${classId}/classImage.${fileName.split(".")[1]}`;

      const uploadedFile = rsf.storage.uploadFile(path, image.file);

      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });

      // Wait for upload to complete
      yield uploadedFile;

      // Get URL for uploaded file
      const imageUrl = yield call(rsf.storage.getDownloadURL, path);

      updatedClass = Object.assign(updatedClass, { imageUrl });
    }

    yield call(rsf.database.update, `/classes/${schoolId}/${classId}`, updatedClass);

    yield put(
      classUpdateClassSuccess({
        updatedClass: updatedClass
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.class_update_success"
      })
    );
  } catch (error) {
    yield put(classUpdateClassFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteClass({ payload }) {
  const { schoolId, classesList } = payload;

  const deletedClasses = classesList.reduce(
    (acc, classObj) =>
      Object.assign(acc, {
        [classObj.id]: null
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/classes/${schoolId}/`, deletedClasses);

    yield put(classDeleteClassSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.class_delete_success"
      })
    );
  } catch (error) {
    yield put(classDeleteClassFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* fetchClassListToday({ payload }) {
  try {
    let classListToday = null;

    if (payload.type === "byWeekDay") {
      // byWeekDay
      switch (payload.dateValue) {
        case 1:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/monday")
              .equalTo(true)
          );
          break;
        case 2:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/tuesday")
              .equalTo(true)
          );
          break;
        case 3:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/wednesday")
              .equalTo(true)
          );
          break;
        case 4:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/thursday")
              .equalTo(true)
          );
          break;
        case 5:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/friday")
              .equalTo(true)
          );
          break;
        case 6:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/saturday")
              .equalTo(true)
          );
          break;
        case 7:
          classListToday = yield call(
            rsf.database.read,
            firebase
              .database()
              .ref("classes/" + payload.schoolId)
              .orderByChild("profile/classDays/sunday")
              .equalTo(true)
          );
          break;
        default:
          break;
      }
    } else {
      // byDate as default
      // TODO: Add node path for class calendar schedule. Note: Not yet created.
      classListToday = yield call(
        rsf.database.read,
        firebase
          .database()
          .ref("classes/" + payload.schoolId)
          .orderByChild("metadata/active")
          .equalTo(true)
      );
    }

    yield put(
      classFetchClassListTodaySuccess({
        classListToday
      })
    );
  } catch (error) {
    yield put(classFetchClassListTodayFailure({ error }));
  }
}

function* fetchClassListActive({ payload }) {
  try {
    const classListActive = yield call(
      rsf.database.read,
      firebase
        .database()
        .ref("classes/" + payload.school)
        .orderByChild("metadata/active")
        .equalTo(true)
    );

    yield put(
      classFetchClassListActiveSuccess({
        classListActive
      })
    );
  } catch (error) {
    yield put(classFetchClassListActiveFailure({ error }));
  }
}

function* archiveClasses({ payload }) {
  const { schoolId, classesList } = payload;

  const archivedClasses = classesList.reduce(
    (acc, classObj) =>
      Object.assign(acc, {
        [classObj.id]: {
          ...classObj,
          metadata: {
            ...classObj.metadata,
            active: false,
            archived_at: moment()
              .utc()
              .valueOf()
          }
        }
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/classes/${schoolId}/`, archivedClasses);

    yield put(classArchiveClassSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.class_archive_success"
      })
    );
  } catch (error) {
    yield put(classArchiveClassFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* unArchiveClasses({ payload }) {
  const { schoolId, classesList } = payload;

  const unArchivedClasses = classesList.reduce(
    (acc, classObj) =>
      Object.assign(acc, {
        [classObj.id]: {
          ...classObj,
          metadata: {
            ...classObj.metadata,
            active: true,
            archived_at: ""
          }
        }
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/classes/${schoolId}/`, unArchivedClasses);

    yield put(classUnArchiveClassSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.class_unarchive_success"
      })
    );
  } catch (error) {
    yield put(classUnArchiveClassFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// EXPORTS

// SYNCS
function* syncClassListActive({ payload }) {
  const { schoolId } = payload;

  const sync = yield fork(
    rsf.database.sync,
    firebase
      .database()
      .ref(`classes/${schoolId}`)
      .orderByChild("metadata/active")
      .equalTo(true),
    {
      successActionCreator: classSyncClassListActiveSuccess,
      failureActionCreator: classSyncClassListActiveFailure
    }
  );

  // On unsync request, cancel sync.
  yield take(CLASS_UNSYNC_CLASS_LIST_ACTIVE);
  yield cancel(sync);
}

function* syncClassListArchived({ payload }) {
  const { schoolId } = payload;

  const sync = yield fork(
    rsf.database.sync,
    firebase
      .database()
      .ref(`classes/${schoolId}`)
      .orderByChild("metadata/active")
      .equalTo(false),
    {
      successActionCreator: classSyncClassListArchivedSuccess,
      failureActionCreator: classSyncClassListArchivedFailure
    }
  );

  // On unsync request, cancel sync.
  yield take(CLASS_UNSYNC_CLASS_LIST_ARCHIVED);
  yield cancel(sync);
}

// #TODO - we will use below sync in teacher page
// function * syncClassStudentListActive ({payload}) {
//   const {schoolId, classId} = payload;
//   const sync = yield fork(
//     rsf.database.sync,
//     firebase.database().ref(`student/${schoolId}`).orderByChild('metadata/classes/'+ classId).equalTo(true),
//     {
//       successActionCreator: studentSyncStudentListActiveSuccess,
//       failureActionCreator: studentSyncStudentListActiveFailure,
//     }
//   );

//   // On unsync request, cancel sync.
//   yield take(STUDENT_UNSYNC_STUDENT_LIST_ACTIVE);
//   yield cancel(sync);
// }

// WATCHERS

export default function* rootSaga() {
  yield all([
    takeEvery(CLASS_FETCH_CLASS, fetchClass),
    takeEvery(CLASS_LOAD_CLASS, loadClass),
    takeEvery(CLASS_CREATE_CLASS, createClass),
    takeEvery(CLASS_UPDATE_CLASS, updateClass),
    takeEvery(CLASS_DELETE_CLASS, deleteClass),
    takeLatest(CLASS_FETCH_CLASS_LIST_TODAY, fetchClassListToday),
    takeEvery(CLASS_FETCH_CLASS_LIST_ACTIVE, fetchClassListActive),
    takeLatest(CLASS_SYNC_CLASS_LIST_ACTIVE, syncClassListActive),
    takeLatest(CLASS_SYNC_CLASS_LIST_ARCHIVED, syncClassListArchived),
    takeEvery(CLASS_ARCHIVE_CLASS, archiveClasses),
    takeEvery(CLASS_UNARCHIVE_CLASS, unArchiveClasses)
  ]);
}
