import {
  HIDE_ALERT,
  SHOW_ALERT,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_REAUTH_USER,
  AUTH_REAUTH_USER_SUCCESS,
  AUTH_REAUTH_USER_FAILURE,
  AUTH_REAUTH_USER_REQUIRE,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE,
  AUTH_REQUEST_PASSWORD_RESET,
  AUTH_REQUEST_PASSWORD_RESET_SUCCESS,
  AUTH_REQUEST_PASSWORD_RESET_FAILURE,
  AUTH_SIGNUP_SET_INVITECODE,
  AUTH_SIGNUP_PROVIDER_PASSWORD_SET_EMAIL,
  AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PASSWORD,
  AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PROFILE,
  AUTH_SIGNUP_PROVIDER_PASSWORD,
  AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS,
  AUTH_SIGNUP_PROVIDER_PASSWORD_FAILURE,
  AUTH_ADD_INVITECODE,
  AUTH_ADD_INVITECODE_SUCCESS,
  AUTH_ADD_INVITECODE_FAILURE,
  AUTH_CHANGE_USER_LANGUAGE,
  AUTH_CHANGE_USER_LANGUAGE_SUCCESS,
  AUTH_CHANGE_USER_LANGUAGE_FAILURE,
  AUTH_FETCH_USER_PROFILE,
  AUTH_FETCH_USER_PROFILE_SUCCESS,
  AUTH_FETCH_USER_PROFILE_FAILURE,
  AUTH_UPDATE_USER_PROFILE,
  AUTH_UPDATE_USER_PROFILE_SUCCESS,
  AUTH_UPDATE_USER_PROFILE_FAILURE,
  AUTH_FETCH_USER_SETTINGS,
  AUTH_FETCH_USER_SETTINGS_SUCCESS,
  AUTH_FETCH_USER_SETTINGS_FAILURE,
  AUTH_UPDATE_USER_SETTINGS,
  AUTH_UPDATE_USER_SETTINGS_SUCCESS,
  AUTH_UPDATE_USER_SETTINGS_FAILURE,
  AUTH_SYNC_USER_METADATA,
  AUTH_SYNC_USER_METADATA_SUCCESS,
  AUTH_SYNC_USER_METADATA_FAILURE,

  //AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN,
  //AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN_SUCCESS,
  //AUTH_UPDATE_USER_PUSH_NOTIFICATION_TOKEN_FAILURE,
  AUTH_REQUEST_USER_ID_TOKEN,
  AUTH_CLEAR_ERROR
} from "redux/constants/ActionTypes";

export const logout = () => {
  return {
    type: AUTH_LOGOUT
  };
};
export const logoutSuccess = () => {
  return {
    type: AUTH_LOGOUT_SUCCESS
  };
};
export const userloginWithEmailPassword = user => {
  return {
    type: AUTH_LOGIN,
    payload: user
  };
};
export const loginSuccess = authUser => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: authUser
  };
};

export const loginFailure = error => {
  return {
    type: AUTH_LOGIN_FAILURE,
    payload: error
  };
};

export const authRequestUserIdToken = () => {
  return {
    type: AUTH_REQUEST_USER_ID_TOKEN,
    payload: null
  };
};

export const authAddInviteCode = code => {
  return {
    type: AUTH_ADD_INVITECODE,
    payload: code
  };
};

export const authAddInviteCodeSuccess = code => {
  return {
    type: AUTH_ADD_INVITECODE_SUCCESS,
    payload: code
  };
};

export const authAddInviteCodeFailure = code => {
  return {
    type: AUTH_ADD_INVITECODE_FAILURE,
    payload: code
  };
};

export const authReAuthUser = payload => {
  return {
    type: AUTH_REAUTH_USER,
    payload: payload
  };
};

export const authReAuthUserSuccess = payload => {
  return {
    type: AUTH_REAUTH_USER_SUCCESS,
    payload: payload
  };
};

export const authReAuthUserFailure = payload => {
  return {
    type: AUTH_REAUTH_USER_FAILURE,
    payload: payload
  };
};

export const authReAuthUserRequire = payload => {
  return {
    type: AUTH_REAUTH_USER_REQUIRE,
    payload: payload
  };
};

export const authChangePassword = payload => {
  return {
    type: AUTH_CHANGE_PASSWORD,
    payload: payload
  };
};

export const authChangePasswordSuccess = payload => {
  return {
    type: AUTH_CHANGE_PASSWORD_SUCCESS,
    payload: payload
  };
};

export const authChangePasswordFailure = payload => {
  return {
    type: AUTH_CHANGE_PASSWORD_FAILURE,
    payload: payload
  };
};

export const authRequestPasswordReset = payload => {
  return {
    type: AUTH_REQUEST_PASSWORD_RESET,
    payload: payload
  };
};

export const authRequestPasswordResetSuccess = payload => {
  return {
    type: AUTH_REQUEST_PASSWORD_RESET_SUCCESS,
    payload: payload
  };
};

export const authRequestPasswordResetFailure = payload => {
  return {
    type: AUTH_REQUEST_PASSWORD_RESET_FAILURE,
    payload: payload
  };
};

export const authSignupSetInviteCode = invite => {
  return {
    type: AUTH_SIGNUP_SET_INVITECODE,
    payload: invite
  };
};

export const authSignupProviderPasswordSetEmail = email => {
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD_SET_EMAIL,
    payload: email
  };
};

export const authSignupProviderPasswordSetPassword = password => {
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PASSWORD,
    payload: password
  };
};

export const authSignupProviderPasswordSetProfile = profile => {
  //console.log('ACTION - authSignupSetProfile',profile);
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PROFILE,
    payload: profile
  };
};

export const authSignupProviderPassword = payload => {
  //console.log('ACTION - authSignupProviderPassword',payload);
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD,
    payload: payload
  };
};

export const authSignupProviderPasswordSuccess = payload => {
  //console.log('ACTION - authSignupProviderPasswordSuccess',payload);
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS,
    payload: payload
  };
};

export const authSignupProviderPasswordFailure = payload => {
  //console.log('ACTION - authSignupProviderPasswordFailure',payload);
  return {
    type: AUTH_SIGNUP_PROVIDER_PASSWORD_FAILURE,
    payload: payload
  };
};

export const authChangeUserLanguage = payload => {
  return {
    type: AUTH_CHANGE_USER_LANGUAGE,
    payload: payload
  };
};

export const authChangeUserLanguageSuccess = payload => {
  return {
    type: AUTH_CHANGE_USER_LANGUAGE_SUCCESS,
    payload: payload
  };
};

export const authChangeUserLanguageFailure = payload => {
  return {
    type: AUTH_CHANGE_USER_LANGUAGE_FAILURE,
    payload: payload
  };
};

export const authSyncUserMetadata = user => {
  return {
    type: AUTH_SYNC_USER_METADATA,
    payload: user
  };
};

export const authSyncUserMetadataSuccess = user => {
  return {
    type: AUTH_SYNC_USER_METADATA_SUCCESS,
    payload: user
  };
};

export const authSyncUserMetadataFailure = user => {
  return {
    type: AUTH_SYNC_USER_METADATA_FAILURE,
    payload: user
  };
};

export const authFetchUserProfile = user => {
  return {
    type: AUTH_FETCH_USER_PROFILE,
    payload: user
  };
};

export const authFetchUserProfileSuccess = user => {
  return {
    type: AUTH_FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
};

export const authFetchUserProfileFailure = user => {
  return {
    type: AUTH_FETCH_USER_PROFILE_FAILURE,
    payload: user
  };
};

export const authUpdateUserProfile = payload => {
  return {
    type: AUTH_UPDATE_USER_PROFILE,
    payload: payload
  };
};

export const authUpdateUserProfileSuccess = user => {
  return {
    type: AUTH_UPDATE_USER_PROFILE_SUCCESS,
    payload: user
  };
};

export const authUpdateUserProfileFailure = user => {
  return {
    type: AUTH_UPDATE_USER_PROFILE_FAILURE,
    payload: user
  };
};

export const authFetchUserSettings = user => {
  return {
    type: AUTH_FETCH_USER_SETTINGS,
    payload: user
  };
};

export const authFetchUserSettingsSuccess = user => {
  return {
    type: AUTH_FETCH_USER_SETTINGS_SUCCESS,
    payload: user
  };
};

export const authFetchUserSettingsFailure = user => {
  return {
    type: AUTH_FETCH_USER_SETTINGS_FAILURE,
    payload: user
  };
};

export const authUpdateUserSettings = user => {
  return {
    type: AUTH_UPDATE_USER_SETTINGS,
    payload: user
  };
};

export const authUpdateUserSettingsSuccess = user => {
  return {
    type: AUTH_UPDATE_USER_SETTINGS_SUCCESS,
    payload: user
  };
};

export const authUpdateUserSettingsFailure = user => {
  return {
    type: AUTH_UPDATE_USER_SETTINGS_FAILURE,
    payload: user
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_ALERT,
    payload: message
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_ALERT
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const authClearError = () => {
  return {
    type: AUTH_CLEAR_ERROR
  };
};
