import {
  SCHOOL_FETCH_SCHOOL_PROFILE,
  SCHOOL_FETCH_SCHOOL_PROFILE_SUCCESS,
  SCHOOL_FETCH_SCHOOL_PROFILE_FAILURE,
  SCHOOL_UPDATE_SCHOOL_PROFILE,
  SCHOOL_UPDATE_SCHOOL_PROFILE_SUCCESS,
  SCHOOL_UPDATE_SCHOOL_PROFILE_FAILURE,
  SCHOOL_FETCH_SCHOOL_SETTINGS,
  SCHOOL_FETCH_SCHOOL_SETTINGS_SUCCESS,
  SCHOOL_FETCH_SCHOOL_SETTINGS_FAILURE,
  //SCHOOL_SYNC_SCHOOL_METADATA,
  SCHOOL_SYNC_SCHOOL_METADATA_SUCCESS,
  SCHOOL_SYNC_SCHOOL_METADATA_FAILURE,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
  SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ROOM_FETCH_ROOM_LIST,
  SCHOOL_ROOM_FETCH_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_FETCH_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_ADD_ROOM,
  SCHOOL_ROOM_ADD_ROOM_SUCCESS,
  SCHOOL_ROOM_ADD_ROOM_FAILURE,
  SCHOOL_ROOM_UPDATE_ROOM,
  SCHOOL_ROOM_UPDATE_ROOM_SUCCESS,
  SCHOOL_ROOM_UPDATE_ROOM_FAILURE,
  SCHOOL_ROOM_DELETE_ROOM_LIST,
  SCHOOL_ROOM_DELETE_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_DELETE_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_SYNC_ROOM_LIST,
  SCHOOL_ROOM_SYNC_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_SYNC_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_UNSYNC_ROOM_LIST,
    SCHOOL_LISTENER_ON_SCHOOL_USERS,
    SCHOOL_LISTENER_OFF_SCHOOL_USERS,
    SCHOOL_LISTENER_EVENT_SCHOOL_USERS,
    SCHOOL_FETCH_SCHOOL_USERS,
    SCHOOL_FETCH_SCHOOL_USERS_SUCCESS,
    SCHOOL_FETCH_SCHOOL_USERS_FAILURE,
} from "redux/constants/ActionTypes";

export const schoolFetchSchoolUsers = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_USERS,
    payload: payload
  };
};

export const schoolFetchSchoolUsersSuccess = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_USERS_SUCCESS,
    payload: payload
  };
};

export const schoolFetchSchoolUsersFailure = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_USERS_FAILURE,
    payload: payload
  };
};

export const schoolListenerOnSchoolUsers = payload => {
  return {
    type: SCHOOL_LISTENER_ON_SCHOOL_USERS,
    payload: payload
  };
};

export const schoolListenerOffSchoolUsers = payload => {
  return {
    type: SCHOOL_LISTENER_OFF_SCHOOL_USERS,
    payload: payload
  };
};

export const schoolListenerEventSchoolUsers = payload => {
  return {
    type: SCHOOL_LISTENER_EVENT_SCHOOL_USERS,
    payload: payload
  };
};

export const schoolFetchSchoolProfile = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_PROFILE,
    payload: payload
  };
};

export const schoolFetchSchoolProfileSuccess = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_PROFILE_SUCCESS,
    payload: payload
  };
};

export const schoolFetchSchoolProfileFailure = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_PROFILE_FAILURE,
    payload: payload
  };
};

export const schoolUpdateSchoolProfile = payload => {
  return {
    type: SCHOOL_UPDATE_SCHOOL_PROFILE,
    payload: payload
  };
};

export const schoolUpdateSchoolProfileSuccess = payload => {
  return {
    type: SCHOOL_UPDATE_SCHOOL_PROFILE_SUCCESS,
    payload: payload
  };
};

export const schoolUpdateSchoolProfileFailure = payload => {
  return {
    type: SCHOOL_UPDATE_SCHOOL_PROFILE_FAILURE,
    payload: payload
  };
};

export const schoolFetchSchoolSettings = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_SETTINGS,
    payload: payload
  };
};

export const schoolFetchSchoolSettingsSuccess = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_SETTINGS_SUCCESS,
    payload: payload
  };
};

export const schoolFetchSchoolSettingsFailure = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_SETTINGS_FAILURE,
    payload: payload
  };
};

export const schoolSyncSchoolMetadata = payload => {
  return {
    type: SCHOOL_FETCH_SCHOOL_SETTINGS,
    payload: payload
  };
};

export const schoolSyncSchoolMetadataSuccess = payload => {
  return {
    type: SCHOOL_SYNC_SCHOOL_METADATA_SUCCESS,
    payload: payload
  };
};

export const schoolSyncSchoolMetadataFailure = payload => {
  return {
    type: SCHOOL_SYNC_SCHOOL_METADATA_FAILURE,
    payload: payload
  };
};

export const schoolAcademicYearFetchSchoolAcademicYearList = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST,
    payload: payload
  };
};

export const schoolAcademicYearFetchSchoolAcademicYearListSuccess = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
    payload: payload
  };
};

export const schoolAcademicYearFetchSchoolAcademicYearListFailure = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
    payload: payload
  };
};

export const schoolAcademicYearSyncSchoolAcademicYearList = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST,
    payload: payload
  };
};

export const schoolAcademicYearSyncSchoolAcademicYearListSuccess = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
    payload: payload
  };
};

export const schoolAcademicYearSyncSchoolAcademicYearListFailure = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
    payload: payload
  };
};

export const schoolAcademicYearUnSyncSchoolAcademicYearList = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST,
    payload: payload
  };
};

export const schoolAcademicYearAddSchoolAcademicYear = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR,
    payload: payload
  };
};

export const schoolAcademicYearAddSchoolAcademicYearSuccess = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_SUCCESS,
    payload: payload
  };
};

export const schoolAcademicYearAddSchoolAcademicYearFailure = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_FAILURE,
    payload: payload
  };
};

export const schoolAcademicYearUpdateSchoolAcademicYear = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR,
    payload: payload
  };
};

export const schoolAcademicYearUpdateSchoolAcademicYearSuccess = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_SUCCESS,
    payload: payload
  };
};

export const schoolAcademicYearUpdateSchoolAcademicYearFailure = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_FAILURE,
    payload: payload
  };
};

export const schoolAcademicYearDeleteSchoolAcademicYear = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR,
    payload: payload
  };
};

export const schoolAcademicYearDeleteSchoolAcademicYearSuccess = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_SUCCESS,
    payload: payload
  };
};

export const schoolAcademicYearDeleteSchoolAcademicYearFailure = payload => {
  return {
    type: SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_FAILURE,
    payload: payload
  };
};

// ROOMS

export const schoolRoomFetchRoomList = payload => {
  return {
    type: SCHOOL_ROOM_FETCH_ROOM_LIST,
    payload: payload
  };
};

export const schoolRoomFetchRoomListSuccess = payload => {
  return {
    type: SCHOOL_ROOM_FETCH_ROOM_LIST_SUCCESS,
    payload: payload
  };
};

export const schoolRoomFetchRoomListFailure = payload => {
  return {
    type: SCHOOL_ROOM_FETCH_ROOM_LIST_FAILURE,
    payload: payload
  };
};

export const schoolRoomAddRoom = payload => {
  return {
    type: SCHOOL_ROOM_ADD_ROOM,
    payload: payload
  };
};

export const schoolRoomAddRoomSuccess = payload => {
  return {
    type: SCHOOL_ROOM_ADD_ROOM_SUCCESS,
    payload: payload
  };
};

export const schoolRoomAddRoomFailure = payload => {
  return {
    type: SCHOOL_ROOM_ADD_ROOM_FAILURE,
    payload: payload
  };
};

export const schoolRoomUpdateRoom = payload => {
  return {
    type: SCHOOL_ROOM_UPDATE_ROOM,
    payload: payload
  };
};

export const schoolRoomUpdateRoomSuccess = payload => {
  return {
    type: SCHOOL_ROOM_UPDATE_ROOM_SUCCESS,
    payload: payload
  };
};

export const schoolRoomUpdateRoomFailure = payload => {
  return {
    type: SCHOOL_ROOM_UPDATE_ROOM_FAILURE,
    payload: payload
  };
};

export const schoolRoomDeleteRoomList = payload => {
  return {
    type: SCHOOL_ROOM_DELETE_ROOM_LIST,
    payload: payload
  };
};

export const schoolRoomDeleteRoomListSuccess = payload => {
  return {
    type: SCHOOL_ROOM_DELETE_ROOM_LIST_SUCCESS,
    payload: payload
  };
};

export const schoolRoomDeleteRoomListFailure = payload => {
  return {
    type: SCHOOL_ROOM_DELETE_ROOM_LIST_FAILURE,
    payload: payload
  };
};

export const schoolRoomSyncRoomList = payload => {
  return {
    type: SCHOOL_ROOM_SYNC_ROOM_LIST,
    payload: payload
  };
};

export const schoolRoomSyncRoomListSuccess = payload => {
  return {
    type: SCHOOL_ROOM_SYNC_ROOM_LIST_SUCCESS,
    payload: payload
  };
};

export const schoolRoomSyncRoomListFailure = payload => {
  return {
    type: SCHOOL_ROOM_SYNC_ROOM_LIST_FAILURE,
    payload: payload
  };
};

export const schoolRoomUnSyncRoomList = payload => {
  return {
    type: SCHOOL_ROOM_UNSYNC_ROOM_LIST,
    payload: payload
  };
};
