import {

  POSTS_DIALOG_ADD_OPEN,
  POSTS_DIALOG_ADD_CLOSE,

  POSTS_DIALOG_EDIT_OPEN,
  POSTS_DIALOG_EDIT_CLOSE,


  POSTS_FETCH_CURRENT_LIST,
  POSTS_FETCH_CURRENT_LIST_SUCCESS,
  POSTS_FETCH_CURRENT_LIST_FAILURE,

  POSTS_CREATE,
  POSTS_CREATE_SUCCESS,
  POSTS_CREATE_FAILURE,

  POSTS_UPDATE,
  POSTS_UPDATE_SUCCESS,
  POSTS_UPDATE_FAILURE,

  POSTS_DELETE,
  POSTS_DELETE_SUCCESS,
  POSTS_DELETE_FAILURE
} from "redux/constants/ActionTypes";


export const postsDialogEditOpen = payload => {
  return {
    type: POSTS_DIALOG_EDIT_OPEN,
    payload: payload
  };
};

export const postsDialogEditClose = payload => {
  return {
    type: POSTS_DIALOG_EDIT_CLOSE,
    payload: payload
  };
};

export const postsDialogAddOpen = payload => {
  return {
    type: POSTS_DIALOG_ADD_OPEN,
    payload: payload
  };
};

export const postsDialogAddClose = payload => {
  return {
    type: POSTS_DIALOG_ADD_CLOSE,
    payload: payload
  };
};

export const postsFetchCurrentList = payload => {
  return {
    type: POSTS_FETCH_CURRENT_LIST,
    payload: payload
  };
};

export const postsFetchCurrentListSuccess = payload => {
  return {
    type: POSTS_FETCH_CURRENT_LIST_SUCCESS,
    payload: payload
  };
};

export const postsFetchCurrentListFailure = payload => {
  return {
    type: POSTS_FETCH_CURRENT_LIST_FAILURE,
    payload: payload
  };
};


export const postsCreate = payload => {
  return {
    type: POSTS_CREATE,
    payload: payload
  };
};

export const postsCreateSuccess = payload => {
  return {
    type: POSTS_CREATE_SUCCESS,
    payload: payload
  };
};

export const postsCreateFailure = payload => {
  return {
    type: POSTS_CREATE_FAILURE,
    payload: payload
  };
};

export const postsUpdate = payload => {
  return {
    type: POSTS_UPDATE,
    payload: payload
  };
};

export const postsUpdateSuccess = payload => {
  return {
    type: POSTS_UPDATE_SUCCESS,
    payload: payload
  };
};

export const postsUpdateFailure = payload => {
  return {
    type: POSTS_UPDATE_FAILURE,
    payload: payload
  };
};

export const postsDelete = payload => {
  return {
    type: POSTS_DELETE,
    payload: payload
  };
};

export const postsDeleteSuccess = payload => {
  return {
    type: POSTS_DELETE_SUCCESS,
    payload: payload
  };
};

export const postsDeleteFailure = payload => {
  return {
    type: POSTS_DELETE_FAILURE,
    payload: payload
  };
};