import {

  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST,
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_SUCCESS,
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_FAILURE,
  SCHOOL_MESSENGER_ON_SCROLL_CONVERSATION_LIST,
  SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST,

  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_FAILURE,

  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE,
  
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_FAILURE,
  SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,

  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_FAILURE,
  SCHOOL_MESSENGER_UNSYNC_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_ON_SCROLL_SELECTED_CONVERSATION,

  SCHOOL_MESSENGER_SUBMIT_MESSAGE,
  SCHOOL_MESSENGER_MARK_MESSAGES_AS_READ,

} from 'redux/constants/ActionTypes';


export const schoolMessengerSelectedConversationFetchMore = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerSelectedConversationFetchMoreSuccess = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS,
    payload: payload,
  };
};

export const schoolMessengerSelectedConversationFetchMoreFailure = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE,
    payload: payload,
  };
};

export const schoolMessengerSyncConversationList = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST,
    payload: payload,
  };
};

export const schoolMessengerSyncConversationListSuccess = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_SUCCESS,
    payload: payload,
  };
};

export const schoolMessengerSyncConversationListFailure = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_FAILURE,
    payload: payload,
  };
};

export const schoolMessengerOnScrollConversationList = (payload) => {
  return {
    type: SCHOOL_MESSENGER_ON_SCROLL_CONVERSATION_LIST,
    payload: payload,
  };
};

export const schoolMessengerUnSyncConversationList = (payload) => {
  return {
    type: SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST,
    payload: payload,
  };
};

export const schoolMessengerFetchSelectedConversation = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerFetchSelectedConversationSuccess = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_SUCCESS,
    payload: payload,
  };
};

export const schoolMessengerFetchSelectedConversationFailure = (payload) => {
  return {
    type: SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_FAILURE,
    payload: payload,
  };
};

export const schoolMessengerAddNewWatcherSelectedConversation = (payload) => {

  return {
    type: SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerAddNewWatcherSelectedConversationSuccess = (payload) => {

  return {
    type: SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS,
    payload: payload,
  };
};

export const schoolMessengerAddNewWatcherSelectedConversationFailure = (payload) => {
  return {
    type: SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_FAILURE,
    payload: payload,
  };
};

export const schoolMessengerRemoveNewWatcherSelectedConversation = (payload) => {

  return {
    type: SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerSyncSelectedConversation = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerSyncSelectedConversationSuccess = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_SUCCESS,
    payload: payload,
  };
};

export const schoolMessengerSyncSelectedConversationFailure = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_FAILURE,
    payload: payload,
  };
};

export const schoolMessengerOnScrollSelectedConversation = (payload) => {
  return {
    type: SCHOOL_MESSENGER_ON_SCROLL_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerUnSyncSelectedConversation = (payload) => {
  return {
    type: SCHOOL_MESSENGER_UNSYNC_SELECTED_CONVERSATION,
    payload: payload,
  };
};

export const schoolMessengerSubmitMessage = (payload) => {
  return {
    type: SCHOOL_MESSENGER_SUBMIT_MESSAGE,
    payload: payload,
  };
};

export const schoolMessengerMarkMessagesAsRead = (payload) => {
  return {
    type: SCHOOL_MESSENGER_MARK_MESSAGES_AS_READ,
    payload: payload,
  };
};