import {
  SCHOOL_CALENDAR_CREATE_EVENT,
  SCHOOL_CALENDAR_CREATE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_CREATE_EVENT_FAILURE,
  SCHOOL_CALENDAR_UPDATE_EVENT,
  SCHOOL_CALENDAR_UPDATE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_UPDATE_EVENT_FAILURE,
  SCHOOL_CALENDAR_DELETE_EVENT,
  SCHOOL_CALENDAR_DELETE_EVENT_SUCCESS,
  SCHOOL_CALENDAR_DELETE_EVENT_FAILURE,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_SUCCESS,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_FAILURE,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_SUCCESS,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_FAILURE,
  SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID
} from "redux/constants/ActionTypes";

const initialSettings = {
  loaded: false,
  loading: false,

  eventListLoading: false,
  eventListLoaded: false,
  eventList: null,
  eventListSynced: false
};

const SchoolCalendar = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case SCHOOL_CALENDAR_CREATE_EVENT: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SCHOOL_CALENDAR_CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case SCHOOL_CALENDAR_CREATE_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case SCHOOL_CALENDAR_UPDATE_EVENT: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SCHOOL_CALENDAR_UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case SCHOOL_CALENDAR_UPDATE_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case SCHOOL_CALENDAR_DELETE_EVENT: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SCHOOL_CALENDAR_DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case SCHOOL_CALENDAR_DELETE_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE: {
      return {
        ...state,
        eventListLoading: true,
        eventListLoaded: false
      };
    }

    case SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_SUCCESS: {
      return {
        ...state,
        eventListLoading: false,
        eventList: payload,
        eventListLoaded: true
      };
    }

    case SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE_FAILURE: {
      return {
        ...state,
        eventListLoading: false,
        eventListLoaded: false,
        error: payload.error
      };
    }

    case SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID: {
      return {
        ...state,
        eventListLoading: true,
        eventListLoaded: false
      };
    }

    case SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_SUCCESS: {
      return {
        ...state,
        eventListLoading: false,
        eventListLoaded: true,
        eventListSynced: true,
        eventList: payload
      };
    }

    case SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID_FAILURE: {
      return {
        ...state,
        eventListLoading: false,
        eventListLoaded: false,
        eventListSynced: false,
        eventList: null
      };
    }

    case SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID: {
      return {
        ...state,
        eventListLoading: false,
        eventListLoaded: false,
        eventListSynced: false
      };
    }

    default:
      return state;
  }
};

export default SchoolCalendar;
