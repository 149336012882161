import React from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import TimeAgoIntl from "util/IntlTimeAgo";

const MessengerDateTime = ({ messageDate, locale }) => {

  let formatedDateTime = moment(messageDate).format("MM/DD");



  if (moment(messageDate).isSame(moment(), "day")) {
    // Date is today


    return <TimeAgoIntl date={messageDate} locale={locale} live={true} />;
  } else if (
    messageDate >=
    moment()
      .subtract(6, "days")
      .startOf("day")
      .utc()
      .valueOf()
  ) {
    // Date is in the past 6 days
    //formatedDateTime = moment(messageDate).format("ddd");

    if (locale === "ja" || locale === "zh") {
      formatedDateTime = moment(messageDate).format("MM月DD日");
    } else {
      formatedDateTime = moment(messageDate).format("MM/DD");
    }
  } else {
    if (locale === "ja" || locale === "zh") {
      formatedDateTime = moment(messageDate).format("MM月DD日");
    } else {
      formatedDateTime = moment(messageDate).format("MM/DD");
    }
  }


  return <span className="messenger-datetime">{formatedDateTime}</span>;
};

MessengerDateTime.propTypes = {
  messageDate: PropTypes.number.isRequired
};

export default MessengerDateTime;
