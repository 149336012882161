import React from 'react';

import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom';
import StudentAvatar from 'components/Avatar/StudentAvatar';
//import moment from "moment-timezone";

import { routesDefault } from "config/routes";

import MessengerDateTime from 'components/Messenger/MessengerDateTime';


const ListItem = ({data, onSelectMessengerConversation, locale, authUser}) => {


    const linkTo = {
        pathname: routesDefault.messenger.index + '/' + data.id,
        state: { id: data.id }
    };

    const {lastMessage} = data;

    let itemReadStatus = 'unread';

    if(data.lastMessage.hasOwnProperty('readBy')){
        if(data.lastMessage.readBy.hasOwnProperty('office')){
            if(data.lastMessage.readBy.office){
                itemReadStatus = 'read';
            }

        }
    }

    const studentList = [];

    Object.keys(data.students).map(studentKey => {
      if(typeof data.students[studentKey] === 'object'){
          return studentList.push(data.students[studentKey])
      }
        return null;
    });

    let firstName = null;
    let fullname = null;
    let conversationTitle = null;

    if(locale.locale === 'ja') {
        if(lastMessage.firstNameFurigana){
            firstName = lastMessage.firstNameFurigana;
        }else{
            firstName = lastMessage.firstNameRomaji;
        }

        if(lastMessage.nameFurgana){
            fullname = lastMessage.nameFurgana;
        }else{
            fullname = lastMessage.nameRomaji;
        }

        let lastname = null;
        switch(studentList.length) {
            case 1:
                if(studentList[0].profile.nameFurigana){
                    conversationTitle  = studentList[0].profile.nameFurigana;
                }else{
                    conversationTitle  = studentList[0].profile.nameRomaji;
                }
                break;
            case 2:



                if(studentList[0].profile.lastNameFurigana){
                    lastname = studentList[0].profile.lastNameFurigana;

                    conversationTitle  = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;
                }else{
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle  = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                }

                break;
            default:

                if(studentList[0].profile.lastNameFurigana){
                    lastname = studentList[0].profile.lastNameFurigana;

                    conversationTitle  = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;
                }else{
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle  = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                }
        }



    }else{
        if(lastMessage.firstNameRomaji){
            firstName = lastMessage.firstNameRomaji;
        }else{
            firstName = lastMessage.firstNameFurigana;
        }
        if(lastMessage.nameRomaji){
            fullname = lastMessage.nameRomaji;
        }else{
            fullname = lastMessage.nameFurigana;
        }

        let lastname = null;

        switch(studentList.length) {
            case 1:
                if(studentList[0].profile.nameRomaji){
                    conversationTitle  = studentList[0].profile.nameRomaji;
                }else{
                    conversationTitle  = studentList[0].profile.nameFurigana;
                }
                break;
            case 2:
                if(studentList[0].profile.lastNameRomaji){
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle  = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                }else{
                    lastname = studentList[0].profile.lastNameFurigana;
                    conversationTitle  = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;

                }
                break;
            default:
                if(studentList.length) {
                    if(studentList[0].profile.lastNameRomaji){
                        lastname = studentList[0].profile.lastNameRomaji;
                        conversationTitle  = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;
    
                    }else{
                        lastname = studentList[0].profile.lastNameFurigana;
                        conversationTitle  = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;
                    }
                }
        }

    }

    if(!firstName){
        firstName = fullname;
    }

    return (
        <li className="media">
            <Link to={linkTo} className="w-100">
                <Button className={'jr-btn mb-0 ' + itemReadStatus} onClick={() => { onSelectMessengerConversation(data) }}>

                    {studentList.length === 1
                        ? <StudentAvatar data={studentList[0].profile} size={40} classes="mr-2"/>
                        : <StudentAvatar data={studentList[0].profile} size={40} classes="mr-2"/>
                    }


                    <div className="media-body align-self-center">
                        <p className="heading mb-1">{conversationTitle}</p>
                        {
                            lastMessage.system
                            ? <p className="sub-heading mb-0 text-left" style={{height:15, overflow: 'hidden'}}><IntlMessages id="common.system"/>: <IntlMessages id={lastMessage.message}/></p>
                            : authUser.user.uid === lastMessage.createdBy
                            ? <p className="sub-heading mb-0 text-left" style={{height:15, overflow: 'hidden'}}><IntlMessages id="common.messengerYou"/>: {lastMessage.message}</p>
                            : <p className="sub-heading mb-0 text-left" style={{height:15, overflow: 'hidden'}}> {firstName + ': ' + lastMessage.message} </p>
                        }
                    </div>
                    <div style={{width:50, fontSize:10, marginLeft:5}}>
                    <MessengerDateTime messageDate={lastMessage.createdAt} locale={locale}/>
                  </div>
                    
                    <div className="arrowBtn align-self-center">
                        <i className="zmdi zmdi-chevron-right zmdi-hc-fw text-primary"/>
                    </div>
                </Button>
            </Link>
        </li>
    );
};



export default ListItem;
