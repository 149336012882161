import {
  STUDENT_SHOW_ALERT_MESSAGE,
  STUDENT_HIDE_ALERT_MESSAGE,
  STUDENT_LOAD_SELECTED_STUDENT,
  STUDENT_FETCH_SELECTED_STUDENT,
  STUDENT_FETCH_SELECTED_STUDENT_SUCCESS,
  STUDENT_FETCH_SELECTED_STUDENT_FAILURE,
  STUDENT_SYNC_SELECTED_STUDENT,
  STUDENT_SYNC_SELECTED_STUDENT_SUCCESS,
  STUDENT_SYNC_SELECTED_STUDENT_FAILURE,
  STUDENT_UNSYNC_SELECTED_STUDENT,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE_SUCCESS,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE_FAILURE,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE_SUCCESS,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE_FAILURE,
  STUDENT_UNSYNC_STUDENT_LIST_ACTIVE,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED_SUCCESS,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED_FAILURE,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED_SUCCESS,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED_FAILURE,
  STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED,


  /*
  STUDENT_DELETE_STUDENTS,
  STUDENT_DELETE_STUDENTS_SUCCESS,
  STUDENT_DELETE_STUDENTS_FAILURE,

  STUDENT_ARCHIVE_STUDENTS,
  STUDENT_ARCHIVE_STUDENTS_SUCCESS,
  STUDENT_ARCHIVE_STUDENTS_FAILURE,
*/
  STUDENT_UPDATE_STUDENT,
  STUDENT_UPDATE_STUDENT_SUCCESS,
  STUDENT_UPDATE_STUDENT_FAILURE,
  /*
  STUDENT_CREATE_STUDENT,
  STUDENT_CREATE_STUDENT_SUCCESS,
  STUDENT_CREATE_STUDENT_FAILURE
  */

  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST,
  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_SUCCESS,
  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_FAILURE
} from "redux/constants/ActionTypes";

/*
loaded: false,
loading: false,
selectedStudent: {
  studentProfile: null,
  studentMetadata: null,
},
studentListActive: {}
studentListArchived: {}
alertType: '',
alertMessage: '', (error | warning | success),
showAlertMessage: false
*/

const initialSettings = {
  //loaded: false,
  //loading: false,
  selectedStudentSynced: false,
  selectedStudentLoading: false,
  selectedStudentLoaded: false,
  selectedStudent: {},

  studentListActiveLoading: false,
  studentListActiveLoaded: false,
  studentListActive: {},

  studentListArchivedLoading: false,
  studentListArchivedLoaded: false,
  studentListArchived: {},

  studentListArchivedSynced: false,

  studentListActiveSynced: false,

  alertType: "",
  alertMessage: "",
  showAlertMessage: false,

  selectedStudentParentListLoading: false,
  selectedStudentParentList: []
};

const Student = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case STUDENT_SHOW_ALERT_MESSAGE: {
      return {
        ...state,
        alertType: payload.alertType,
        alertMessage: payload.alertMessage,
        showAlertMessage: true
      };
    }

    case STUDENT_HIDE_ALERT_MESSAGE: {
      return {
        ...state,
        alertType: "",
        alertMessage: "",
        showAlertMessage: false
      };
    }

    case STUDENT_LOAD_SELECTED_STUDENT: {
      return {
        ...state,
        //selectedStudentLoading: true,
        selectedStudentLoaded: true,
        selectedStudent: payload
      };
    }

    case STUDENT_FETCH_SELECTED_STUDENT: {
      return {
        ...state,
        selectedStudentLoading: true,
        selectedStudentLoaded: true
      };
    }
    case STUDENT_FETCH_SELECTED_STUDENT_SUCCESS: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: true,
        selectedStudent: payload
      };
    }

    case STUDENT_FETCH_SELECTED_STUDENT_FAILURE: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: false,
        selectedStudent: {}
      };
    }

    case STUDENT_SYNC_SELECTED_STUDENT: {
      return {
        ...state,
        selectedStudentLoading: true,
        selectedStudentLoaded: false
      };
    }
    case STUDENT_SYNC_SELECTED_STUDENT_SUCCESS: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: true,
        selectedStudentSynced: true,
        selectedStudent: payload
      };
    }

    case STUDENT_SYNC_SELECTED_STUDENT_FAILURE: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: false,
        selectedStudent: {}
      };
    }

    case STUDENT_UNSYNC_SELECTED_STUDENT: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: false,
        selectedStudentSynced: false,
        selectedStudent: {}
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ACTIVE: {
      return {
        ...state,
        studentListActiveLoading: true,
        studentListActiveLoaded: false
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ACTIVE_SUCCESS: {
      return {
        ...state,
        studentListActiveLoading: false,
        studentListActiveLoaded: true,
        studentListActive: payload.studentListActive
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ACTIVE_FAILURE: {
      return {
        ...state,
        studentListActiveLoading: false,
        studentListActiveLoaded: false
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ACTIVE: {
      return {
        ...state,
        studentListActiveLoading: true,
        studentListActiveLoaded: false,
        studentListActiveSynced: false
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ACTIVE_SUCCESS: {
      return {
        ...state,
        studentListActiveLoading: false,
        studentListActiveLoaded: true,
        studentListActiveSynced: true,
        studentListActive: payload
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ACTIVE_FAILURE: {
      return {
        ...state,
        studentListActiveLoading: false,
        studentListActiveLoaded: false,
        studentListActiveSynced: false
      };
    }

    case STUDENT_UNSYNC_STUDENT_LIST_ACTIVE: {
      return {
        ...state,
        studentListActiveLoading: false,
        studentListActiveLoaded: false,
        studentListActiveSynced: false
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ARCHIVED: {
      return {
        ...state,
        studentListArchivedLoading: true,
        studentListArchivedLoaded: false
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ARCHIVED_SUCCESS: {
      return {
        ...state,
        studentListArchivedLoading: false,
        studentListArchivedLoaded: true,
        studentListArchived: payload
      };
    }

    case STUDENT_FETCH_STUDENT_LIST_ARCHIVED_FAILURE: {
      return {
        ...state,
        studentListArchivedLoading: false,
        studentListArchivedLoaded: false
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ARCHIVED: {
      return {
        ...state,
        studentListArchivedLoading: true,
        studentListArchivedLoaded: false,
        studentListArchivedSynced: false
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ARCHIVED_SUCCESS: {
      return {
        ...state,
        studentListArchivedLoading: false,
        studentListArchivedLoaded: true,
        studentListArchivedSynced: true,
        studentListArchived: payload
      };
    }

    case STUDENT_SYNC_STUDENT_LIST_ARCHIVED_FAILURE: {
      return {
        ...state,
        studentListArchivedLoading: false,
        studentListArchivedLoaded: false,
        studentListArchivedSynced: false
      };
    }

    case STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED: {
      return {
        ...state,
        studentListArchivedLoading: false,
        studentListArchivedLoaded: false,
        studentListArchivedSynced: false
      };
    }

    case STUDENT_UPDATE_STUDENT: {
      return {
        ...state,
        selectedStudentLoading: true
      };
    }
    case STUDENT_UPDATE_STUDENT_SUCCESS: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: true,
        selectedStudent: payload
      };
    }
    case STUDENT_UPDATE_STUDENT_FAILURE: {
      return {
        ...state,
        selectedStudentLoading: false,
        selectedStudentLoaded: false
      };
    }


    case STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST: {
      return {
        ...state,
        selectedStudentParentListLoading: true
      };
    }
    case STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_SUCCESS: {

      return {
        ...state,
        selectedStudentParentListLoading: false,
        selectedStudentParentList: payload.parentList

      };
    }
    case STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_FAILURE: {
      return {
        ...state,
        selectedStudentParentListLoading: false,

      };
    }

    default:
      return state;
  }
};

export default Student;
