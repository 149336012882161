import React, { useState } from 'react';
import TimeAgoIntl from 'util/IntlTimeAgo';
import Linkify from 'react-linkify';
import Tooltip from '@material-ui/core/Tooltip';
import IntlMessages from 'util/IntlMessages';
import moment from "moment-timezone";



import Chip from '@material-ui/core/Chip';

import { getTranslatedText } from "../../../../../../util/googleTranslateApi"

const SentMessageCell = ({ message, auth, locale, conversation }) => {
    const [isShowTransltedMessage, setIsShowTransltedMessage] = useState(false)
    const [previousMessage, setPreviousMessage] = useState("")
    const [activeTranslatedMessage, setActiveTranslatedMessage] = useState("...")

    let readByUsers = false;

    // Set seen
    if (message.hasOwnProperty('readBy')) {
        readByUsers = true;
    }

    const handleTranslation = async (message, locale) => {

        if (message === previousMessage) {
            setIsShowTransltedMessage(true)
        }
        else {
            setPreviousMessage(message);

            const translatedMessage = await getTranslatedText(message, locale);
            setActiveTranslatedMessage(translatedMessage);

            setIsShowTransltedMessage(true)
        }

    };

    const handleShowTranslation = () => {
        setIsShowTransltedMessage(false)
    };


    if(message.system){
        return (
          <div className="messenger-chat">
              <div className="d-flex align-items-center justify-content-center" style={{padding: '24px 0px'}}>
                  <Chip disabled={true} variant="outlined" label={ <IntlMessages id={message.message} />}/>
              </div>
          </div>
        )
    }



    if(message.hasOwnProperty('image')){

        return (
          <div className="messenger-chat">
              <div className="d-flex flex-nowrap chat-item flex-row-reverse pb-0">
                  <div className="bubble">
                      <div className="message">
                          <img src={message.image}  style={{width:100}} alt={''}/>

                      </div>
                  </div>
              </div>
              <div className="chat-time text-muted text-left mt-1 ml-1 chat-date-wrapper chat-left">

                  <TimeAgoIntl date={message.createdAt} locale={locale.locale} live={true} />
                  {readByUsers &&
                  <Tooltip title={<IntlMessages id="common.messageRead" values={{ time: moment(message.readBy['office']).format('lll') }} />} placement="bottom-end">
                      <i className="zmdi zmdi-check-circle" style={{ marginLeft: 5, color: 'green' }}></i>
                  </Tooltip>
                  }
              </div>
          </div>
        )

    }


    return (
        <div className="messenger-chat">
            <div className="d-flex flex-nowrap chat-item flex-row-reverse pb-0">
                <div className="bubble jambo-card  mr-0">
                    <div className="message">
                        <Linkify properties={{ target: '_blank' }}>
                            {message.message.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </Linkify>
                    </div>

                    {!isShowTransltedMessage && (
                        <div className="chat-see-translation" onClick={() => handleTranslation(message.message, locale.locale)}>
                            {message.message === previousMessage
                              ? <IntlMessages id="common.show_translation" />
                              : <IntlMessages id="common.see_translation" />
                            }
                        </div>
                    )}

                    {isShowTransltedMessage && activeTranslatedMessage && (
                        <div style={{ borderLeft: "3px solid #dcdee3", paddingLeft: "6px", color: "#38424ba6" }}>
                            {activeTranslatedMessage}
                        </div>
                    )}
                    {isShowTransltedMessage && (
                        <div className="chat-see-translation" onClick={() => handleShowTranslation()}>

                            <IntlMessages id="common.hide_translation" />
                        </div>
                    )}
                </div>
            </div>
            <div className="chat-time text-muted text-right mt-1 mr-3 chat-date-wrapper">

                <TimeAgoIntl date={message.createdAt} locale={locale.locale} live={true} />
                {readByUsers &&
                    Object.keys(message.readBy).map((itemKey, index) => conversation.guardians.hasOwnProperty(itemKey) &&
                        <Tooltip key={index} title={<IntlMessages id="common.messageRead" values={{ time: moment(message.readBy[itemKey]).format('lll') }} />} placement="bottom-end">
                            <i key={index} className="zmdi zmdi-check-circle" style={{ marginLeft: 5, color: 'green' }}></i>
                        </Tooltip>
                    )}
            </div>
        </div>

    )
};

export default SentMessageCell;