import {
  ATTENDANCE_ADD_ATTENDANCE,
  ATTENDANCE_ADD_ATTENDANCE_SUCCESS,
  ATTENDANCE_ADD_ATTENDANCE_FAILURE,
  ATTENDANCE_UPDATE_ATTENDANCE,
  ATTENDANCE_UPDATE_ATTENDANCE_SUCCESS,
  ATTENDANCE_UPDATE_ATTENDANCE_FAILURE,
  ATTENDANCE_DELETE_ATTENDANCE,
  ATTENDANCE_DELETE_ATTENDANCE_SUCCESS,
  ATTENDANCE_DELETE_ATTENDANCE_FAILURE,
  ATTENDANCE_SYNC_ATTENDANCE_LIST,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_FAILURE,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_SUCCESS,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_FAILURE,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE
} from "redux/constants/ActionTypes";

const initialSettings = {
  creating: false,
  updating: false,
  deleting: false,

  attendanceListLoading: false,
  attendanceListLoaded: false,
  attendanceList: {},

  attendanceListByDateTypeSyncing: false,
  attendanceListByDateTypeSynced: false,
  attendanceListByDateType: {},

  attendanceListSynced: false,
  attendanceListSyncedTried: false,

  alertType: "",

  alertMessage: "",
  showAlert: false
};

const Attendance = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case ATTENDANCE_ADD_ATTENDANCE: {
      return {
        ...state,
        creating: true
      };
    }

    case ATTENDANCE_ADD_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        creating: false
      };
    }

    case ATTENDANCE_ADD_ATTENDANCE_FAILURE: {
      return {
        ...state,
        creating: false
      };
    }

    case ATTENDANCE_UPDATE_ATTENDANCE: {
      return {
        ...state,
        updating: true
      };
    }

    case ATTENDANCE_UPDATE_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        updating: false
        // attendanceList: payload,
      };
    }

    case ATTENDANCE_UPDATE_ATTENDANCE_FAILURE: {
      return {
        ...state,
        updating: false
      };
    }

    case ATTENDANCE_DELETE_ATTENDANCE: {
      return {
        ...state,
        deleting: true
      };
    }

    case ATTENDANCE_DELETE_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        deleting: false,
        // as attendanceFirstCreatedReSync, when user delete the attendance we need to resync
        attendanceDeletedReSync: true
      };
    }

    case ATTENDANCE_DELETE_ATTENDANCE_FAILURE: {
      return {
        ...state,
        deleting: false
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST: {
      return {
        ...state,
        attendanceListLoading: true,
        attendanceListLoaded: false,
        attendanceListSynced: false
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST_SUCCESS: {
      return {
        ...state,
        attendanceListLoading: false,
        attendanceListLoaded: true,
        attendanceListSynced: true,
        attendanceList: payload ? payload : {}
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST_FAILURE: {
      return {
        ...state,
        attendanceListLoading: false,
        attendanceListLoaded: false,
        attendanceListSynced: false,
        attendanceList: {}
      };
    }

    case ATTENDANCE_UNSYNC_ATTENDANCE_LIST: {
      return {
        ...state,
        attendanceListLoading: false,
        attendanceListLoaded: false,
        attendanceListSynced: false,
        attendanceList: {}
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE: {
      return {
        ...state,
        attendanceListByDateTypeSyncing: true,
        attendanceListByDateTypeSynced: false
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_SUCCESS: {
      return {
        ...state,
        attendanceListByDateTypeSyncing: false,
        attendanceListByDateTypeSynced: true,
        attendanceListByDateType: payload ? payload : {}
      };
    }

    case ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_FAILURE: {
      return {
        ...state,
        attendanceListByDateTypeSyncing: false,
        attendanceListByDateTypeSynced: false,
        attendanceListByDateType: {}
      };
    }

    case ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE: {
      return {
        ...state,
        attendanceListByDateTypeSyncing: false,
        attendanceListByDateTypeSynced: false,
        attendanceListByDateType: {}
      };
    }

    default:
      return state;
  }
};

export default Attendance;
