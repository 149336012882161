const roleAccess = {
    system: false,
    admin: true,
    manager: true,
    teacher: true,
    parent: false,
    guardian: false,

};

const providers = {
    password: {
        id: 'password',
        signupLabel: 'auth:signupProvider_password_label',
        signupDescription: 'auth:signupProvider_password_description',
        iconFont:'Ionicon',
        iconName:'ios-mail',
        iconColor:'#333',
        active: true,
    },
    phone: {
        id: 'phone',
        signupLabel: 'auth:signupProvider_phone_label',
        signupDescription: 'auth:signupProvider_phone_description',
        iconFont:'Ionicon',
        iconName:'ios-phone-portrait',
        iconColor:'#333',
        active: false,
    },
    facebook: {
        id: 'facebook',
        signupLabel: 'auth:signupProvider_facebook_label',
        signupDescription: 'auth:signupProvider_facebook_description',
        iconFont:'Ionicon',
        iconName:'logo-facebook',
        iconColor:'#333',
        active: false,
    },
    google: {
        id: 'google',
        signupLabel: 'auth:signupProvider_google_label',
        signupDescription: 'auth:signupProvider_google_description',
        iconFont:'Ionicon',
        iconName:'logo-google',
        iconColor:'#333',
        active: false,
    },
    line: {
        id: 'line',
        signupLabel: 'auth:signupProvider_line_label',
        signupDescription: 'auth:signupProvider_line_description',
        iconFont:'FontAwesome',
        iconName:'line',
        iconColor:'#333',
        active: false,
    },
};





export {
    roleAccess,
    providers
}