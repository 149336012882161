import { all } from "redux-saga/effects";
import auth from "./Auth";
import school from "./School";
import classes from "./Classes";
import student from "./Student";
import attendance from "./Attendance";
import schoolCalendar from "./SchoolCalendar";
import schoolMessenger from "./SchoolMessenger";
import announcement from "./Announcement";
import schoolUsers from "./SchoolUsers";
import posts from "./Posts";

export default function* rootSaga(getState) {
  yield all([auth(), school(), classes(), student(), attendance(), schoolCalendar(), schoolMessenger(), announcement(), schoolUsers(), posts()]);
}
