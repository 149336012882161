import React from 'react';
import {withRouter} from 'react-router-dom';
import HeaderMenuContent from './HeaderMenuContent';

class HeaderMenu extends React.Component {

  render() {
    return (
      <div className={`app-top-nav d-none d-md-block ${this.props.styleName}`}>
        <div className="d-flex app-toolbar align-items-center">
          <HeaderMenuContent/>
        </div>
      </div>
    );
  }
}

export default withRouter(HeaderMenu);

HeaderMenu.defaultProps = {
  styleName: ''
};