import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import asyncComponent from 'util/asyncComponent';


class DashboardRouter extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('DashboardRouter didupdate')
  }


  render() {

    const {auth} = this.props;

    return (
      <div className="app-main-content">
        <Switch>
          { auth.user.role === 'sysadmin' &&
          <Route path={`/dashboard`} component={asyncComponent(() => import('./ManagerDashboard'))}/>
          }
          { auth.user.role === 'admin' &&
          <Route path={`/`} component={asyncComponent(() => import('./ManagerDashboard'))}/>
          }
          { auth.user.role === 'manager' &&
          <Route path={`/`} component={asyncComponent(() => import('./ManagerDashboard'))}/>
          }
          { (auth.user.role === 'teacher' || auth.user.role === 'staff') &&
          <Route path={`/`} component={asyncComponent(() => import('./ManagerDashboard'))}/>
          }
          { (auth.user.role === 'parent' || auth.user.role === 'guardian') &&
          <Route path={`/`} component={asyncComponent(() => import('./ManagerDashboard'))}/>
          }
          <Route component={asyncComponent(() => import('components/Error404'))}/>
        </Switch>
      </div>

    );
  }
}


const mapStateToProps = ({auth}) => {
  return {auth}
};
export default withRouter(connect(mapStateToProps)(DashboardRouter));