import {
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE,
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_SUCCESS,
  SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_FAILURE
} from "redux/constants/ActionTypes";

const initialSettings = {
  schoolUsersListActiveLoading: false,
  schoolUsersListActiveLoaded: false,
  schoolUsersListActive: {}
};

const SchoolUsers = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE: {
      return {
        ...state,
        schoolUsersListActiveLoading: true,
        schoolUsersListActiveLoaded: false
      };
    }

    case SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_SUCCESS: {
      return {
        ...state,
        schoolUsersListActiveLoading: false,
        schoolUsersListActiveLoaded: true,
        schoolUsersListActive: payload.schoolUsersListActive
      };
    }

    case SCHOOL_USERS_FETCH_SCHOOL_USERS_LIST_ACTIVE_FAILURE: {
      return {
        ...state,
        schoolUsersListActiveLoading: false,
        schoolUsersListActiveLoaded: false
      };
    }

    default:
      return state;
  }
};

export default SchoolUsers;
