import {
  ATTENDANCE_ADD_ATTENDANCE,
  ATTENDANCE_ADD_ATTENDANCE_SUCCESS,
  ATTENDANCE_ADD_ATTENDANCE_FAILURE,
  ATTENDANCE_UPDATE_ATTENDANCE,
  ATTENDANCE_UPDATE_ATTENDANCE_SUCCESS,
  ATTENDANCE_UPDATE_ATTENDANCE_FAILURE,
  ATTENDANCE_DELETE_ATTENDANCE,
  ATTENDANCE_DELETE_ATTENDANCE_SUCCESS,
  ATTENDANCE_DELETE_ATTENDANCE_FAILURE,
  ATTENDANCE_SYNC_ATTENDANCE_LIST,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_FAILURE,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_SUCCESS,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_FAILURE,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE
} from "redux/constants/ActionTypes";

export const attendanceAddAttendance = payload => {
  return {
    type: ATTENDANCE_ADD_ATTENDANCE,
    payload: payload
  };
};

export const attendanceAddAttendanceSuccess = payload => {
  return {
    type: ATTENDANCE_ADD_ATTENDANCE_SUCCESS,
    payload: payload
  };
};

export const attendanceAddAttendanceFailure = payload => {
  return {
    type: ATTENDANCE_ADD_ATTENDANCE_FAILURE,
    payload: payload
  };
};

export const attendanceUpdateAttendance = payload => {
  return {
    type: ATTENDANCE_UPDATE_ATTENDANCE,
    payload: payload
  };
};

export const attendanceUpdateAttendanceSuccess = payload => {
  return {
    type: ATTENDANCE_UPDATE_ATTENDANCE_SUCCESS,
    payload: payload
  };
};

export const attendanceUpdateAttendanceFailure = payload => {
  return {
    type: ATTENDANCE_UPDATE_ATTENDANCE_FAILURE,
    payload: payload
  };
};

export const attendanceDeleteAttendance = payload => {
  return {
    type: ATTENDANCE_DELETE_ATTENDANCE,
    payload: payload
  };
};

export const attendanceDeleteAttendanceSuccess = payload => {
  return {
    type: ATTENDANCE_DELETE_ATTENDANCE_SUCCESS,
    payload: payload
  };
};

export const attendanceDeleteAttendanceFailure = payload => {
  return {
    type: ATTENDANCE_DELETE_ATTENDANCE_FAILURE,
    payload: payload
  };
};

export const attendanceSyncAttendanceList = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST,
    payload: payload
  };
};

export const attendanceSyncAttendanceListSuccess = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST_SUCCESS,
    payload: payload
  };
};

export const attendanceSyncAttendanceListFailure = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST_FAILURE,
    payload: payload
  };
};

export const attendanceUnSyncAttendanceList = payload => {
  return {
    type: ATTENDANCE_UNSYNC_ATTENDANCE_LIST,
    payload: payload
  };
};

export const attendanceSyncAttendanceListByDateAndType = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
    payload: payload
  };
};

export const attendanceSyncAttendanceListByDateAndTypeSuccess = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_SUCCESS,
    payload: payload
  };
};

export const attendanceSyncAttendanceListByDateAndTypeFailure = payload => {
  return {
    type: ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE_FAILURE,
    payload: payload
  };
};

export const attendanceUnSyncAttendanceListByDateAndType = payload => {
  return {
    type: ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
    payload: payload
  };
};
