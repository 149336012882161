import {
  all,
  call,
  put,
  takeEvery,
  fork,
  takeLatest,
  take,
  cancel
} from "redux-saga/effects";
//fork, cancel, delay
//import { eventChannel } from "redux-saga";

import { rsf } from "config/firebase";
import firebase from "firebase/app";
import "firebase/database";
//import 'firebase/storage';
import moment from "moment-timezone";

import {
  ATTENDANCE_ADD_ATTENDANCE,
  ATTENDANCE_UPDATE_ATTENDANCE,
  ATTENDANCE_DELETE_ATTENDANCE,
  ATTENDANCE_SYNC_ATTENDANCE_LIST,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST,
  ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
  ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE
} from "redux/constants/ActionTypes";

import {
  attendanceAddAttendanceSuccess,
  attendanceAddAttendanceFailure,
  attendanceUpdateAttendanceSuccess,
  attendanceUpdateAttendanceFailure,
  attendanceDeleteAttendanceSuccess,
  attendanceDeleteAttendanceFailure,
  attendanceSyncAttendanceListSuccess,
  attendanceSyncAttendanceListFailure,
  attendanceSyncAttendanceListByDateAndTypeSuccess,
  attendanceSyncAttendanceListByDateAndTypeFailure
} from "redux/actions";

import { showAlert } from "redux/actions/Setting";

// HELPERS

// REQUESTS

// FUNCTIONS
function* createAttendance({ payload }) {
  const { schoolId, attendance, key = attendance.data.recordDate } = payload;

  console.log('SAGA - createAttendance payload', payload)
  try {

    const res = yield call(
      rsf.database.update,
      `/studentAttendance/${schoolId}/byStudent/${attendance.studentId}/${key}/`,
      attendance
    );

    console.log('SAGA - createAttendance res', res)

    yield put(
      attendanceAddAttendanceSuccess({
        attendance: attendance
      })
    );


    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.attendance_create_success"
      })
    );

  } catch (error) {
    console.log(error, "SAGA - createAttendance : error");
    yield put(attendanceAddAttendanceFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateAttendance({ payload }) {
  const {
    schoolId,
    attendance,
    key = moment()
      .startOf("day")
      .utc()
      .valueOf()
  } = payload;

  try {
    //yield call(rsf.database.patch, `/studentAttendance/${schoolId}/byDate/${key}/${attendance.studentId}`, attendance);

    yield call(
      rsf.database.patch,
      `/studentAttendance/${schoolId}/byStudent/${attendance.studentId}/${key}/`,
      attendance
    );

    yield put(
      attendanceUpdateAttendanceSuccess({
        attendance: attendance
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.attendance_update_success"
      })
    );
  } catch (error) {
    console.log(error, "error");
    yield put(attendanceUpdateAttendanceFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteAttendance({ payload }) {
  const { schoolId, studentId, key } = payload;

  try {
    //yield call(rsf.database.delete, `/studentAttendance/${schoolId}/byDate/${key}/${studentId}`);

    yield call(
      rsf.database.delete,
      `/studentAttendance/${schoolId}/byStudent/${studentId}/${key}`
    );

    yield put(attendanceDeleteAttendanceSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.attendance_delete_success"
      })
    );
  } catch (error) {
    console.log(error, "error");
    yield put(attendanceDeleteAttendanceFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// EXPORTS

// SYNCS

function* syncAttendanceList({ payload }) {
  const { schoolId, dateStart, dateEnd } = payload;
  const nodeRef = firebase
    .database()
    .ref(`studentAttendance/${schoolId}/byDate`)
    .orderByKey()
    .startAt(dateStart)
    .endAt(dateEnd);

  try {
    // Start the sync saga
    let sync = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: attendanceSyncAttendanceListSuccess,
      failureActionCreator: attendanceSyncAttendanceListFailure
    });

    // Wait for the pause action, then stop sync
    yield take(ATTENDANCE_UNSYNC_ATTENDANCE_LIST);
    yield cancel(sync);
  } catch (e) {
    console.log(e);
  }
}

function* syncAttendanceListByDateAndType({ payload }) {
  const { schoolId, dateStart, type } = payload;

  const nodeRef = firebase
    .database()
    .ref(`studentAttendance/${schoolId}/byDate/${dateStart}`)
    .orderByChild("data/status")
    .equalTo(type);

  try {
    // Start the sync saga
    let task = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: attendanceSyncAttendanceListByDateAndTypeSuccess,
      failureActionCreator: attendanceSyncAttendanceListByDateAndTypeFailure
    });

    // Wait for the pause action, then stop sync
    yield take(ATTENDANCE_UNSYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE);
    yield cancel(task);
  } catch (e) {
    console.log(e);
  }
}

// WATCHERS

export default function* rootSaga() {
  yield all([
    takeEvery(ATTENDANCE_ADD_ATTENDANCE, createAttendance),
    takeEvery(ATTENDANCE_UPDATE_ATTENDANCE, updateAttendance),
    takeEvery(ATTENDANCE_DELETE_ATTENDANCE, deleteAttendance),
    takeLatest(ATTENDANCE_SYNC_ATTENDANCE_LIST, syncAttendanceList),
    takeLatest(
      ATTENDANCE_SYNC_ATTENDANCE_LIST_BY_DATE_AND_TYPE,
      syncAttendanceListByDateAndType
    )
  ]);
}
