import React from "react";
import IntlMessages from "util/IntlMessages";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { showAlert, hideAlert } from "redux/actions";
import { compose } from "redux";
import { connect } from "react-redux";

class AlertSystem extends React.Component {
  componentDidUpdate() {
    if (this.props.showAlert) {
      switch (this.props.alertType) {
        case "info":
          NotificationManager.info(<IntlMessages id={this.props.alertMessage} />);
          break;
        case "success":
          if (this.props.alertMessageExtra) {
            NotificationManager.success(<IntlMessages id={this.props.alertMessage} values={{ extra: this.props.alertMessageExtra }} />);
          } else {
            NotificationManager.success(<IntlMessages id={this.props.alertMessage} />);
          }
          break;
        case "warning":
          NotificationManager.warning(<IntlMessages id={this.props.alertMessage} />);
          break;
        case "error":
          NotificationManager.error(<IntlMessages id={this.props.alertMessage} />);
          break;
        default:
      }
      setTimeout(() => {
        this.props.hideAlert();
      }, 100);
    }
  }

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { showAlert, hideAlert, alertType, alertMessage, alertMessageExtra } = settings;
  return { showAlert, hideAlert, alertType, alertMessage, alertMessageExtra };
};

export default compose(
  connect(
    mapStateToProps,
    {
      showAlert,
      hideAlert
    }
  )
)(AlertSystem);
