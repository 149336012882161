import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Route, Switch } from "react-router-dom";
import { connect} from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "../themes/defaultTheme";
import AppLocale from "../languages";
import { routesDefault } from "config/routes";
import { languageSwitcherList } from "config/language";
import AppContainer from "routes/AppContainer";
import Login from "routes/auth/login";
import ForgotPassword from "routes/auth/forgotPassword";
import { setInitUrl } from "redux/actions/Auth";
import RTL from "util/RTL";
import { switchLanguage } from "redux/actions/Setting";
import moment from "moment-timezone";
import momentJa from "moment/locale/ja";
import momentOverides from "util/momentOverides";
import { ConnectedRouter } from "connected-react-router";
import { ReactReduxContext } from 'react-redux';
import { history } from "../redux/store";
import CacheBuster from '../CacheBuster';

class App extends React.PureComponent {
  //static whyDidYouRender = true;
  componentDidMount() {
    if(this.props.locale.locale === 'ja'){
      moment.updateLocale(this.props.locale.locale, momentJa);
      moment.updateLocale(this.props.locale.locale, momentOverides[this.props.locale.locale]);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Updates system locale on initial user login.
    if (this.props.auth.loaded && this.props.auth.loaded !== prevProps.auth.loaded) {
      if (this.props.auth.userMetadata.locale !== this.props.locale.locale) {
        if (languageSwitcherList.hasOwnProperty(this.props.auth.userMetadata.locale)) {
          if (languageSwitcherList[this.props.auth.userMetadata.locale].active) {
            this.props.switchLanguage(languageSwitcherList[this.props.auth.userMetadata.locale]);
            moment.updateLocale(this.props.locale.locale, momentJa);
            moment.updateLocale(this.props.auth.userMetadata.locale, momentOverides[this.props.auth.userMetadata.locale] || null);
          }
        }
      }
    }
    // Updates moment-js locale on language change.
    if (this.props.locale.locale !== prevProps.locale.locale) {
      moment.updateLocale(this.props.locale.locale, momentJa);
      moment.updateLocale(this.props.locale.locale, momentOverides[this.props.locale.locale] || null);
    }
    // set schoolTimezone
    if (this.props.schoolSettings !== prevProps.schoolSettings) {
      this.props.schoolSettings.timezone && moment.tz.setDefault(this.props.schoolSettings.timezone);
    }
  }

  render() {
    const { locale, auth, isDirectionRTL } = this.props;
    const applyTheme = createMuiTheme(defaultTheme);
    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <MuiThemeProvider theme={applyTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                  <RTL>
                    <div className="app-main">
                      <ConnectedRouter history={history} context={ReactReduxContext}>
                        {auth.user
                          ? <AppContainer/>
                          : <Switch>
                              <Route exact path={routesDefault.forgotPassword} component={ForgotPassword}/>
                              <Route exact path={routesDefault.login} component={Login}/>
                            </Switch>
                        }
                      </ConnectedRouter>

                    </div>
                  </RTL>
                </IntlProvider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          );
        }}
      </CacheBuster>
    );



  }
}

const mapStateToProps = (store, ownProps) => {
  const { settings, auth, school } = store;
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { initURL } = auth;
  const { schoolSettings } = school;
  return { sideNavColor, locale, isDirectionRTL, auth, initURL, schoolSettings };

};

export default connect(
  mapStateToProps,
  {
    setInitUrl,
    switchLanguage
  }
)(App);
