import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import SidemenuContent from './SidemenuContent';
import SideMenuHeader from 'components/SideMenuHeader';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'redux/constants/ActionTypes';

import {toggleCollapsedNav, updateWindowWidth} from 'redux/actions/Setting';
import {logout} from 'redux/actions/Auth';

class SideNav extends React.PureComponent {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  handleLogout = () => {
    this.props.logout()
  };

  render() {
    const {navCollapsed, drawerType, width, navigationStyle} = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }




    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={this.onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
        >
          <SideMenuHeader/>
          <SidemenuContent/>

          <Button style={{width:'100%'}} className="jr-btn bg-dark jr-btn-lg text-white" onClick={() => {this.handleLogout()}}>
            <i className="zmdi zmdi-power" />
          </Button>

        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed, drawerType, width, navigationStyle} = settings;
  return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {
  toggleCollapsedNav,
  updateWindowWidth,
  logout
})(SideNav));

