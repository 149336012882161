import {
  all,
  call,
  put,
  delay,
  takeEvery,
  takeLatest,
  take,
  fork,
  cancel
} from "redux-saga/effects";
//import { eventChannel } from 'redux-saga'
//import { roleAccess } from "config/auth";
import { rsf } from "config/firebase";

import {getUserIdToken, deleteDBRequest, updateDBWithArrayRequest} from "../../util/firebaseHelpers";
import {postApiData2} from "../../util/apiHelpers";


import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

import {
  SCHOOL_CALENDAR_CREATE_EVENT,
  SCHOOL_CALENDAR_UPDATE_EVENT,
  SCHOOL_CALENDAR_DELETE_EVENT,
  SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE,
  SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
  SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID
} from "redux/constants/ActionTypes";

import {
  schoolCalendarCreateEventSuccess,
  schoolCalendarCreateEventFailure,
  schoolCalendarUpdateEventSuccess,
  schoolCalendarUpdateEventFailure,
  schoolCalendarDeleteEventSuccess,
  schoolCalendarDeleteEventFailure,
  schoolCalendarFetchEventListByDateSuccess,
  schoolCalendarFetchEventListByDateFailure,
  schoolCalendarSyncEventListByAcademicYearIdSuccess,
  schoolCalendarSyncEventListByAcademicYearIdFailure
} from "redux/actions/SchoolCalendar";

import { showAlert } from "redux/actions/Setting";

// HELPERS

// REQUESTS

// FUNCTIONS

function* createEvent2({ payload }) {
  let { schoolId, image, createdEvent } = payload;


  try {
    //const eventId = yield call(rsf.database.create, `/schoolCalendar/${schoolId}`, createdEvent);
    const eventId = yield call(
      rsf.database.create,
      `/schoolCalendar/${schoolId}/all`,
      {}
    );

  const formData = new FormData();
  formData.append('dateStart', createdEvent.dateStart);
  formData.append('dateEnd', createdEvent.dateEnd);
  formData.append('titleFurigana', createdEvent.titleFurigana);
  formData.append('titleRomaji', createdEvent.titleRomaji);
  formData.append('location', createdEvent.location);
  formData.append('address', createdEvent.address);
  formData.append('eventColor', createdEvent.eventColor);
  formData.append('showInCalendar', createdEvent.showInCalendar);
  formData.append('multipleDays', createdEvent.multipleDays);
  formData.append('totalDays', createdEvent.totalDays);
  formData.append('eventType', createdEvent.eventType);
  formData.append('createdAt', createdEvent.createdAt);
  formData.append('createdBy', createdEvent.createdBy);
  formData.append('updatedAt', createdEvent.updatedAt);
  formData.append('updatedBy', createdEvent.updatedBy);
  formData.append('notes', createdEvent.notes);
  formData.append('academicYearId', createdEvent.academicYearId);
  formData.append('postTo', createdEvent.postTo.toString());

  if(image){
    formData.append('eventImage', image.file);
  }

    if (image) {
      let fileName = image.path
        .substring(image.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/eventData/${eventId}.${
        fileName.split(".")[1]
      }`;

      const uploadedFile = rsf.storage.uploadFile(path, image.file);

      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });

      // Wait for upload to complete
      yield uploadedFile;

      // Get URL for uploaded file
      const imageUrl = yield call(rsf.storage.getDownloadURL, path);
      Object.assign(createdEvent, { eventImageUrl: imageUrl });
    }

    Object.assign(createdEvent, { id: eventId });

    //yield call(rsf.database.update, `/schoolCalendar/${schoolId}/${eventId}`, createdEvent);
    //yield call(rsf.database.update, `/schoolEvents/${schoolId}/${eventId}`, createdEvent);
    yield call(
      rsf.database.update,
      `/schoolCalendar/${schoolId}/all/${eventId}`,
      createdEvent
    );
    yield call(
      rsf.database.update,
      `/schoolCalendar/${schoolId}/events/${eventId}`,
      createdEvent
    );

    yield put(
      schoolCalendarCreateEventSuccess({
        createdEvent: createdEvent
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_create_success_with_extra",
        alertMessageExtra: createdEvent.successTitle
      })
    );
  } catch (error) {
    yield put(schoolCalendarCreateEventFailure({ error }));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* createEvent({ payload }) {
  let { schoolId, image, createdEvent } = payload;

  const formData = new FormData();
  formData.append('dateStart', createdEvent.dateStart);
  formData.append('dateEnd', createdEvent.dateEnd);
  formData.append('titleFurigana', createdEvent.titleFurigana);
  formData.append('titleRomaji', createdEvent.titleRomaji);
  formData.append('location', createdEvent.location);
  formData.append('address', createdEvent.address);
  formData.append('eventColor', createdEvent.eventColor);
  formData.append('showInCalendar', createdEvent.showInCalendar);
  formData.append('multipleDays', createdEvent.multipleDays);
  formData.append('totalDays', createdEvent.totalDays);
  formData.append('eventType', createdEvent.eventType);
  formData.append('createdAt', createdEvent.createdAt);
  formData.append('createdBy', createdEvent.createdBy);
  formData.append('updatedAt', createdEvent.updatedAt);
  formData.append('updatedBy', createdEvent.updatedBy);
  formData.append('notes', createdEvent.notes);
  formData.append('academicYearId', createdEvent.academicYearId);
  formData.append('postTo', createdEvent.postTo.toString());

  if(image){
    formData.append('eventImage', image.file);
  }



  try {
    const tokenId = yield call(getUserIdToken);

    console.log('tokenId', tokenId)
    const res = yield call(postApiData2, `/school/${schoolId}/event`, 'multipart/form-data', tokenId, formData);

    console.log('create event res', res)

    if(image){
      Object.assign(createdEvent, { eventImage: res.data.files });
    }

    yield put(
      schoolCalendarCreateEventSuccess({
        createdEvent: createdEvent
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_create_success_with_extra",
        alertMessageExtra: createdEvent.title
      })
    );

  }
  catch (error) {
    yield put(schoolCalendarCreateEventFailure({ error }));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}


function* updateEvent({ payload }) {
  let { schoolId, updatedEventId, updatedEvent, image } = payload;

  try {
    if (image) {
      /*
      let fileName = image.path
        .substring(image.path.lastIndexOf("\\") + 1)
        .split(" ")
        .join("_");
      const path = `schools/${schoolId}/eventData/${updatedEventId}.${
        fileName.split(".")[1]
      }`;

      const uploadedFile = rsf.storage.uploadFile(path, image.file);

      uploadedFile.on("state_changed", snapshot => {
        // const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
      });

      // Wait for upload to complete
      yield uploadedFile;

      // Get URL for uploaded file
      const imageUrl = yield call(rsf.storage.getDownloadURL, path);

      updatedEvent = Object.assign(updatedEvent, { eventImageUrl: imageUrl });
      */
    }

    //yield call(rsf.database.patch, `/schoolCalendar/${schoolId}/${updatedEventId}`, updatedEvent);
    //yield call(rsf.database.patch, `/schoolEvents/${schoolId}/${updatedEventId}`, updatedEvent);
    yield call(
      rsf.database.patch,
      `/schoolCalendar/${schoolId}/all/${updatedEventId}`,
      updatedEvent
    );
    yield call(
      rsf.database.patch,
      `/schoolCalendar/${schoolId}/events/${updatedEventId}`,
      updatedEvent
    );

    yield put(
      schoolCalendarUpdateEventSuccess({
        updatedEvent: updatedEvent
      })
    );
    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_update_success_with_extra",
        alertMessageExtra: updatedEvent.title
      })
    );
  } catch (error) {
    console.log("has error", error);
    yield put(schoolCalendarUpdateEventFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteEvent({ payload }) {
  const { schoolId, eventList } = payload;


  let deleteList = {};
  eventList.map(item => {
    deleteList[`/schoolCalendar/${schoolId}/all/${item.id}`] = null;
    deleteList[`/schoolCalendar/${schoolId}/events/${item.id}`] = null;
    deleteList[`/schoolCalendar/${schoolId}/metadata/${item.id}`] = null;
  });

  try {
    delay(1000);

    yield call(updateDBWithArrayRequest, deleteList);

    yield put(schoolCalendarDeleteEventSuccess());
    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_delete_success"
      })
    );
  } catch (error) {
    yield put(schoolCalendarDeleteEventFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* fetchEventListByDate({ payload }) {
  const { schoolId, dateStart, limit } = payload;


  const nodeRef = firebase
    .database()
    .ref(`schoolCalendar/${schoolId}/events`)
    .orderByChild("dateStart")
    .startAt(dateStart)
    .limitToFirst(limit);

  try {
    const eventList = yield call(rsf.database.read, nodeRef);

    yield put(schoolCalendarFetchEventListByDateSuccess(eventList));
  } catch (error) {
    yield put(schoolCalendarFetchEventListByDateFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// EXPORTS

// SYNCS
function* syncEventListByAcademicYearId({ payload }) {
  const { schoolId, academicYearId } = payload;
  const nodeRef = firebase
    .database()
    .ref(`schoolCalendar/${schoolId}/all`)
    .orderByChild("academicYearId")
    .equalTo(academicYearId);

  try {
    // Start the sync saga
    let task = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: schoolCalendarSyncEventListByAcademicYearIdSuccess,
      //transform: conversationTransformer,
      failureActionCreator: schoolCalendarSyncEventListByAcademicYearIdFailure
    });

    // Wait for the pause action, then stop sync
    yield take(SCHOOL_CALENDAR_UNSYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID);
    yield cancel(task);
  } catch (e) {
    console.log(e);
  }
}

// WATCHERS

export default function* rootSaga() {
  yield all([
    takeEvery(SCHOOL_CALENDAR_CREATE_EVENT, createEvent),
    takeEvery(SCHOOL_CALENDAR_UPDATE_EVENT, updateEvent),
    takeEvery(SCHOOL_CALENDAR_DELETE_EVENT, deleteEvent),
    takeEvery(SCHOOL_CALENDAR_FETCH_EVENT_LIST_BY_DATE, fetchEventListByDate),
    takeLatest(
      SCHOOL_CALENDAR_SYNC_EVENT_LIST_BY_ACADEMIC_YEAR_ID,
      syncEventListByAcademicYearId
    )
  ]);
}
