import React from "react";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";

const StudentAvatar = props => {

  let { size } = props;
  const { data, classes } = props;

  if (!size) {
    size = 32;
  }

  const avatarStyle = {
    width: size,
    height: size,
    textDecoration: "none"
  };

  let url;
  if (data.hasOwnProperty("avatar")) {
    if (data.avatar && data.avatar !== '') {
      if(data.avatar.hasOwnProperty("url")){
        url = data.avatar.url.avatar;
      } else  if(data.avatar.hasOwnProperty("medium")) {
        url = data.avatar.medium;
      }
    }
  } else if (data.hasOwnProperty("avatarUrl")) {
    if (data.avatarUrl && data.avatarUrl !== '') {
      url = data.avatarUrl;
    }
  }

  if (url) {
    return <Avatar style={avatarStyle} src={url} className={classes} />;
  }

  /*
  if(data){

    if (data.hasOwnProperty("avatar")) {
      if (data.avatar && data.avatar !== '') {
        return <Avatar style={avatarStyle} src={size <= 32 ? data.avatar.small : data.avatar.medium} className={classes} />;
      }
    }
    if (data.hasOwnProperty("avatarUrl")) {
      if (data.avatarUrl && data.avatarUrl !== '') {
        return <Avatar style={avatarStyle} src={data.avatarUrl} className={classes} />;
      }
    }
  }*/

  if (data.hasOwnProperty("initials")) {
    if (data.initials) {
      let bgColorClass = "avatar bg-standard";
      if (data.gender === "male") {
        bgColorClass = "male-avatar avatar-bg";
      } else if (data.gender === "female") {
        bgColorClass = "female-avatar avatar-bg";
      } else {
        bgColorClass = "avatar bg-standard";
      }

      return (
        <Avatar style={avatarStyle} className={classes + " " + bgColorClass}>
          {data.initials}
        </Avatar>
      );
    }
  }

  let extraClasses = "avatar bg-gray lighten-3";
  if (data.gender === "male") {
    extraClasses = "male-avatar avatar-bg";
  } else if (data.gender === "female") {
    extraClasses = "female-avatar avatar-bg";
  } else {
    extraClasses = "avatar bg-gray lighten-3";
  }

  return (
    <Avatar style={avatarStyle} className={classes + " " + extraClasses}>
      <PersonIcon />
    </Avatar>
  );
};
export default StudentAvatar;

