import {
  STUDENT_SHOW_ALERT_MESSAGE,
  STUDENT_HIDE_ALERT_MESSAGE,
  STUDENT_LOAD_SELECTED_STUDENT,
  STUDENT_FETCH_SELECTED_STUDENT,
  STUDENT_FETCH_SELECTED_STUDENT_SUCCESS,
  STUDENT_FETCH_SELECTED_STUDENT_FAILURE,
  STUDENT_SYNC_SELECTED_STUDENT,
  STUDENT_SYNC_SELECTED_STUDENT_SUCCESS,
  STUDENT_SYNC_SELECTED_STUDENT_FAILURE,
  STUDENT_UNSYNC_SELECTED_STUDENT,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE_SUCCESS,
  STUDENT_FETCH_STUDENT_LIST_ACTIVE_FAILURE,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED_SUCCESS,
  STUDENT_FETCH_STUDENT_LIST_ARCHIVED_FAILURE,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE_SUCCESS,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE_FAILURE,
  STUDENT_UNSYNC_STUDENT_LIST_ACTIVE,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED_SUCCESS,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED_FAILURE,
  STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED,
  STUDENT_DELETE_STUDENTS,
  STUDENT_DELETE_STUDENTS_SUCCESS,
  STUDENT_DELETE_STUDENTS_FAILURE,
  STUDENT_ARCHIVE_STUDENTS,
  STUDENT_ARCHIVE_STUDENTS_SUCCESS,
  STUDENT_ARCHIVE_STUDENTS_FAILURE,
  STUDENT_UNARCHIVE_STUDENTS,
  STUDENT_UNARCHIVE_STUDENTS_SUCCESS,
  STUDENT_UNARCHIVE_STUDENTS_FAILURE,
  STUDENT_UPDATE_STUDENT,
  STUDENT_UPDATE_STUDENT_SUCCESS,
  STUDENT_UPDATE_STUDENT_FAILURE,
  STUDENT_CREATE_STUDENT,
  STUDENT_CREATE_STUDENT_SUCCESS,
  STUDENT_CREATE_STUDENT_FAILURE,

  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST,
  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_SUCCESS,
  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_FAILURE
} from "redux/constants/ActionTypes";

export const studentShowAlertMessage = payload => {
  return {
    type: STUDENT_SHOW_ALERT_MESSAGE,
    payload: payload
  };
};

export const studentHideAlertMessage = payload => {
  return {
    type: STUDENT_HIDE_ALERT_MESSAGE,
    payload: payload
  };
};

export const studentLoadSelectedStudent = payload => {
  return {
    type: STUDENT_LOAD_SELECTED_STUDENT,
    payload: payload
  };
};

export const studentFetchSelectedStudent = payload => {
  return {
    type: STUDENT_FETCH_SELECTED_STUDENT,
    payload: payload
  };
};

export const studentFetchSelectedStudentSuccess = payload => {
  return {
    type: STUDENT_FETCH_SELECTED_STUDENT_SUCCESS,
    payload: payload
  };
};

export const studentFetchSelectedStudentFailure = payload => {
  return {
    type: STUDENT_FETCH_SELECTED_STUDENT_FAILURE,
    payload: payload
  };
};

export const studentSyncSelectedStudent = payload => {
  return {
    type: STUDENT_SYNC_SELECTED_STUDENT,
    payload: payload
  };
};

export const studentSyncSelectedStudentSuccess = payload => {
  return {
    type: STUDENT_SYNC_SELECTED_STUDENT_SUCCESS,
    payload: payload
  };
};

export const studentSyncSelectedStudentFailure = payload => {
  return {
    type: STUDENT_SYNC_SELECTED_STUDENT_FAILURE,
    payload: payload
  };
};

export const studentUnSyncSelectedStudent = payload => {
  return {
    type: STUDENT_UNSYNC_SELECTED_STUDENT,
    payload: payload
  };
};

export const studentFetchStudentListActive = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ACTIVE,
    payload: payload
  };
};

export const studentFetchStudentListActiveSuccess = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ACTIVE_SUCCESS,
    payload: payload
  };
};

export const studentFetchStudentListActiveFailure = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ACTIVE_FAILURE,
    payload: payload
  };
};

export const studentSyncStudentListActive = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ACTIVE,
    payload: payload
  };
};

export const studentSyncStudentListActiveSuccess = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ACTIVE_SUCCESS,
    payload: payload
  };
};

export const studentSyncStudentListActiveFailure = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ACTIVE_FAILURE,
    payload: payload
  };
};

export const studentUnSyncStudentListActive = payload => {
  return {
    type: STUDENT_UNSYNC_STUDENT_LIST_ACTIVE,
    payload: payload
  };
};

export const studentFetchStudentListArchived = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ARCHIVED,
    payload: payload
  };
};

export const studentFetchStudentListArchivedSuccess = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ARCHIVED_SUCCESS,
    payload: payload
  };
};

export const studentFetchStudentListArchivedFailure = payload => {
  return {
    type: STUDENT_FETCH_STUDENT_LIST_ARCHIVED_FAILURE,
    payload: payload
  };
};

export const studentSyncStudentListArchived = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ARCHIVED,
    payload: payload
  };
};

export const studentSyncStudentListArchivedSuccess = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ARCHIVED_SUCCESS,
    payload: payload
  };
};

export const studentSyncStudentListArchivedFailure = payload => {
  return {
    type: STUDENT_SYNC_STUDENT_LIST_ARCHIVED_FAILURE,
    payload: payload
  };
};

export const studentUnSyncStudentListArchived = payload => {
  return {
    type: STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED,
    payload: payload
  };
};

export const studentDeleteStudents = payload => {
  return {
    type: STUDENT_DELETE_STUDENTS,
    payload: payload
  };
};

export const studentDeleteStudentsSuccess = payload => {
  return {
    type: STUDENT_DELETE_STUDENTS_SUCCESS,
    payload: payload
  };
};

export const studentDeleteStudentsFailure = payload => {
  return {
    type: STUDENT_DELETE_STUDENTS_FAILURE,
    payload: payload
  };
};

export const studentArchiveStudents = payload => {
  return {
    type: STUDENT_ARCHIVE_STUDENTS,
    payload: payload
  };
};

export const studentArchiveStudentsSuccess = payload => {
  return {
    type: STUDENT_ARCHIVE_STUDENTS_SUCCESS,
    payload: payload
  };
};

export const studentArchiveStudentsFailure = payload => {
  return {
    type: STUDENT_ARCHIVE_STUDENTS_FAILURE,
    payload: payload
  };
};

export const studentUnArchiveStudents = payload => {
  return {
    type: STUDENT_UNARCHIVE_STUDENTS,
    payload: payload
  };
};

export const studentUnArchiveStudentsSuccess = payload => {
  return {
    type: STUDENT_UNARCHIVE_STUDENTS_SUCCESS,
    payload: payload
  };
};

export const studentUnArchiveStudentsFailure = payload => {
  return {
    type: STUDENT_UNARCHIVE_STUDENTS_FAILURE,
    payload: payload
  };
};

export const studentUpdateStudent = payload => {
  return {
    type: STUDENT_UPDATE_STUDENT,
    payload: payload
  };
};

export const studentUpdateStudentSuccess = payload => {
  return {
    type: STUDENT_UPDATE_STUDENT_SUCCESS,
    payload: payload
  };
};

export const studentUpdateStudentFailure = payload => {
  return {
    type: STUDENT_UPDATE_STUDENT_FAILURE,
    payload: payload
  };
};

export const studentCreateStudent = payload => {
  return {
    type: STUDENT_CREATE_STUDENT,
    payload: payload
  };
};

export const studentCreateStudentSuccess = payload => {
  return {
    type: STUDENT_CREATE_STUDENT_SUCCESS,
    payload: payload
  };
};

export const studentCreateStudentFailure = payload => {
  return {
    type: STUDENT_CREATE_STUDENT_FAILURE,
    payload: payload
  };
};


export const studentFetchSelectedStudentParentList = payload => {

  return {
    type: STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST,
    payload: payload
  };

}



export const studentFetchSelectedStudentParentListSuccess = payload => {

  return {
    type: STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_SUCCESS,
    payload: payload
  };

}


export const studentFetchSelectedStudentParentListFailure = payload => {

  return {
    type: STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST_FAILURE,
    payload: payload
  };

}