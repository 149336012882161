import { all, call, fork, put, takeEvery, take, cancel } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { roleAccess } from "config/auth";
import { rsf } from "config/firebase";
import { push } from "connected-react-router";

import firebase from "firebase/app";
import "firebase/auth";

import {SigninUserWithPasswordRequest} from "../../util/firebaseHelpers";
import {getUserIdToken, getUserClaims} from "../../util/firebaseHelpers";
import {apiCall} from "../../util/apiHelpers";

import {
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  //AUTH_SYNC_USER_METADATA,
  //AUTH_SYNC_USER_METADATA_SUCCESS,
  AUTH_FETCH_USER_PROFILE,
  //AUTH_FETCH_USER_PROFILE_SUCCESS,
  AUTH_UPDATE_USER_PROFILE,
  //AUTH_UPDATE_USER_PROFILE_SUCCESS,
  AUTH_FETCH_USER_SETTINGS,
  //AUTH_FETCH_USER_SETTINGS_SUCCESS,
  AUTH_UPDATE_USER_SETTINGS,
  //USER_CHANGE_LANGUAGE,
  //USER_CHANGE_LANGUAGE_SUCCESS,
  //USER_CHANGE_LANGUAGE_FAILURE,
  AUTH_CHANGE_PASSWORD,
  //USER_UPDATE_PUSH_NOTIFICATION_TOKEN,
  //MESSENGER_UNSYNC_CONVERSATION,
  //AUTH_ADD_INVITECODE,
  //AUTH_SIGNUP_SET_INVITECODE,
  //AUTH_SIGNUP_PROVIDER_PASSWORD_SET_EMAIL,
  //AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PASSWORD,
  //AUTH_SIGNUP_PROVIDER_PASSWORD_SET_PROFILE,
  //AUTH_SIGNUP_PROVIDER_PASSWORD,
  //AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS,
  AUTH_REQUEST_USER_ID_TOKEN,
  AUTH_CHANGE_USER_LANGUAGE,
  AUTH_REAUTH_USER,
  AUTH_REQUEST_PASSWORD_RESET
} from "redux/constants/ActionTypes";

import {
  showAuthMessage,
  loginSuccess,
  logoutSuccess,
  loginFailure,
  //authFetchUserProfile,
  authFetchUserProfileSuccess,
  authFetchUserProfileFailure,
  //authUpdateUserProfile,
  authUpdateUserProfileSuccess,
  authUpdateUserProfileFailure,
  //authFetchUserSettings,
  authFetchUserSettingsSuccess,
  authFetchUserSettingsFailure,
  //authUpdateUserSettings,
  authUpdateUserSettingsSuccess,
  authUpdateUserSettingsFailure,
  //authSyncUserMetadata,
  authSyncUserMetadataFailure,
  authSyncUserMetadataSuccess,
  //authChangePasswordSuccess,
  //authChangePasswordFailure,
  //userUpdateProfileSuccess,
  //userUpdateProfileFailure,
  //userFetchProfileSuccess,
  //authSignupProviderPasswordFailure,
  //authSignupProviderPasswordSuccess,
  //authAddInviteCodeSuccess,
  //authAddInviteCodeFailure,
  authChangeUserLanguageSuccess,
  authChangeUserLanguageFailure,
  authReAuthUserSuccess,
  authReAuthUserFailure,
  authRequestPasswordResetSuccess,
  authRequestPasswordResetFailure
} from "redux/actions/Auth";

import {
  //setLocale,
  showAlert
} from "../actions/Setting";

const auth = rsf.app.auth();


/*
 * REQUESTS
 */

// Create User
/*
const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
    */

const reAuthenticateUserRequest = async credentials =>
  await auth.currentUser
    .reauthenticateAndRetrieveDataWithCredential(credentials)
    .then(authUser => authUser)
    .catch(error => error);

const authRequestPasswordResetRequest = async email =>
  await auth
    .sendPasswordResetEmail(email, auth.actionCodeSettings || {})
    .then(res => {
      return res;
    })
    .catch(error => {
      console.log(error, "error");
      return error;
    });




/*
 * FUNCTIONS
 */

/*
function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(authSignupProviderPasswordSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
*/

function* loginUserWithEmailPassword({ payload }) {
  const { email, password } = payload;

  try {
    const signInUser = yield call(SigninUserWithPasswordRequest, {email, password});

    console.log('signInUser', signInUser)

    if(signInUser.error){
      switch (signInUser.error.code) {
        case "auth/user-not-found":
          yield put(
            showAlert({
              alertType: "error",
              alertMessage: "alertMessage.login_user_not_found"
            })
          );
          yield put(loginFailure("auth.login_user_not_found"));
          break;

        case "auth/invalid-email":
          yield put(
            showAlert({
              alertType: "error",
              alertMessage: "alertMessage.login_invalid_email"
            })
          );
          yield put(loginFailure("auth.login_invalid_email"));
          break;

        case "auth/wrong-password":
          yield put(
            showAlert({
              alertType: "error",
              alertMessage: "alertMessage.login_wrong_password"
            })
          );
          yield put(loginFailure("auth.login_wrong_password"));
          break;

        default:


          yield put(
            showAlert({
              alertType: "error",
              alertMessage: "alertMessage." + signInUser.error.code
            })
          );
          yield put(loginFailure("auth." + signInUser.error.code));
      }
    }

    /*
    if (signInUser.message) {
      yield put(
        showAlert({
          alertType: "success",
          alertMessage: "signInUser.message"
        })
      );
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      //yield put(userSignInSuccess(signInUser.user.uid));
    }
    */
  } catch (error) {
    //ToDo: continue with error handling .
    console.log("ERROR: SAGA - Auth - loginUserWithEmailPassword", error.code);

    switch (error.code) {
      case "auth/user-not-found":
        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage.login_user_not_found"
          })
        );
        yield put(loginFailure("auth.login_user_not_found"));
        break;

      case "auth/invalid-email":
        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage.login_invalid_email"
          })
        );
        yield put(loginFailure("auth.login_invalid_email"));
        break;

      case "auth/wrong-password":
        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage.login_wrong_password"
          })
        );
        yield put(loginFailure("auth.login_wrong_password"));
        break;

      default:

        console.log('error', error)

        yield put(
          showAlert({
            alertType: "error",
            alertMessage: "alertMessage." + error.code
          })
        );
        yield put(loginFailure("auth." + error.code));
    }
  }
}

function* logoutUser() {
  try {
    const signOutUser = yield call(rsf.auth.signOut);
    if (signOutUser === undefined) {
      //TODO: Browser pushToken removal from DB.
      localStorage.removeItem("user_id");
      yield put(logoutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fetchUserProfile({ payload }) {
  const { uid } = payload;
  if (uid) {
    try {
      const userProfile = yield call(rsf.database.read, "userProfile/" + uid);

      if (userProfile.message) {
        yield put(showAuthMessage(userProfile.message));
        yield put(authFetchUserProfileFailure());
      } else {
        yield put(authFetchUserProfileSuccess(userProfile));
      }
    } catch (error) {
      console.log("ERROR: SAGA - Auth - fetchUserProfile", error.code);
      yield put(authFetchUserProfileFailure());
    }
  }
}

function* updateUserProfile({ payload }) {
  let { userId, sid, userProfile, avatarFile, prevAvatar  } = payload;

  const prevAvatarId = prevAvatar.id;


  try {
    const tokenId = yield call(getUserIdToken);

    if (avatarFile) {
      const formData = new FormData();
      formData.append('avatar', avatarFile.file);
      const apiUrl = `/v1/file/${sid}/upload/avatar`;
      const fileData = yield call(apiCall, 'POST', apiUrl, tokenId, formData, true);
      if(fileData.files.length > 0){
        userProfile.avatarId = fileData.files[0].id;
        userProfile.avatarUrl = fileData.files[0].url.avatar;
      }
    }

    yield call(rsf.database.update, "userProfile/" + userId, userProfile);
    yield put(authUpdateUserProfileSuccess(userProfile));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.profile_update_success"
      })
    );
    if(avatarFile && prevAvatarId){
      yield call(apiCall, 'DELETE', `/v1/file/${sid}/delete/${prevAvatarId}`, tokenId);
    }
  } catch (error) {
    console.log('ERROR: Saga-updateUserProfile', error)
    yield put(authUpdateUserProfileFailure(error));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: error.message
      })
    );
  }
}

function* fetchUserSettings({ payload }) {
  const { uid } = payload;

  try {
    const userSettings = yield call(rsf.database.read, "userSettings/" + uid);

    if (userSettings.message) {
      yield put(showAuthMessage(userSettings.message));
      yield put(authFetchUserSettingsFailure(userSettings));
    } else {
      yield put(authFetchUserSettingsSuccess(userSettings));
    }
  } catch (error) {
    console.log("ERROR: SAGA - Auth - fetchUserSettings", error.code);
  }
}

function* updateUserSettings({ payload }) {
  let { itemValue, all } = payload;
  if (all !== undefined) {
    if (all) {
      itemValue = {
        enableAll: true,
        enableOnNewAnnouncement: true,
        enableOnNewAttendance: true,
        enableOnNewCalendarEvent: true,
        enableOnNewMessage: true,
        enableOnNewNotifications: true
      };
    } else {
      itemValue = {
        enableAll: false,
        enableOnNewAnnouncement: false,
        enableOnNewAttendance: false,
        enableOnNewCalendarEvent: false,
        enableOnNewMessage: false,
        enableOnNewNotifications: false
      };
    }
  }

  try {
    yield call(rsf.database.update, `userSettings/${payload.uid}/${payload.itemField}`, itemValue);
    const userSettings = yield call(rsf.database.read, "userSettings/" + payload.uid);
    yield put(authUpdateUserSettingsSuccess(userSettings));
  } catch (error) {
    console.log("ERROR: SAGA - Auth - fetchUserSettings", error.code);
    yield put(authUpdateUserSettingsFailure(error));
  }
}

function* changeUserLanguage({ payload }) {
  const { userId, locale } = payload;

  try {
    yield call(rsf.database.update, `userSettings/${userId}/locale`, locale);
    yield call(rsf.database.update, `userMetadata/${userId}/locale`, locale);

    yield put(authChangeUserLanguageSuccess());

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.change_user_language_success"
      })
    );
  } catch (error) {
    yield put(authChangeUserLanguageFailure(error));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* authReAuthenticateUser({ payload }) {
  const { email, password } = payload;

  const credentials = firebase.auth.EmailAuthProvider.credential(email, password);

  try {
    const reAuthenticatedUser = yield call(reAuthenticateUserRequest, credentials);
    if (reAuthenticatedUser.message) {
      yield put(authReAuthUserFailure(reAuthenticatedUser.message));
    } else {
      yield put(authReAuthUserSuccess());
    }
  } catch (error) {
    yield put(authReAuthUserFailure(error.message));
  }
}

function* authChangePassword({ payload }) {
  const { password } = payload;

  try {
    yield call(rsf.auth.updatePassword, password);
    yield put(authReAuthUserFailure());
    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.change_password_success"
      })
    );
  } catch (error) {
    yield put(authReAuthUserFailure(error.message));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* authRequestPasswordReset({ payload }) {
  const { email, locale } = payload;
  auth.languageCode = locale.locale;

  try {
    const response = yield call(authRequestPasswordResetRequest, email);

    if (response && response.message) {
      yield put(authRequestPasswordResetFailure(response.code));
      yield put(
        showAlert({
          alertType: "error",
          alertMessage: "alertMessage.common_error"
        })
      );
    } else {
      yield put(authRequestPasswordResetSuccess());
      yield put(
        showAlert({
          alertType: "success",
          alertMessage: "alertMessage.password_reset_success"
        })
      );
    }
  } catch (error) {
    yield put(authRequestPasswordResetFailure(error.code));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// SYNCS
function* syncUserMetadata({ payload }) {
  const { uid } = payload;

  const sync = yield fork(rsf.database.sync, `userMetadata/${uid}`, {
    successActionCreator: authSyncUserMetadataSuccess,
    failureActionCreator: authSyncUserMetadataFailure
  });

  // On unsync request, cancel sync.
  yield take(AUTH_LOGOUT_SUCCESS);
  yield cancel(sync);
}

// WATCHERS
function* authStatusWatcher() {
  const channel = eventChannel(emit => {
    //return auth.onIdTokenChanged(user => emit({ user }), error => emit({ error }));
    return auth.onAuthStateChanged(user => emit({ user }), error => emit({ error }));
  });

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      const uid = user.uid;
      const claims = yield call(getUserClaims);
      const tokenId = yield call(getUserIdToken);

      const payload = {
        uid: uid,
        role: claims.role,
        cid: claims.cid || '',
        school: '',
        schoolList: {},
        tokenId
      };

      if(claims.role === 'admin'){

        let schoolListArray = [];

        Object.keys(claims.sid).map(sKey => {
          return schoolListArray.push(sKey)
        });

        payload.schoolList = claims.sid;
        payload.school = schoolListArray[0];

      }else {

        payload.school = claims.sid;
      }


      //console.log('payload >>', payload )
      yield put(push("/dashboard"));
      yield put(loginSuccess(payload));

      // Check user role
      if (roleAccess[claims.role] === true) {
        switch (claims.role) {
          case "system":
            //yield call(logoutUser);

            break;
          case "parent" || "guardian":
            //console.log('UserRole: system');
            break;
          default:
        }
      } else {
        // Redirect on no user claims to unauthorized page.
        //console.log("authStatusWatcher push to unauthorized");
        yield put(push("/unauthorized"));
        yield call(logoutUser);
      }
    } else {
      // Redirect on no user.
      //console.log("authStatusWatcher push to /");
      yield put(push("/login"));
    }
  }
}

export default function* rootSaga() {
  yield fork(authStatusWatcher);
  yield all([
    takeEvery(AUTH_LOGOUT, logoutUser),
    takeEvery(AUTH_LOGIN, loginUserWithEmailPassword),
    takeEvery(AUTH_LOGIN_SUCCESS, syncUserMetadata),
    takeEvery(AUTH_LOGIN_SUCCESS, fetchUserProfile),
    takeEvery(AUTH_LOGIN_SUCCESS, fetchUserSettings),
    takeEvery(AUTH_FETCH_USER_PROFILE, fetchUserProfile),
    takeEvery(AUTH_UPDATE_USER_PROFILE, updateUserProfile),
    takeEvery(AUTH_FETCH_USER_SETTINGS, fetchUserSettings),
    takeEvery(AUTH_UPDATE_USER_SETTINGS, updateUserSettings),
    takeEvery(AUTH_REQUEST_USER_ID_TOKEN, getUserIdToken),
    takeEvery(AUTH_CHANGE_USER_LANGUAGE, changeUserLanguage),
    takeEvery(AUTH_REAUTH_USER, authReAuthenticateUser),
    takeEvery(AUTH_CHANGE_PASSWORD, authChangePassword),
    takeEvery(AUTH_REQUEST_PASSWORD_RESET, authRequestPasswordReset)
    //takeEvery(AUTH_ADD_INVITECODE, addInviteCode),
    //takeEvery(AUTH_SIGNUP_PROVIDER_PASSWORD, signupUserProviderPassword),
    //takeEvery(AUTH_SIGNUP_PROVIDER_PASSWORD_SUCCESS, sendEmailVerification),
    //takeEvery(USER_UPDATE_PUSH_NOTIFICATION_TOKEN, updateUserPushNotificationToken),
  ]);
}
