import React from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";

import { version } from "../../config/settings";

import Button from "@material-ui/core/Button";
// import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";

import { routesDefault } from "config/routes";
import { switchLanguage } from "redux/actions/Setting";

import brandLogo from "assets/images/brandlogo-header-green.svg";
import backgroundPrimary from "assets/images/backgrounds/forest-bg.svg";
import backgroundSecondary from "assets/images/backgrounds/raccoon-bg.svg";

import LanguageSwitcher from "components/LanguageSwitcher";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import {
  hideMessage,
  showAuthLoader,
  userloginWithEmailPassword
} from "redux/actions/Auth";
import AlertSystem from "components/AlertSystem";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      // email: "demoadmin@renraccoon.com",
      // password: "12345678",
      email: "",
      password: "",
      langSwitcher: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('login.js componentDidUpdate', this.props)
    /*

        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }

        if (this.props.authUser !== null) {
            //this.props.history.push('/');
        }
*/
  }

  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };

  handleLangRequestClose = () => {
    this.setState({
      langSwitcher: false
    });
  };

  render() {
    const { email, password } = this.state;
    const { loader, locale, error } = this.props;

    const backgroundPrimaryStyle = {
      backgroundImage: `url(${backgroundPrimary})`,
      backgroundSize: "cover",
      display: "flex",
      width: "100%",
      height: "100%"
    };
    const backgroundSecondaryStyle = {
      backgroundImage: `url(${backgroundSecondary})`,
      backgroundSize: "cover",
      display: "flex",
      width: "100%",
      height: "100%"
    };

    if (this.props.auth.loaded) {
      return <Redirect to={"/"} />;
    }

    return (
      <div className="backgroundWrapperPrimary" style={backgroundPrimaryStyle}>
        <div
          className="backgroundWrapperSecondary"
          style={backgroundSecondaryStyle}
        >
          <div className="app-login-wrapper d-flex flex-column justify-content-center align-items-center w-100">
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
              <div className="app-login-main-content">
                <IntlMessages id="auth.login_browserTitle">
                  {title => (
                    <Helmet>
                      <title>{title}</title>
                    </Helmet>
                  )}
                </IntlMessages>

                <div className="app-logo-content d-flex flex-column align-items-center justify-content-center">
                  <div style={{position:'absolute', top:0, right:0, paddingTop:5, paddingRight:5, marginRight:15, textAlign:'right', fontSize:9}}>
                    {version.version}
                  </div>
                  <Link className="logo-lg" to="/">

                    <img
                      src={brandLogo}
                      alt={<IntlMessages id="app.name" />}
                      style={{ height: 50 }}
                    />

                  </Link>

                </div>

                <div className="app-login-content">
                  <div className="app-login-header mb-1">
                    <h1>
                      <IntlMessages id="auth.login_pageTitle" />
                    </h1>
                  </div>

                  <div className="app-login-form">
                    <form>
                      <fieldset>
                        <TextField
                          label={<IntlMessages id="common.email" />}
                          fullWidth
                          onChange={event =>
                            this.setState({ email: event.target.value })
                          }
                          defaultValue={email}
                          margin="normal"
                          className="mt-1 my-sm-3"
                          autoComplete="username"
                        />
                        <TextField
                          type="password"
                          label={<IntlMessages id="common.password" />}
                          fullWidth
                          onChange={event =>
                            this.setState({ password: event.target.value })
                          }
                          defaultValue={password}
                          margin="normal"
                          className="mt-1 my-sm-3"
                          autoComplete="current-password"
                        />

                        {error && (
                          <div style={{ color: "red", marginBottom: "12px" }}>
                            <IntlMessages id={error} />
                          </div>
                        )}

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                          <Button
                            onClick={() => {
                              this.props.showAuthLoader();
                              this.props.userloginWithEmailPassword({
                                email,
                                password
                              });
                            }}
                            variant="contained"
                            color="primary"
                            className="text-white"
                          >
                            <IntlMessages id="button.login" />
                          </Button>

                          <Link to={routesDefault.forgotPassword}>
                            <IntlMessages id="common.forgotPassword" />
                          </Link>
                        </div>

                        {/*
                                                    <div className="app-social-block my-1 my-sm-3">
                                                    <IntlMessages id="signIn.connectWith"/>
                                                    <ul className="social-link">
                                                        <li>
                                                            <IconButton className="icon"
                                                                        onClick={() => {
                                                                            this.props.showAuthLoader();
                                                                            this.props.userFacebookSignIn();
                                                                        }}>
                                                                <i className="zmdi zmdi-facebook"/>
                                                            </IconButton>
                                                        </li>

                                                        <li>
                                                            <IconButton className="icon"
                                                                        onClick={() => {
                                                                            this.props.showAuthLoader();
                                                                            this.props.userTwitterSignIn();
                                                                        }}>
                                                                <i className="zmdi zmdi-twitter"/>
                                                            </IconButton>
                                                        </li>

                                                        <li>
                                                            <IconButton className="icon"
                                                                        onClick={() => {
                                                                            this.props.showAuthLoader();
                                                                            this.props.userGoogleSignIn();

                                                                        }}>
                                                                <i className="zmdi zmdi-google-plus"/>
                                                            </IconButton>
                                                        </li>

                                                    </ul>

                                                </div>
                                                     */}
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>

              {loader && (
                <div className="loader-view">
                  <CircularProgress />
                </div>
              )}

              {/* {showMessage && NotificationManager.error(alertMessage)} */}
              <AlertSystem />
            </div>

            <div className="app-login-container animated slideInUpTiny animation-duration-3 mt-2 pr-3">
              <Dropdown
                className="float-right"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <div className="d-flex align-items-center pointer">
                    <i className={`flag flag-24 flag-${locale.icon}`} />
                    <h4 className="mb-0 ml-1 d-none d-md-block">
                      {locale.name}
                    </h4>
                  </div>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher
                    switchLanguage={this.props.switchLanguage}
                    handleRequestClose={this.handleLangRequestClose}
                  />
                </DropdownMenu>
              </Dropdown>
            </div>
            <div>{process.env.REACT_APP_ENV}</div>
            <div>{process.env.NODE_ENV}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { loader, alertMessage, showMessage, error } = auth;
  return { loader, alertMessage, showMessage, auth, locale, error };
};

export default connect(
  mapStateToProps,
  {
    switchLanguage,
    userloginWithEmailPassword,
    hideMessage,
    showAuthLoader
  }
)(Login);
