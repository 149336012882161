import {
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST,
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_SUCCESS,
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_FAILURE,
  SCHOOL_MESSENGER_ON_SCROLL_CONVERSATION_LIST,
  SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST,

  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_FAILURE,

  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE,

  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_FAILURE,
  SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,

  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_SUCCESS,
  SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_FAILURE,
  SCHOOL_MESSENGER_UNSYNC_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_ON_SCROLL_SELECTED_CONVERSATION
} from "redux/constants/ActionTypes";

const initialSettings = {
  listLoading: false,
  listSynced: false,
  list: {},
  listScrollCount: 30,

  conversationLoading: false,
  conversationLoadingMore: false,
  conversationFetched: false,
  conversationFetchedAll: false,

  conversationSynced: false,
  conversation: {},
  conversationMessages: {},
  conversationMessagesScrollCount: 30
};

const SchoolMessenger = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST: {
      return {
        ...state,
        listLoading: true,
        listSynced: false
      };
    }
    case SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        listSynced: true,
        list: payload
      };
    }
    case SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
        listSynced: false,
        list: {},
        listScrollCount: initialSettings.listScrollCount
      };
    }
    case SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST: {
      return {
        ...state,
        listLoading: false,
        listSynced: false,
        list: {},
        listScrollCount: initialSettings.listScrollCount
      };
    }
    case SCHOOL_MESSENGER_ON_SCROLL_CONVERSATION_LIST: {
      return {
        ...state,
        listScrollCount: payload
      };
    }

    case SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION: {
      return {
        ...state,
        conversationLoading: true,
        conversationFetched: false,
        conversationFetchedAll: false
      }
    }
    case SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_SUCCESS: {
      return {
        ...state,
        conversationLoading: false,
        conversationFetched: true,
        conversationFetchedAll: payload.conversationFetchedAll,
        conversationMessages: payload.messagesList
      }
    }
    case SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION_FAILURE: {
      return {
        ...state,
        conversationLoading: false,
        conversationFetched: false,
        conversationFetchedAll: false,
        conversationMessages: {},
      }
    }
    case SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION: {
      //console.log('SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION')
      return {
        ...state,
        conversationLoadingMore: true
      }
    }

    case SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS: {

      //console.log('SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_SUCCESS')
      //console.log('payload', payload)

      return {
        ...state,
        conversationLoadingMore: false,
        conversationFetchedAll: payload.conversationFetchedAll,
        conversationMessages:
          { ...payload.messagesList,
            ...state.conversationMessages }
      }
    }

    case SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE: {

      //console.log('SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION_FAILURE', payload)
      return {
        ...state,
        conversationLoadingMore: false,
        //conversationFetchedAll: true,
      }
    }


    /*
    case SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION: {
      return {
        ...state,
      }
    }*/
    case SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS: {

      //console.log('SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_SUCCESS')
      //console.log('payload', payload)

      let conversationMessages = state.conversationMessages;
      conversationMessages[payload.key] = payload.value;

      return {
        ...state,
        conversationLastMessageCreatedAt: payload.value.createdAt,
        conversationLastMessageId: payload.key,
        //conversationMessages: { ...state.conversationMessages, ...{ [payload.snapshot.key] : payload.value} }
        /*
        conversationMessages: {
          ...state.conversationMessages,
          ...{
            [payload.key] : payload.value}
        }*/
        conversationMessages
      }
    }

    case SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION_FAILURE: {
      return {
        ...state,
      }
    }

    case SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION: {
      return {
        ...state,
      }
    }

    case SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION: {
      return {
        ...state,
        conversationLoading: true
        //conversationSynced: false,
        //conversation: {},
        //conversationMessages: {},
        //conversationMessagesScrollCount: 30,
      };
    }
    case SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_SUCCESS: {

      return {
        ...state,
        conversationLoading: false,
        conversationSynced: true,
        //conversation: payload.conversation,
        conversationMessages: payload
        //conversationMessagesScrollCount: 30,
      };
    }
    case SCHOOL_MESSENGER_SYNC_SELECTED_CONVERSATION_FAILURE: {
      return {
        ...state,
        conversationLoading: false,
        conversationSynced: false,
        conversation: {},
        conversationMessages: {},
        conversationMessagesScrollCount: initialSettings.conversationMessagesScrollCount
      };
    }
    case SCHOOL_MESSENGER_UNSYNC_SELECTED_CONVERSATION: {
      return {
        ...state,
        conversationLoading: false,
        conversationSynced: false,
        conversation: {},
        conversationMessages: {},
        conversationMessagesScrollCount: initialSettings.conversationMessagesScrollCount
      };
    }
    case SCHOOL_MESSENGER_ON_SCROLL_SELECTED_CONVERSATION: {
      return {
        ...state,
        conversationMessagesScrollCount: payload
      };
    }

    default:
      return state;
  }
};

export default SchoolMessenger;
