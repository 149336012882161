import React from 'react';
import { Route, Switch} from "react-router-dom";
import {connect} from 'react-redux';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'redux/constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from 'util/asyncComponent';
import Header from 'components/HeaderNav';
import Sidebar from 'navigation/SideMenu';
import HeaderMenu from 'navigation/HeaderMenu';
import ManagerRouter from 'routes/manager';
import DashboardRouter from 'routes/dashboard';
import AccountRouter from 'routes/account';
import SchoolMessenger from 'routes/messenger/SchoolMessenger';

import {
  schoolMessengerSyncConversationList,
  schoolMessengerUnSyncConversationList,
} from "redux/actions/SchoolMessenger";

import { studentSyncStudentListActive, studentUnSyncStudentListActive } from "redux/actions/Student";
import { classSyncClassListActive, classUnSyncClassListActive } from "redux/actions/Classes";

class AppContainer extends React.Component {
  componentDidMount() {
    // Sync Data
    !this.props.studentListActiveSynced && this.props.studentSyncStudentListActive({ schoolId: this.props.auth.user.school });
    !this.props.classListActiveSynced && this.props.classSyncClassListActive({ schoolId: this.props.auth.user.school });
    !this.props.schoolMessengerListSynced && this.props.schoolMessengerSyncConversationList({ schoolId: this.props.auth.user.school});
  }

  componentWillUnmount() {
    // Unsync Data.
    this.props.schoolMessengerListSynced && this.props.schoolMessengerUnSyncConversationList();
    this.props.studentListActiveSynced && this.props.studentUnSyncStudentListActive();
    this.props.classListActiveSynced && this.props.classUnSyncClassListActive();
  }

  render() {
    const {drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }
    return (
      <div className={`app-container frontend ${drawerStyle}`}>
        {/*<Tour/>*/}

        <Sidebar/>
        <div className="app-main-container">
          <div className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <HeaderMenu styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <HeaderMenu/>}
          </div>
          <main className="app-main-content-wrapper">
            <Switch>
                <Route path={`/account`} component={AccountRouter}/>
                <Route path={`/manager`} component={ManagerRouter}/>
                <Route path={`/dashboard`} component={DashboardRouter}/>
                <Route path="/messenger/:id?" component={SchoolMessenger}/>
                <Route path={`/`} component={DashboardRouter}/>
                <Route component={asyncComponent(() => import('components/Error404'))}/>
            </Switch>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, settings, student, classes, schoolMessenger}) => {
  const { studentListActiveSynced } = student;
  const { classListActiveSynced } = classes;
  const schoolMessengerListSynced = schoolMessenger.listSynced;
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition, auth, studentListActiveSynced, classListActiveSynced, schoolMessengerListSynced}
};
export default connect(
  mapStateToProps,
  {
    schoolMessengerSyncConversationList,
    schoolMessengerUnSyncConversationList,
    classSyncClassListActive,
    classUnSyncClassListActive,
    studentSyncStudentListActive,
    studentUnSyncStudentListActive
  }
)(AppContainer);
