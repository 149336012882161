import {
  CLASS_FETCH_CLASS,
  CLASS_FETCH_CLASS_SUCCESS,
  CLASS_FETCH_CLASS_FAILURE,
  CLASS_LOAD_CLASS,
  CLASS_LOAD_CLASS_SUCCESS,
  CLASS_LOAD_CLASS_FAILURE,
  CLASS_CREATE_CLASS,
  CLASS_CREATE_CLASS_SUCCESS,
  CLASS_CREATE_CLASS_FAILURE,
  CLASS_UPDATE_CLASS,
  CLASS_UPDATE_CLASS_SUCCESS,
  CLASS_UPDATE_CLASS_FAILURE,
  CLASS_DELETE_CLASS,
  CLASS_DELETE_CLASS_SUCCESS,
  CLASS_DELETE_CLASS_FAILURE,
  CLASS_FETCH_CLASS_LIST_TODAY,
  CLASS_FETCH_CLASS_LIST_TODAY_SUCCESS,
  CLASS_FETCH_CLASS_LIST_TODAY_FAILURE,
  CLASS_FETCH_CLASS_LIST_ACTIVE,
  CLASS_FETCH_CLASS_LIST_ACTIVE_SUCCESS,
  CLASS_FETCH_CLASS_LIST_ACTIVE_FAILURE,
  CLASS_SYNC_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ACTIVE_SUCCESS,
  CLASS_SYNC_CLASS_LIST_ACTIVE_FAILURE,
  CLASS_UNSYNC_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ARCHIVED,
  CLASS_SYNC_CLASS_LIST_ARCHIVED_SUCCESS,
  CLASS_SYNC_CLASS_LIST_ARCHIVED_FAILURE,
  CLASS_UNSYNC_CLASS_LIST_ARCHIVED,
  CLASS_SYNC_CLASS_STUDENT_LIST,
  CLASS_SYNC_CLASS_STUDENT_LIST_SUCCESS,
  CLASS_SYNC_CLASS_STUDENT_LIST_FAILURE,
  CLASS_UNSYNC_CLASS_STUDENT_LIST,
  CLASS_ARCHIVE_CLASS,
  CLASS_ARCHIVE_CLASS_SUCCESS,
  CLASS_ARCHIVE_CLASS_FAILURE,
  CLASS_UNARCHIVE_CLASS,
  CLASS_UNARCHIVE_CLASS_SUCCESS,
  CLASS_UNARCHIVE_CLASS_FAILURE
} from "redux/constants/ActionTypes";

export const classFetchClass = payload => {
  return {
    type: CLASS_FETCH_CLASS,
    payload: payload
  };
};

export const classFetchClassSuccess = payload => {
  return {
    type: CLASS_FETCH_CLASS_SUCCESS,
    payload: payload
  };
};

export const classFetchClassFailure = payload => {
  return {
    type: CLASS_FETCH_CLASS_FAILURE,
    payload: payload
  };
};

export const classLoadClass = payload => {
  return {
    type: CLASS_LOAD_CLASS,
    payload: payload
  };
};

export const classLoadClassSuccess = payload => {
  return {
    type: CLASS_LOAD_CLASS_SUCCESS,
    payload: payload
  };
};

export const classLoadClassFailure = payload => {
  return {
    type: CLASS_LOAD_CLASS_FAILURE,
    payload: payload
  };
};

export const classCreateClass = payload => {
  return {
    type: CLASS_CREATE_CLASS,
    payload: payload
  };
};

export const classCreateClassSuccess = payload => {
  return {
    type: CLASS_CREATE_CLASS_SUCCESS,
    payload: payload
  };
};

export const classCreateClassFailure = payload => {
  return {
    type: CLASS_CREATE_CLASS_FAILURE,
    payload: payload
  };
};

export const classUpdateClass = payload => {
  return {
    type: CLASS_UPDATE_CLASS,
    payload: payload
  };
};

export const classUpdateClassSuccess = payload => {
  return {
    type: CLASS_UPDATE_CLASS_SUCCESS,
    payload: payload
  };
};

export const classUpdateClassFailure = payload => {
  return {
    type: CLASS_UPDATE_CLASS_FAILURE,
    payload: payload
  };
};

export const classDeleteClass = payload => {
  return {
    type: CLASS_DELETE_CLASS,
    payload: payload
  };
};

export const classDeleteClassSuccess = payload => {
  return {
    type: CLASS_DELETE_CLASS_SUCCESS,
    payload: payload
  };
};

export const classDeleteClassFailure = payload => {
  return {
    type: CLASS_DELETE_CLASS_FAILURE,
    payload: payload
  };
};

export const classFetchClassListActive = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_ACTIVE,
    payload: payload
  };
};

export const classFetchClassListActiveSuccess = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_ACTIVE_SUCCESS,
    payload: payload
  };
};

export const classFetchClassListActiveFailure = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_ACTIVE_FAILURE,
    payload: payload
  };
};

export const classFetchClassListToday = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_TODAY,
    payload: payload
  };
};

export const classFetchClassListTodaySuccess = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_TODAY_SUCCESS,
    payload: payload
  };
};

export const classFetchClassListTodayFailure = payload => {
  return {
    type: CLASS_FETCH_CLASS_LIST_TODAY_FAILURE,
    payload: payload
  };
};

export const classSyncClassListActive = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ACTIVE,
    payload: payload
  };
};

export const classSyncClassListActiveSuccess = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ACTIVE_SUCCESS,
    payload: payload
  };
};

export const classSyncClassListActiveFailure = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ACTIVE_FAILURE,
    payload: payload
  };
};

export const classUnSyncClassListActive = payload => {
  return {
    type: CLASS_UNSYNC_CLASS_LIST_ACTIVE,
    payload: payload
  };
};

export const classSyncClassListArchived = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ARCHIVED,
    payload: payload
  };
};

export const classSyncClassListArchivedSuccess = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ARCHIVED_SUCCESS,
    payload: payload
  };
};

export const classSyncClassListArchivedFailure = payload => {
  return {
    type: CLASS_SYNC_CLASS_LIST_ARCHIVED_FAILURE,
    payload: payload
  };
};

export const classUnSyncClassListArchived = payload => {
  return {
    type: CLASS_UNSYNC_CLASS_LIST_ARCHIVED,
    payload: payload
  };
};

export const classSyncClassStudentList = payload => {
  return {
    type: CLASS_SYNC_CLASS_STUDENT_LIST,
    payload: payload
  };
};

export const classSyncClassStudentListSuccess = payload => {
  return {
    type: CLASS_SYNC_CLASS_STUDENT_LIST_SUCCESS,
    payload: payload
  };
};

export const classSyncClassStudentListFailure = payload => {
  return {
    type: CLASS_SYNC_CLASS_STUDENT_LIST_FAILURE,
    payload: payload
  };
};

export const classUnSyncClassStudentList = payload => {
  return {
    type: CLASS_UNSYNC_CLASS_STUDENT_LIST,
    payload: payload
  };
};

// ##########

export const classArchiveClass = payload => {
  return {
    type: CLASS_ARCHIVE_CLASS,
    payload: payload
  };
};

export const classArchiveClassSuccess = payload => {
  return {
    type: CLASS_ARCHIVE_CLASS_SUCCESS,
    payload: payload
  };
};

export const classArchiveClassFailure = payload => {
  return {
    type: CLASS_ARCHIVE_CLASS_FAILURE,
    payload: payload
  };
};

export const classUnArchiveClass = payload => {
  return {
    type: CLASS_UNARCHIVE_CLASS,
    payload: payload
  };
};

export const classUnArchiveClassSuccess = payload => {
  return {
    type: CLASS_UNARCHIVE_CLASS_SUCCESS,
    payload: payload
  };
};

export const classUnArchiveClassFailure = payload => {
  return {
    type: CLASS_UNARCHIVE_CLASS_FAILURE,
    payload: payload
  };
};
