import React from 'react';
import StudentAvatar from 'components/Avatar/StudentAvatar';
import IntlMessages from 'util/IntlMessages';
import NoMailWarningIcon from "@material-ui/icons/Report";
import Tooltip from '@material-ui/core/Tooltip';
import {NavLink} from 'react-router-dom';


const StudentListItem = ({data, onSelectMessengerConversation, authUser, locale, onItemClick, itemClassName, selectedConversation}) => {



    let hasOfficeMessenger = false;



    // Check if student has guardians.
    if(data.metadata.hasOwnProperty('guardians')){
        if(Object.keys(data.metadata.guardians).length !== 0){
            hasOfficeMessenger = true;
        }
    }

    let fullname = null;



    if(locale.locale === 'ja') {

        if(data.profile.firstNameFurigana && data.profile.lastNameFurigana){
            fullname = data.profile.lastNameFurigana + ' ' + data.profile.firstNameFurigana;

            if(data.profile.lastNameKanji){
                fullname = fullname + ' (' + data.profile.lastNameKanji + ')'
            }

        }else{
            fullname = data.profile.lastNameRomaji + ' ' + data.profile.firstNameRomaji;
        }

    }else{
        if(data.profile.firstNameRomaji && data.profile.lastNameRomaji){
            fullname = data.profile.firstNameRomaji + ' ' + data.profile.lastNameRomaji;
        }else{
            fullname = data.profile.lastNameFurigana + ' ' + data.profile.firstNameFurigana;
        }
    }


    if(!hasOfficeMessenger){

        return(
          <li className="messenger-list-item">

              <div
                style={{display:'inline-flex', paddingLeft:10, paddingTop: 5, paddingRight: 10, paddingBottom:10, alignItems: 'center'}}
                className={!hasOfficeMessenger ? "messenger-list-item no-click" : "messenger-list-item"}
              >
                  <StudentAvatar
                    data={data.profile}
                    size={32}
                    classes=" mr-2"
                  />

                  <div className="media-body align-self-center">
                      <div className="d-flex justify-content-between">
                          <div className="heading name text-left">
                              <span>{fullname}</span>
                          </div>

                      </div>
                      <div className="sub-heading">
                          <p className="sub-heading mb-0 text-left" style={{height:15, overflow: 'hidden'}}>

                              { Object.keys(data.classes).length !== 0 && data.classes.constructor === Object
                                ? Object.values(data.classes).map((classItem, index) => {

                                    if(index >= 1){
                                        return(
                                          locale.locale === 'ja' || locale.locale === 'zh'
                                            ? ' & ' + classItem.profile.nameFurigana
                                            : ' & ' + classItem.profile.nameRomaji
                                        )
                                    }else{
                                        return(
                                          locale.locale === 'ja' || locale.locale === 'zh'
                                            ? classItem.profile.nameFurigana
                                            : classItem.profile.nameRomaji
                                        )
                                    }
                                })
                                : <span><IntlMessages id="common.noClass"/></span>
                              }
                          </p>
                      </div>
                  </div>

                  {!hasOfficeMessenger &&
                  <div>
                      <Tooltip title={<IntlMessages id="warningMessage.student_has_no_guardians"/>} placement="right">
                          <NoMailWarningIcon color="error"/>
                      </Tooltip>

                  </div>
                  }
              </div>


          </li>
          )


    }

    return (
        <li className="messenger-list-item">

            <NavLink
              exact
              to={"/messenger/" + data.metadata.family}
              className={!hasOfficeMessenger ? "messenger-list-item no-click" : "messenger-list-item"}
              activeClassName={'current-conversation active'}
            >
                <StudentAvatar
                  data={data.profile}
                  size={32}
                  classes=" mr-2"
                />

                <div className="media-body align-self-center">
                    <div className="d-flex justify-content-between">
                        <div className="heading name text-left">
                            <span>{fullname}</span>
                        </div>

                    </div>
                    <div className="sub-heading">
                        <p className="sub-heading mb-0 text-left" style={{height:15, overflow: 'hidden'}}>

                            { Object.keys(data.classes).length !== 0 && data.classes.constructor === Object
                              ? Object.values(data.classes).map((classItem, index) => {

                                  if(index >= 1){
                                      return(
                                        locale.locale === 'ja' || locale.locale === 'zh'
                                          ? ' & ' + classItem.profile.nameFurigana
                                          : ' & ' + classItem.profile.nameRomaji
                                      )
                                  }else{
                                      return(
                                        locale.locale === 'ja' || locale.locale === 'zh'
                                          ? classItem.profile.nameFurigana
                                          : classItem.profile.nameRomaji
                                      )
                                  }
                              })
                              : <span><IntlMessages id="common.noClass"/></span>
                            }
                        </p>
                    </div>
                </div>

                {!hasOfficeMessenger &&
                <div>
                    <Tooltip title={<IntlMessages id="warningMessage.student_has_no_guardians"/>} placement="right">
                        <NoMailWarningIcon color="error"/>
                    </Tooltip>

                </div>
                }
            </NavLink>


        </li>
    );
};

export default StudentListItem;