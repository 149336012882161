import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';


export default {

    palette: {
        primary: {
            light: green[300],
            main: green[500],
            dark: green[700],
            contrastText: '#fff'
        },
        secondary: {
            light: lightGreen[400],
            main: lightGreen[500],
            dark: lightGreen[700],
            contrastText: '#fff'
        },
        error: {
            light: red[300],
            main: red['A700'],
            dark: red[700],
            contrastText: '#fff'
        },
        warning: {
            light: yellow[300],
            main: yellow['A700'],
            dark: yellow[700],
            contrastText: '#fff'
        }
    },
    status: {
        danger: 'orange',
    },
    typography: {
        useNextVariants: true,
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    },

    overrides: {
        MuiPaper: {
            elevation1: {
                borderRadius: 0,
                boxShadow: '7px 7px 0px -3px rgba(0,0,0,0.15)',
            },
            elevation2: {
                borderRadius: 0,
                boxShadow: 'unset',
            },
        },
        MuiButton: {

            raised: {
                // Name of the rule

                //boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)',
                //boxShadow: '7px 7px 0px -3px rgba(0,0,0,0.15)',
                boxShadow: '0',
            },
            contained: {

                //boxShadow: '7px 7px 0px -3px rgba(0,0,0,0.15)',
                boxShadow: '0',
            },
        },
        MUIDataTable: {
            paper: {
                borderRadius: 0,
                boxShadow: "none"
            }
        },
        MuiTableCell: {
            root:  {
                display: 'table-cell',
                padding: '4px',
                textAlign: 'left',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                verticalAlign: 'inherit',
            },
            head: {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.75rem',
                fontWeight: '500',
                borderTop: '1px solid #d5d5d5',
            }

        },
        MuiTableRow: {
            hover:{
                '&:hover': {
                    backgroundColor: '#00000005 !important',
                }
            }
        },
        MUIDataTableToolbarSelect: {
            root: {
                boxShadow: 'unset',
                flex: '1 1 100%',
                height: '64px',
                display: 'flex',
                zIndex: '120',
                position: 'relative',
                paddingTop: '8px',
                paddingBottom: '8px',
                justifyContent: 'space-between',
                backgroundColor: '#fafafa',
            }
        },
        MUIDataTableFilterList: {
            root: {
                margin: '0px 16px 12px 16px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'left'
            }
        },
        /*
        MuiDialogTitle: {
            root: {
                backgroundColor: green[500],
                '& h6': {
                    color: 'white'
                }
            }
        }
        */

    }
};
