import React, { useState } from 'react';
import TimeAgoIntl from 'util/IntlTimeAgo';

import Tooltip from '@material-ui/core/Tooltip';
import IntlMessages from 'util/IntlMessages';
import moment from "moment-timezone";
import StudentAvatar from "components/Avatar/StudentAvatar";
import Linkify from 'react-linkify';
import { getTranslatedText } from "../../../../../../util/googleTranslateApi"



const ReceivedMessageCell = ({ conversation, message, locale }) => {
  const [isShowTransltedMessage, setIsShowTransltedMessage] = useState(false)
  const [previousMessage, setPreviousMessage] = useState("")
  const [activeTranslatedMessage, setActiveTranslatedMessage] = useState("...")


  let readByUsers = false;
  if (message.hasOwnProperty('readBy')) {
    if (message.readBy.hasOwnProperty('office')) {
      if(message.readBy.office){
        readByUsers = true;
      }
    }
  }


  /*
  console.log('message', message)
  console.log('time', message.createdAt)
  console.log('time2', moment(message.createdAt).format('lll'))
  */


  const handleTranslation = async (message, locale) => {

    if (message === previousMessage) {
      setIsShowTransltedMessage(true)
    }
    else {
      setPreviousMessage(message);

      const translatedMessage = await getTranslatedText(message, locale);
      setActiveTranslatedMessage(translatedMessage);
      
      setIsShowTransltedMessage(true)
    }

  };



  const handleShowTranslation = () => {
    setIsShowTransltedMessage(false)
  };


  if(message.hasOwnProperty('image')){

    return (
      <div className="messenger-chat">
        <div className="d-flex flex-nowrap chat-item pb-0">
          <StudentAvatar data={{}} size={30} />
          <div className="bubble">
            <div className="message">
              <img src={message.image}  style={{width:100}} alt={''}/>

            </div>
          </div>
        </div>
        <div className="chat-time text-muted text-left mt-1 ml-1 chat-date-wrapper chat-left">

          <TimeAgoIntl date={message.createdAt} locale={locale.locale} live={true} />
          {readByUsers &&
          <Tooltip title={<IntlMessages id="common.messageRead" values={{ time: moment(message.readBy['office']).format('lll') }} />} placement="bottom-end">
            <i className="zmdi zmdi-check-circle" style={{ marginLeft: 5, color: 'green' }}></i>
          </Tooltip>
          }
        </div>
      </div>
    )

  }

  return (
    <div className="messenger-chat">
      <div className="d-flex flex-nowrap chat-item pb-0">
        <StudentAvatar data={{}} size={30} />
        <div className="bubble">
          <div className="message">
            <Linkify properties={{ target: '_blank' }}>
              {message.message.split('\n').map((item, key) => {
                return <span key={key}>{item}<br /> </span>
              })}
            </Linkify>
          </div>
          {!isShowTransltedMessage && (
            <div className="chat-see-translation" onClick={() => handleTranslation(message.message, locale.locale)}>
              {message.message === previousMessage
                ? <IntlMessages id="common.show_translation" />
                : <IntlMessages id="common.see_translation" />
              }
            </div>
          )}

          {isShowTransltedMessage && activeTranslatedMessage && (
            <div style={{ borderLeft: "3px solid #dcdee3", paddingLeft: "6px", color: "#38424ba6" }}>
              {activeTranslatedMessage}
            </div>
          )}
          {isShowTransltedMessage && (
            <div className="chat-see-translation" onClick={() => handleShowTranslation()}>

              <IntlMessages id="common.hide_translation" />
            </div>
          )}
        </div>
      </div>
      <div className="chat-time text-muted text-left mt-1 ml-1 chat-date-wrapper chat-left">

        <TimeAgoIntl date={message.createdAt} locale={locale.locale} live={true} />
        {readByUsers &&
          <Tooltip title={<IntlMessages id="common.messageRead" values={{ time: moment(message.readBy['office']).format('lll') }} />} placement="bottom-end">
            <i className="zmdi zmdi-check-circle" style={{ marginLeft: 5, color: 'green' }}></i>
          </Tooltip>
        }
      </div>
    </div>
  )
};

export default ReceivedMessageCell;