import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { sortBy } from "lodash";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import LeftSideMenuIcon from "@material-ui/icons/VerticalSplit";
import SearchBox from "components/SearchBox";
import ConversationListItem from "./ConversationListItem";
import StudentListItem from "./StudentListItem";


import {
  schoolMessengerSyncConversationList,
  schoolMessengerUnSyncConversationList,
  schoolMessengerOnScrollConversationList
} from "redux/actions/SchoolMessenger";

import { studentSyncStudentListActive, studentUnSyncStudentListActive } from "redux/actions/Student";

import { classSyncClassListActive, classUnSyncClassListActive } from "redux/actions/Classes";

function TabContainer(props) {
  return (
    <div
      style={{
        padding: 0,
        paddingTop: 0
      }}
    >
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const drawerWidthLeft = 280;
const drawerWidth = 280;

const styles = theme => ({
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerLeft: { flexShrink: 0 },
  drawer: {
    flexShrink: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerShift: {
    flexShrink: 0,
    width: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerLeftPaper: {
    //top:80,
    width: drawerWidthLeft,
    height: "calc(100vh - 70px)"
  },
  drawerPaper: {
    //top:80,
    width: drawerWidth,
    height: "calc(100vh - 70px)",
    position: "relative",

    borderLeft: "solid 1px #e9ecef"
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  }
});

class LeftSidePanel extends React.Component {

  //static whyDidYouRender = true;

  constructor() {
    super();
    this.state = {
      selectedTabIndex: 0,
      leftSidebarOpen: true,
      search: ""
    };
  }

  componentDidMount() {

    //console.log('Messenger LeftSidePanel - ComponentDidMount')

    if(!this.props.match.params.id){
      if (this.props.conversationList[0]) {
        // Replace URL in history and redirect to last conversation.
        this.props.history.replace("/messenger/" + this.props.conversationList[0].id);
      } else {
        // Change Tab to Students.
        this.setState({
          selectedTabIndex: 1
        });
      }
    }


  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {

    //console.log('index nextProps', nextProps)
    //console.log('nextState', nextState)
    //console.log('nextContext', nextContext)
    if(this.state !== nextState){
      return true;
    }

    /*
    if(this.props.schoolMessagesListSynced !== nextProps.schoolMessagesListSynced){
      return true;
    }*/
    /*
    if(this.props.conversationListSynced !== nextProps.conversationListSynced){
      return true;
    }*/

    if(this.props.conversationListObj !== nextProps.conversationListObj){
      return true;
    }

    if(this.props.studentList !== nextProps.studentList){
      return true;
    }






    return false;
  }




  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check for conversationId.

    //console.log('Messenger LeftSidePanel - ComponentDidUpdate')

    if (prevProps.settings.width !== this.props.settings.width) {

      if (this.props.settings.width >= 768) {
        this.setState({
          leftSidebarOpen: true
        });
      } else {
        this.setState({
          leftSidebarOpen: false
        });
      }
    }



  }

  componentWillUnmount() {
  }

  onUpdateSearchList(evt) {
    this.setState({ search: evt.target.value });
  }

  onSearchListReset(evt) {
    this.setState({ search: "" });
  }

  handleTabChange = (event, value) => {
    this.setState({ selectedTabIndex: value });
  };

  handleTabChangeIndex = index => {
    this.setState({ selectedTabIndex: index });
  };

  handleLeftSidebarToggle = () => {
    this.setState(state => ({ leftSidebarOpen: !state.leftSidebarOpen }));
  };

  handleScrollReachBottom = values => {
    const { scrollHeight } = values;
    if (scrollHeight > 280) {
      this.props.onScrollAppPageMessenger(this.props.scrollCountAppPage);
    }
  };



  render() {

    let { studentList, conversationList } = this.props;
    const { locale, auth, conversationListLoading, studentListLoading } = this.props;
    const { search } = this.state;

    // Filter Search
    if (search) {
      if (locale.locale === "ja" || locale.locale === "zh") {

        // For Students List
        studentList = studentList.filter(item => {
          const classNameArray = [];
          Object.keys(item.classes).forEach(nKey => {
            if (item.classes[nKey] && item.classes[nKey].profile.nameFurigana) {
              classNameArray.push(item.classes[nKey].profile.nameFurigana);
            } else {
              classNameArray.push(item.classes[nKey].profile.nameRomaji);
            }
          });
          switch(classNameArray.length) {
            case 0:
              return (
                item.profile.nameFurigana.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.profile.nameKanji.toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 1:
              return (
                item.profile.nameFurigana.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.profile.nameKanji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 2:
              return (
                item.profile.nameFurigana.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.profile.nameKanji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 3:
              return (
                item.profile.nameFurigana.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.profile.nameKanji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            default:
              return (
                item.profile.nameFurigana.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                item.profile.nameKanji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
          }
        });

        // For Messages List
        conversationList = conversationList.filter(item => {
          const classNameArray = [];
          const studentNameArray = [];

          Object.keys(item.students).forEach(nKey => {

            if(item.students[nKey].profile === undefined){
              console.log('undefined student',nKey)
            }


            //Check if object
            if(Object.keys( item.students[nKey]).length !== 0){
              if (item.students[nKey] && item.students[nKey].profile.nameFurigana) {
                studentNameArray.push(item.students[nKey].profile.nameFurigana);
              } else {
                studentNameArray.push(item.students[nKey].profile.nameRomaji);
              }

              if(item.students[nKey].hasOwnProperty('classes')){
                Object.keys(item.students[nKey].classes).forEach(nClassKey => {
                  if (item.students[nKey].classes[nClassKey] && item.students[nKey].classes[nClassKey].profile.nameFurigana) {
                    classNameArray.push(item.students[nKey].classes[nClassKey].profile.nameFurigana);
                  } else {
                    classNameArray.push(item.students[nKey].classes[nClassKey].profile.nameRomaji);
                  }
                });
              }
            }else{

              //ToDo: temp console.log for messed up archived students.
             // console.log('BB > item.students[nKey]', item.students[nKey])
              //console.log('nKey', nKey)
            }


          });

          switch(studentNameArray.length) {
            case 0:

              return null;
            case 1:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 2:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 3:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            default:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1  ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
          }

        });



      } else {
        //Locale default


        // For Messages List
        conversationList = conversationList.filter(item => {
          const classNameArray = [];
          const studentNameArray = [];

          Object.keys(item.students).forEach(nKey => {



            //Check if object
            if(Object.keys( item.students[nKey]).length !== 0){
              if (item.students[nKey] && item.students[nKey].profile.nameRomaji) {
                studentNameArray.push(item.students[nKey].profile.nameRomaji);
              } else {
                studentNameArray.push(item.students[nKey].profile.nameFurigana);
              }

              if(item.students[nKey].hasOwnProperty('classes')){
                Object.keys(item.students[nKey].classes).forEach(nClassKey => {
                  if (item.students[nKey].classes[nClassKey] && item.students[nKey].classes[nClassKey].profile.nameRomaji) {
                    classNameArray.push(item.students[nKey].classes[nClassKey].profile.nameRomaji);
                  } else {
                    classNameArray.push(item.students[nKey].classes[nClassKey].profile.nameFurigana);
                  }
                });
              }
            }else{


              //ToDo: temp console.log for messed up archived students.
              //console.log('AAA > item.students[nKey]', item.students[nKey])
              //console.log('nKey', nKey)
            }


          });

          switch(studentNameArray.length) {
            case 0:

              return null;
            case 1:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 2:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 3:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  studentNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                studentNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            default:
              if(classNameArray.length >= 1){
                return (
                  studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1  ||
                  classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
                );
              }
              return (
                studentNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
          }

        });


        // For Students List
        studentList = studentList.filter(item => {
          const classNameArray = [];
          Object.keys(item.classes).forEach(nKey => {
            if (item.classes[nKey] && item.classes[nKey].profile.nameRomaji) {
              classNameArray.push(item.classes[nKey].profile.nameRomaji);
            } else {
              classNameArray.push(item.classes[nKey].profile.nameFurigana);
            }
          });

          switch(classNameArray.length) {
            case 0:
              return (
                item.profile.nameRomaji.toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 1:
              return (
                item.profile.nameRomaji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 2:
              return (
                item.profile.nameRomaji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            case 3:
              return (
                item.profile.nameRomaji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[1].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[2].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
            default:
              return (
                item.profile.nameRomaji.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                classNameArray[0].toLowerCase().indexOf(search.toLowerCase()) > -1
              );
          }

        });

      }

    }

    return (
      <div className="chat-sidenav-main messenger" style={{ display: "flex", justifyContent: "first", alignItems: "top" }}>
        <div className="chat-sidenav-button-container d-md-none">
          <IconButton className="" aria-label="Open" onClick={this.handleLeftSidebarToggle}>
            <LeftSideMenuIcon />
          </IconButton>
        </div>

        <div className="chat-sidenav">
          {this.state.leftSidebarOpen &&

          <div className="chat-sidenav-content">
            <AppBar position="static" className="no-shadow chat-tabs-header">
              <Tabs
                className="chat-tabs"
                value={this.state.selectedTabIndex}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                style={{ height: "auto" }}
                fullWidth
              >
                <Tab label={<IntlMessages id="common.messages" />} />
                <Tab label={<IntlMessages id="common.students" />} />
              </Tabs>
            </AppBar>

            <div className="search-wrapper chat-sidenav-search-wrapper">
              <IntlMessages id="common.search_placeholder">
                {label => (
                  <SearchBox
                    placeholder={label}
                    onChange={this.onUpdateSearchList.bind(this)}
                    onReset={this.onSearchListReset.bind(this)}
                    value={this.state.search}
                  />
                )}
              </IntlMessages>
            </div>

            <SwipeableViews index={this.state.selectedTabIndex} onChangeIndex={this.handleTabChangeIndex}>
              <TabContainer>
                <CustomScrollbars
                  className="chat-sidenav-scroll scrollbar"
                  style={{ height: this.props.width >= 1200 ? "calc(100vh - 428px)" : "calc(100vh - 237px)" }}
                >
                  {conversationListLoading ? (
                    <div className="d-flex align-items-center h-100 justify-content-center">
                      <CircularProgress size={30} />
                    </div>
                  ) : conversationList.length === 0 ? (
                    <div className="d-flex align-items-center h-100 justify-content-center">
                      {this.state.search !== "" ? <IntlMessages id="common.noSearchData" /> : <IntlMessages id="common.noMessages" />}
                    </div>
                  ) : (
                    <div className="aside-messages-list">
                      <ul className="list-unstyled">
                        {conversationList.map((conversationItem, index) => {

                          if(!conversationItem.lastMessage.system){
                            return (
                              <ConversationListItem
                                key={index}
                                data={conversationItem}
                                locale={locale}
                                authUser={auth}
                                x={index}
                              />
                            )
                          }else{
                            return null;
                          }
                        })}
                      </ul>
                    </div>
                  )}
                </CustomScrollbars>
              </TabContainer>

              <TabContainer>
                <CustomScrollbars
                  className="chat-sidenav-scroll scrollbar"
                  style={{ height: this.props.width >= 1200 ? "calc(100vh - 408px)" : "calc(100vh - 237px)" }}
                >
                  {studentListLoading ? (
                    <div className="d-flex align-items-center h-100 justify-content-center">
                      <CircularProgress size={30} />
                    </div>
                  ) : studentList.length === 0 ? (
                    <div className="d-flex align-items-center h-100 justify-content-center">
                      {this.state.search !== "" ? <IntlMessages id="common.noSearchData" /> : <IntlMessages id="common.noStudents" />}
                    </div>
                  ) : (
                    <div className="aside-messages-list">
                      <ul className="list-unstyled">
                        {studentList.map((studentItem, index) => {
                            return (
                              <StudentListItem
                                key={index}
                                data={studentItem}
                                locale={locale}
                                authUser={auth}
                              />
                            )
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </CustomScrollbars>
              </TabContainer>
            </SwipeableViews>
          </div>

          }
        </div>

      </div>

    );

  }
}

const mapStateToProps = (props, state) => {

  const { settings, auth, schoolMessenger, student, classes } = props;
  const { locale } = settings;
  const conversationListLoading = schoolMessenger.listLoading;
  const conversationListSynced = schoolMessenger.listSynced;
  const studentListLoading = student.studentListActiveLoading;
  const { studentListActiveSynced } = student;
  const { classListActiveSynced } = classes;
  let dataLoaded = false;

  // Combine conversationList data.
  let conversationList = [];
  let conversationListObj = {};

  ///console.log('schoolMessenger', schoolMessenger)

  if (schoolMessenger.listSynced && student.studentListActiveSynced && classes.classListActiveSynced) {
    const basicConversationListObj = schoolMessenger.list;
    const studentListObj = student.studentListActive;
    const classesListObj = classes.classListActive;

    if (basicConversationListObj && studentListObj && classesListObj) {
      conversationList = Object.keys(basicConversationListObj).map(conversationKey => {
        let v = basicConversationListObj[conversationKey];



        if (v.students) {
          Object.keys(v.students).map(studentKey => {
            let studentData = {};

            if (!studentListObj.hasOwnProperty(studentKey)) {
              return null;
            } else {
              studentData.id = studentKey;
              studentData.profile = studentListObj[studentKey].profile;
              studentData.classes = {};

              if (studentListObj[studentKey].metadata.classes) {
                Object.keys(studentListObj[studentKey].metadata.classes).map(classKey => {
                  if (!classesListObj.hasOwnProperty(classKey)) {
                    return null;
                  }
                  let classObj = {};
                  classObj.id = classesListObj[classKey].metadata.id;
                  classObj.profile = classesListObj[classKey].profile;

                  return (studentData.classes[classKey] = classObj);
                });
              }
            }
            return (v.students[studentKey] = studentData);
          });
        }

        conversationListObj[conversationKey] = v;
        return v;
      });
      dataLoaded = true;
    }
  }

  //console.log('conversationList - count', conversationList.length)

  //console.log('conversationList - count 48', conversationList[48])



  // Combine studentList data.
  let studentList = [];
  if (student.studentListActiveSynced && classes.classListActiveSynced) {
    const studentListObj = student.studentListActive;
    const classesListObj = classes.classListActive;

    const sortArray = ["profile.lastNameRomaji"];



    if (studentListObj && classesListObj) {
      studentList = sortBy(
        Object.keys(studentListObj).map(key => {
          let v = studentListObj[key];
          v.classes = {};

          if (v.metadata.classes) {
            Object.keys(v.metadata.classes).map(classKey => {
              if (!classesListObj.hasOwnProperty(classKey)) {
                return null;
              }
              return (v.classes[classKey] = classesListObj[classKey]);
            });
          }

          return v;
        }),
        sortArray
      );
    }
  }

  const conversationSynced = schoolMessenger.conversationSynced;
  conversationList.sort((a, b) => (a.lastMessage.createdAt < b.lastMessage.createdAt) ? 1 : -1);

  return {
    settings,
    auth,
    locale,
    conversationListLoading,
    conversationListSynced,
    conversationList,
    studentListLoading,
    studentList,
    dataLoaded,
    conversationSynced,
    conversationListObj,
    studentListActiveSynced,
    classListActiveSynced,

    schoolMessagesListSynced: schoolMessenger.listSynced

    //selectedConversationLoading,
    //schoolMessagesNewCount
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      schoolMessengerSyncConversationList,
      schoolMessengerUnSyncConversationList,
      schoolMessengerOnScrollConversationList,

      classSyncClassListActive,
      classUnSyncClassListActive,

      studentSyncStudentListActive,
      studentUnSyncStudentListActive
    }
  )
)(LeftSidePanel);
