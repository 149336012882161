import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import SchoolMessages from './SchoolMessages';

import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'redux/constants/ActionTypes';

//import CardHeader from 'components-all/dashboard/Common/CardHeader/index';
import {switchLanguage, toggleCollapsedNav} from 'redux/actions/Setting';
//import IntlMessages from 'util/IntlMessages';
import LanguageSwitcher from 'components/LanguageSwitcher';
import HeaderMenu from 'navigation/HeaderMenu' ;
import UserInfoPopup from './UserInfoPopup';


import MessengerIcon from '@material-ui/icons/Chat';

class HeaderNav extends React.Component {

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      messengerSchoolNotification: false,
      messengerUserNotification: false,
      userInfo: false,
      appNotification: false,
    }
  }

  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };

  onMessengerSchoolNotificationSelect = () => {
    this.setState({
      messengerSchoolNotification: !this.state.messengerSchoolNotification
    })
  };

  onMessengerUserNotificationSelect = () => {
    this.setState({
      messengerUserNotification: !this.state.messengerUserNotification
    })
  };

  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      messengerSchoolNotification: false,
      messengerUserNotification: false,

      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };




  render() {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    let messengerIconStyle = 'icon-alert animated wobble';


    if(this.props.auth.userMetadata !== null){
      if(this.props.auth.userMetadata.badges){
        if(this.props.auth.userMetadata.badges.messengerCount === 0){
          messengerIconStyle = 'svg-icon';
        }
      }
    }


    if(this.props.auth.user !== null){

      if(this.props.auth.user.role === 'manager'){

        messengerIconStyle = 'icon-alert animated wobble';



        if(this.props.school.loaded){

          if(this.props.school.schoolMetadata){
            if(this.props.school.schoolMetadata.badges.messengerFamilyCount >= 1){
              messengerIconStyle = 'icon-alert animated wobble';
            }
          }

        }
      }
    }


    const RenderMessengerIcon = () => {

      if(this.props.auth.user !== null){

        if(this.props.auth.user.role === 'manager'){

          messengerIconStyle = 'icon-alert animated wobble';

          if(this.props.school.loaded){

            if(this.props.school.schoolMetadata){
              if(this.props.school.schoolMetadata.badges.messengerFamilyCount >= 1){
                messengerIconStyle = 'icon-alert animated wobble';
              }

              return(
                <IconButton className="icon-btn size-20 font-size-20 pt-0">
                  {this.props.auth.userMetadata !== null &&
                  this.props.school.schoolMetadata.badges.messengerFamilyCount >= 1
                    ? <Badge className={'t'} badgeContent={this.props.school.schoolMetadata.badges.messengerFamilyCount} color="secondary">
                      <MessengerIcon className={messengerIconStyle}/>
                    </Badge>
                    : <MessengerIcon className={messengerIconStyle}/>
                  }
                </IconButton>
              )

            }

          }

        }
      }

      return(
        <IconButton className="icon-btn size-20 font-size-20 pt-0">
          {this.props.auth.userMetadata !== null &&
          this.props.auth.userMetadata.badges.messengerCount >= 1
            ? <Badge className={'t'} badgeContent={this.props.auth.userMetadata.badges.messengerCount} color="secondary">
              <MessengerIcon className={messengerIconStyle}/>
            </Badge>
            : <MessengerIcon className={messengerIconStyle}/>
          }
        </IconButton>
      )




    };


    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>

        <div className="container-fluid">
          <Toolbar className="app-toolbar" disableGutters={true}>
            {navigationStyle === HORIZONTAL_NAVIGATION ?
              <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
              </div>
              :
              <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                          onClick={this.onToggleCollapsedNav}>
                <span className="menu-icon"/>
              </IconButton>
            }

            <Link className="app-logo mr-2 d-none d-sm-block" to="/">
              <img src={require("assets/images/logo.svg")} alt="Renraccoon" title="Renraccoon"/>
            </Link>

            { this.props.school.schoolProfile &&
            <div>
              {locale.locale === 'ja'
                ? <div style={{fontSize:14}}>
                    {this.props.school.schoolProfile.nameJa}
                    {this.props.school.schoolProfile.branchNameJa ? <span> ({this.props.school.schoolProfile.branchNameJa})</span> : ''}
                  </div>
                : <div style={{fontSize:16}}>
                    {this.props.school.schoolProfile.nameEn}
                    {this.props.school.schoolProfile.branchNameEn ? <span> ({this.props.school.schoolProfile.branchNameEn})</span> : ''}
                  </div>
              }
            </div>
            }




            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
            <HeaderMenu/>}

            {this.props.auth.user &&
            <ul className="header-notifications list-inline ml-auto">

              <li className="list-inline-item mail-tour d-none">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.messengerUserNotification}
                  toggle={this.onMessengerUserNotificationSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">

                    <RenderMessengerIcon/>




                  </DropdownToggle>


                  <DropdownMenu right>
                    userMessages
                  </DropdownMenu>
                </Dropdown>
              </li>

              {this.props.auth.user && this.props.school.metadataSynced && (this.props.auth.user.role === 'admin' || this.props.auth.user.role === 'manager') &&
              <li className="list-inline-item mail-tour">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.messengerSchoolNotification}
                  toggle={this.onMessengerSchoolNotificationSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">

                    <IconButton className="icon-btn size-20 font-size-20 pt-0">

                      {this.props.school.schoolMetadata.badges.messengerFamilyCount >= 1
                        ? <Badge className={'t'}
                                 badgeContent={this.props.school.schoolMetadata.badges.messengerFamilyCount}
                                 color="secondary">
                          <MessengerIcon className={messengerIconStyle}/>
                        </Badge>
                        : <MessengerIcon className={messengerIconStyle}/>
                      }


                    </IconButton>
                  </DropdownToggle>


                  <DropdownMenu right>
                    {this.state.messengerSchoolNotification &&
                    <SchoolMessages close={this.onMessengerSchoolNotificationSelect.bind(this)}
                                    location={this.props.location}/>
                    }


                  </DropdownMenu>
                </Dropdown>
              </li>
              }

              <li className="list-inline-item">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.langSwitcher}
                  toggle={this.onLangSwitcherSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn">
                      <i className={`flag flag-24 flag-${locale.icon}`}/>
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right className="w-50">
                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                      handleRequestClose={this.handleRequestClose}/>
                  </DropdownMenu>
                </Dropdown>


              </li>

              {navigationStyle === HORIZONTAL_NAVIGATION &&
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt='...'
                        src={'https://via.placeholder.com/150x150'}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup/>
                  </DropdownMenu>
                </Dropdown>
              </li>}
            </ul>
            }

          </Toolbar>
        </div>
        <div className="ellipse-shape1"></div>

      </AppBar>
    );
  }

}


const mapStateToProps = ({settings, auth, school}) => {
  const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, locale, navigationStyle, horizontalNavPosition, auth, school}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(HeaderNav));

/*
              {this.props.auth.user.role === 'manager' &&
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}>

                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      {this.props.school.loaded
                        ? <Avatar
                          alt='...'
                          src={this.props.school.schoolProfile.logoUrl}
                          className="size-30"
                        />
                        : <Avatar
                          alt='...'
                          src={'https://via.placeholder.com/150x150'}
                          className="size-30"
                        />
                      }
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup/>
                  </DropdownMenu>
                </Dropdown>
              </li>

              }
 */