import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import School from "./School";
import Classes from "./Classes";
import Student from "./Student";
import Attendance from "./Attendance";
import SchoolCalendar from "./SchoolCalendar";
import SchoolMessenger from "./SchoolMessenger";
import Announcement from "./Announcement";
import SchoolUsers from "./SchoolUsers";
import FeedPosts from "./Posts";

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    school: School,
    classes: Classes,
    student: Student,
    attendance: Attendance,
    schoolCalendar: SchoolCalendar,
    schoolMessenger: SchoolMessenger,
    announcement: Announcement,
    schoolUsers: SchoolUsers,
    posts: FeedPosts
  });
