import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  take,
  cancel,
  fork,
  race

} from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import { rsf } from "config/firebase";
import firebase from "firebase/app";
import 'firebase/database';


import { showAlert } from "redux/actions/Setting";

import {
  AUTH_LOGOUT,
  SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST,
  SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST,
  SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_SUBMIT_MESSAGE,
  SCHOOL_MESSENGER_MARK_MESSAGES_AS_READ,
  SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION,
  SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION
} from "redux/constants/ActionTypes";

import {
  schoolMessengerSyncConversationListSuccess,
  schoolMessengerSyncConversationListFailure,
  schoolMessengerFetchSelectedConversationSuccess,
  schoolMessengerFetchSelectedConversationFailure,
  schoolMessengerAddNewWatcherSelectedConversationSuccess,
  schoolMessengerSelectedConversationFetchMoreSuccess,
  schoolMessengerSelectedConversationFetchMoreFailure,
} from "redux/actions/SchoolMessenger";

import moment from "moment-timezone";

const database = firebase.database();

// REQUESTS


const badgeTransactionRequest = async ({postRef, action, increment}) =>
  await firebase.database().ref(postRef)
    .transaction(function(post) {
      if (post) {

        if(!post.messengerFamilyCount) {
          post.messengerFamilyCount = 0;
        }

        switch(action) {
          case 'add':

            if(increment){
              post.messengerFamilyCount = post.messengerFamilyCount + increment;
            }else{
              post.messengerFamilyCount++;
            }

            break;
          case 'minus':

            if(increment){
              post.messengerFamilyCount = post.messengerFamilyCount - increment;
              if(post.messengerFamilyCount < 0){
                post.messengerFamilyCount = 0;
              }
            }else{
              post.messengerFamilyCount--;
            }
            break;
          default:


        }

      }
      return post;
    })
    .then(result => {
      return result;
    })
    .catch(error => error);

const markMessageAsReadTransactionRequest = async ({postRef, uid}) =>
  await firebase.database().ref(postRef)
    .transaction(function(post) {

      if (post) {
        if (post.readBy && post.readBy[uid]) {
        } else {
          //
          if (!post.readBy) {
            post.readBy = {};
          }
          if (!post.readBy['office']) {
            post.readBy['office'] = moment().utc().valueOf();
          }
          post.readBy[uid] = moment().utc().valueOf();
        }
      }
      return post;
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log('markMessageAsReadTransactionRequest-error',error)
      return error;
    });










// FUNCTIONS
function* markMessagesAsRead({ payload }) {
  let { schoolId, userId, conversationId, lastMessageId, hasItemMessageUpdate, itemMessageUpdateList} = payload;

  const badgesRef = `schoolMetadata/${schoolId}/badges`;
  const lastMessageRef = `messenger/${schoolId}/conversations/${conversationId}/lastMessage`;

  try {


    if(hasItemMessageUpdate){

      let counter = 0;

      yield call(markMessageAsReadTransactionRequest, {
        postRef: lastMessageRef,
        uid: userId
      });

      yield all(Object.keys(itemMessageUpdateList).map(messageId => {

        const itemMessageRef = `messenger/${schoolId}/messages/${conversationId}/${messageId}`;
        counter++;
        return call(markMessageAsReadTransactionRequest, {
          postRef: itemMessageRef,
          uid: userId
        });

      }));


      yield call(badgeTransactionRequest, {
        postRef: badgesRef,
        action: 'minus',
        increment: counter
      });

    }else{


      yield call(markMessageAsReadTransactionRequest, {
        postRef: lastMessageRef,
        uid: userId
      });

      const itemMessageRef = `messenger/${schoolId}/messages/${conversationId}/${lastMessageId}`;


      yield call(markMessageAsReadTransactionRequest, {
        postRef: itemMessageRef,
        uid: userId
      });

      yield call(badgeTransactionRequest, {
        postRef: badgesRef,
        action: 'minus',
        increment: 1
      });

    }

    //yield delay(2000);

  } catch (error) {
    console.log("ERROR - markMessagesAsRead:", error);
  }
}

function* submitMessage({ payload }) {

  //console.log('submitMessage', payload)

  let {
    schoolId,
    userId,
    conversationId,
    newMessage,
    userType,
    userProfile
  } = payload;

  const data = {
    message: newMessage,
    createdAt: moment()
      .utc()
      .valueOf(),
    userType: userType,
    readBy: {},
    createdBy: {
      id: userId,
      nameFurigana: userProfile.nameFurigana,
      nameKanji: userProfile.nameKanji,
      nameRomaji: userProfile.nameRomaji
      /*
       nameFurigana: userProfile.nameFurigana || userProfile.firstNameFurigana,
      nameKanji: userProfile.nameKanji || userProfile.firstNameKanji,
      nameRomaji: userProfile.nameRomaji || userProfile.firstNameRomaji
       */
      //avatar: userProfile.avatarUrl,
    },
    sentByOffice: true,
    //sent: null,
    received: false
    //system: false,
  };

  data.readBy[userId] = moment()
    .utc()
    .valueOf();
  data.readBy["office"] = moment()
    .utc()
    .valueOf();

  try {
    yield call(
      rsf.database.create,
      `/messenger/${schoolId}/messages/${conversationId}`,
      data
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.message_sent_success"
      })
    );
  } catch (error) {
    console.log("ERROR - submitMessage:", error);
  }
}

function* fetchConversationById({ payload }) {
  const { schoolId, conversationId, limit, endAt } = payload;



  /*
  const nodeRef = endAt
    ? firebase
      .database()
      .ref(`messenger/${schoolId}/messages/${conversationId}`)
      //.orderByKey()
      .orderByChild('createdAt')
      .limitToLast(limit) 
      //.endAt(lastVisibleKey)
      .endAt(endAt)
    : firebase
      .database()
      .ref(`messenger/${schoolId}/messages/${conversationId}`)
      //.orderByKey()
      .orderByChild('createdAt')
      .limitToLast(limit)*/

  const nodeRef = firebase
      .database()
      .ref(`messenger/${schoolId}/messages/${conversationId}`)
      //.orderByKey()
      .orderByChild('createdAt')
      .limitToLast(limit)

      
  try {
    const messagesList = yield call(rsf.database.read, nodeRef);

    //yield put(schoolMessengerFetchSelectedConversationSuccess({ endAt, messagesList, conversationFetchedAll: Object.keys(messagesList).length < limit }));
    yield put(schoolMessengerFetchSelectedConversationSuccess({ messagesList, conversationFetchedAll: Object.keys(messagesList).length < limit }));
  } catch (error) {
    yield put(schoolMessengerFetchSelectedConversationFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* fetchMoreConversation({ payload }) {
  const { schoolId, conversationId, endAt } = payload;

  //console.log('Saga fetchMoreConversation', payload)

  const limit = 5;


  /*
  const nodeRef = endAt
    ? firebase
      .database()
      .ref(`messenger/${schoolId}/messages/${conversationId}`)
      //.orderByKey()
      .orderByChild('createdAt')
      .limitToLast(limit)
      //.endAt(lastVisibleKey)
      .endAt(endAt)
    : firebase
      .database()
      .ref(`messenger/${schoolId}/messages/${conversationId}`)
      //.orderByKey()
      .orderByChild('createdAt')
      .limitToLast(limit)*/

  //console.log('endAt', endAt)

  const nodeRef = firebase
    .database()
    .ref(`messenger/${schoolId}/messages/${conversationId}`)
    //.orderByKey()
    .orderByChild('createdAt')
    .endAt(endAt -1)
    .limitToLast(limit)


  try {
    const messagesList = yield call(rsf.database.read, nodeRef);

    yield put(schoolMessengerSelectedConversationFetchMoreSuccess({ endAt, messagesList, conversationFetchedAll: Object.keys(messagesList).length < limit }));
  } catch (error) {
    console.log('fetch more messages error', error)
    yield put(schoolMessengerSelectedConversationFetchMoreFailure({ error }));
    /*
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );*/
  }
}

// SYNCS
function* syncConversationList({ payload }) {
  const { schoolId } = payload;
  const nodeRef = firebase
    .database()
    .ref(`messenger/${schoolId}/conversations`)
    .orderByChild("lastMessage/createdAt")
    //.limitToLast(limit);

  try {
    // Start the sync saga
    let sync = yield fork(rsf.database.sync, nodeRef, {
      successActionCreator: schoolMessengerSyncConversationListSuccess,
      failureActionCreator: schoolMessengerSyncConversationListFailure
    });

    // Wait for the pause action, then stop sync
    yield take(SCHOOL_MESSENGER_UNSYNC_CONVERSATION_LIST);
    yield cancel(sync);

    // Wait for the resume action
    //yield take('RESUME_SYNC')
  } catch (e) {
    console.log(e);
  }
}



// WATCHERS
function* watcherAddNewMessageInConversationChildAdded({payload}) {
  const { schoolId, conversationId } = payload;
//const channel = yield call(rsf.database.channel, nodeRef, 'child_added');
  const nodeRef = firebase
  .database()
  .ref(`messenger/${schoolId}/messages/${conversationId}`)
  .orderByChild('createdAt')
  //.endAt(endAt)
  .limitToLast(1);

  const channel = new eventChannel(emitter => {

    nodeRef.on("child_added", snapshot => {

      let data = {};

      if(snapshot.val()){
        data.key = snapshot.key;
        data.value = snapshot.val();
      }
      emitter(data);

      //console.log('emitter data', data)
    });
    return () => {
      nodeRef.off();
    };
  });

  while(true) {
    const { logoutAction, closeAction, socketAction } = yield race({
      logoutAction: take(AUTH_LOGOUT),
      closeAction: take(SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION),
      socketAction: take(channel)
    });

    if (closeAction) {
      channel.close();
    } else if (logoutAction) {
      channel.close();
    } else {
      yield put(schoolMessengerAddNewWatcherSelectedConversationSuccess(socketAction));
    }
  }
}

function* watcherAddNewMessageInConversationChildUpdated({payload}) {
  const { schoolId, conversationId } = payload;
  /*
  const nodeRef = firebase
    .database()
    .ref(`messenger/${schoolId}/messages/${conversationId}`)
    .orderByChild('createdAt')
    //.limitToLast(5);

  const channel = yield call(rsf.database.channel, nodeRef, 'child_changed');
  */

  const nodeRef = firebase
    .database()
    .ref(`messenger/${schoolId}/messages/${conversationId}`)
    .orderByChild('createdAt')
    //.endAt(endAt)
    .limitToLast(1);

  const channel = new eventChannel(emitter => {

    nodeRef.on("child_changed", snapshot => {

      let data = {};

      if(snapshot.val()){
        data.key = snapshot.key;
        data.value = snapshot.val();
      }
      emitter(data);
    });
    return () => {
      nodeRef.off();
    };
  });

  while(true) {
    const { logoutAction, closeAction, socketAction } = yield race({
      logoutAction: take(AUTH_LOGOUT),
      closeAction: take(SCHOOL_MESSENGER_REMOVE_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION),
      socketAction: take(channel)
    });

    if (closeAction) {
      channel.close();
    } else if (logoutAction) {
      channel.close();
    } else {
      yield put(schoolMessengerAddNewWatcherSelectedConversationSuccess(socketAction));
    }
  }
}

export default function* rootSaga() {
  yield all([

    takeLatest(SCHOOL_MESSENGER_SYNC_CONVERSATION_LIST, syncConversationList),
    takeEvery(SCHOOL_MESSENGER_SUBMIT_MESSAGE, submitMessage),
    takeEvery(SCHOOL_MESSENGER_MARK_MESSAGES_AS_READ, markMessagesAsRead),
    takeEvery(SCHOOL_MESSENGER_FETCH_SELECTED_CONVERSATION, fetchConversationById),
    takeEvery(SCHOOL_MESSENGER_FETCH_MORE_SELECTED_CONVERSATION, fetchMoreConversation),
    takeEvery(SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION, watcherAddNewMessageInConversationChildAdded),
    takeEvery(SCHOOL_MESSENGER_ADD_NEW_MESSAGE_WATCHER_SELECTED_CONVERSATION, watcherAddNewMessageInConversationChildUpdated)
  ]);
}
