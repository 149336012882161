import React from 'react';
import StudentAvatar from 'components/Avatar/StudentAvatar';
import IntlMessages from 'util/IntlMessages';
import { NavLink } from 'react-router-dom';

import MessengerDateTime from 'components/Messenger/MessengerDateTime';

const ConversationListItem = ({ data, locale, authUser }) => {

    const { lastMessage } = data;


    //console.log('data', data)

    let itemReadStatus = 'unread';

    if (data.lastMessage.hasOwnProperty('readBy')) {
        if (data.lastMessage.readBy.hasOwnProperty('office')) {
            if(data.lastMessage.readBy.office){
                itemReadStatus = 'read';
            }
        }
    }

    const studentList = [];

    Object.keys(data.students).map(studentKey => {

        //console.log('studentKey', studentKey)

        if (typeof data.students[studentKey] === 'object') {
            return studentList.push(data.students[studentKey])
        }
        return null;
    });

    let firstName = null;
    //let fullname = null;
    let conversationTitle = null;

    let fromParentType = null;


    if(lastMessage.hasOwnProperty('childRelationship')){

        fromParentType = lastMessage.childRelationship;

    }else{
        if (locale.locale === 'ja') {
            fromParentType = lastMessage.nameFurigana;
        }else{
            fromParentType = lastMessage.nameRomaji;
        }


    }


    if (locale.locale === 'ja') {
        if (lastMessage.firstNameFurigana) {
            firstName = lastMessage.firstNameFurigana;
        } else {
            firstName = lastMessage.firstNameRomaji;
        }

        /*
        if (lastMessage.nameFurgana) {
            fullname = lastMessage.nameFurgana;
        } else {
            fullname = lastMessage.nameRomaji;
        }
        */

        let lastname = null;
        switch (studentList.length) {
            case 0:

                conversationTitle = '-';

                break;
            case 1:
                if (studentList[0].profile.nameFurigana) {
                    conversationTitle = studentList[0].profile.nameFurigana;

                    if(studentList[0].profile.lastNameKanji){
                        conversationTitle = conversationTitle + ' (' + studentList[0].profile.lastNameKanji + ')'
                    }
                } else {
                    conversationTitle = studentList[0].profile.nameRomaji;
                }
                break;
            case 2:



                if (studentList[0].profile.lastNameFurigana) {
                    lastname = studentList[0].profile.lastNameFurigana;

                    conversationTitle = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;

                    if(studentList[0].profile.lastNameKanji){
                        conversationTitle = conversationTitle + ' (' + studentList[0].profile.lastNameKanji + ')'
                    }

                } else {
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                }

                break;
            default:

                if (studentList[0].profile.lastNameFurigana) {
                    lastname = studentList[0].profile.lastNameFurigana;

                    conversationTitle = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;
                } else {
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                    if(studentList[0].profile.lastNameKanji){
                        conversationTitle = conversationTitle + ' (' + studentList[0].profile.lastNameKanji + ')'
                    }
                }
        }



    } else {
        if (lastMessage.firstNameRomaji) {
            firstName = lastMessage.firstNameRomaji;
        } else {
            firstName = lastMessage.firstNameFurigana;
        }
        /*
        if (lastMessage.nameRomaji) {
            fullname = lastMessage.nameRomaji;
        } else {
            fullname = lastMessage.nameFurigana;
        }*/

        let lastname = null;
        switch (studentList.length) {
            case 0:

                conversationTitle = '-';

                break;
            case 1:
                if (studentList[0].profile.nameRomaji) {
                    conversationTitle = studentList[0].profile.nameRomaji;
                } else {
                    conversationTitle = studentList[0].profile.nameFurigana;
                }
                break;
            case 2:

                if (studentList[0].profile.lastNameRomaji) {
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                } else {
                    lastname = studentList[0].profile.lastNameFurigana;
                    conversationTitle = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;

                }
                break;
            default:
                if (studentList[0].profile.lastNameRomaji) {
                    lastname = studentList[0].profile.lastNameRomaji;
                    conversationTitle = studentList[0].profile.firstNameRomaji + ' & ' + studentList[1].profile.firstNameRomaji + ' ' + lastname;

                } else {
                    lastname = studentList[0].profile.lastNameFurigana;
                    conversationTitle = studentList[0].profile.firstNameFurigana + ' & ' + studentList[1].profile.firstNameFurigana + ' ' + lastname;

                }



        }

    }

    //

    //console.log('studentList[0].profile', studentList)



    let pData = {}


    if(studentList[0]){
        if(!studentList[0].hasOwnProperty('profile')){
            console.log('no profile', studentList[0])
        }


        pData = studentList[0].hasOwnProperty('profile') ? studentList[0].profile : {}
    }else{
        console.log('no data for student profile', data)

    }

    //
    const renderSubtitle = () => {

        let from;
        let fromTrans;
        let lMessage;

        if(lastMessage.system){
            fromTrans = "common.system";
            lMessage = <IntlMessages id={lastMessage.message} />;
        } else if(authUser.user.uid === lastMessage.createdBy){
            fromTrans = "common.messengerYou";
            lMessage = lastMessage.message;
        } else {

            const hasChildRelationship = lastMessage.hasOwnProperty('childRelationship');

            if(hasChildRelationship){

                fromTrans = `guardianType.${lastMessage.childRelationship}`;
                lMessage = lastMessage.message;
            } else {

                if (locale.locale === 'ja') {
                    from = lastMessage.nameFurigana;
                }else{
                    from = lastMessage.nameRomaji;
                }
                lMessage = lastMessage.message;
            }

        }

        return (
            <p className="sub-heading mb-0 text-left" style={{ height: 15, overflow: 'hidden', maxWidth:200 }}>
                {fromTrans
                    ? <IntlMessages id={fromTrans} />
                    : from
                }
                : {lMessage}
            </p>)
    }

    return (
        <li className={"conversation-list-item " + itemReadStatus}>

            <NavLink
                exact
                to={"/messenger/" + data.id}
                className={"messenger-list-item " + itemReadStatus}
                activeClassName={'current-conversation active'}
            >
                {studentList.length >= 2
                    ? <div style={{position:'relative', maxWidth:40, width:40, height:40, marginRight:8}}>
                      <div style={{position:'absolute', left:0, maxWidth:40, width:40, height:40, overflow: 'hidden',  backgroundColor: 'transparent'}}>
                          <StudentAvatar data={studentList[1].profile} size={40} classes="mr-2" />
                      </div>
                      <div style={{position:'absolute', left:0, maxWidth:20, width:20, height:40, overflow: 'hidden', backgroundColor: 'transparent', zIndex:100}}>
                                <StudentAvatar data={studentList[0].profile} size={40} classes="mr-2" />
                            </div>


                      </div>

                    : <StudentAvatar data={pData} size={40} classes="mr-2" />
                }

                <div className="media-body d-flex justify-content-between align-self-center">
                    <div className="align-self-center" style={{ maxWidth: '76%' }}>

                        <div className="d-flex justify-content-between">
                            <div className="heading name text-left">{conversationTitle}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="sub-heading">
                                { renderSubtitle() }
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 50, fontSize: 10, marginLeft: 5 }}>
                        <MessengerDateTime locale={locale.locale} messageDate={lastMessage.createdAt} />
                    </div>

                </div>
            </NavLink>
        </li>
    );
};
export default ConversationListItem;
