import React from 'react';
import LanguageItem from './LanguageItem';
import {languageSwitcherList, languageSwitcherDropdownStyle} from 'config/language';
import CustomScrollbars from 'util/CustomScrollbars';

const langListArray = Object.values(languageSwitcherList);


const LanguageSwitcher = ({switchLanguage, handleRequestClose}) => {
    return (
        <CustomScrollbars className="messages-list language-list scrollbar" style={languageSwitcherDropdownStyle}>
            <ul className="list-unstyled">
                {langListArray.map((language, index) =>
                   language.active &&
                   <LanguageItem key={index} language={language} handleRequestClose={handleRequestClose} switchLanguage={switchLanguage}/>
                )}
            </ul>
        </CustomScrollbars>
    )
};

export default LanguageSwitcher;

