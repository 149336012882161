import React from "react";
import { Helmet } from "react-helmet";
import IntlMessages from "util/IntlMessages";
import AlertSystem from "../../../components/AlertSystem";
import ShowConversation from "./ShowConversation";
import LeftSidePanel from "./LeftSidePanel";

class SchoolMessenger extends React.Component {

  //static whyDidYouRender = true;

  componentDidMount() {
    //console.log('Messenger index - ComponentDidMount')
  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {

    if(this.state !== nextState){
      return true;
    }

    if(this.props.conversationListObj !== nextProps.conversationListObj){
      return true;
    }

    if(this.props.studentList !== nextProps.studentList){
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('Messenger index - ComponentDidUpdate')
  }

  componentWillUnmount() {
  }

  render() {


    return (
      <div className="app-wrapper-module">

         <IntlMessages id="messenger.schoolMessenger_browserTitle">
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </IntlMessages>
        <div className="page-header">
          <div className="container-fluid page-header-container">
            <h1>
              <IntlMessages id="messenger.schoolMessenger_pageTitle" />
            </h1>
          </div>
        </div>
        <div className="app-module chat-module animated animation-duration-3 m-0">
          <div className="chat-module-box">

            <LeftSidePanel/>

            <div className="chat-box">

              <ShowConversation/>
            </div>
          </div>
        </div>
        <AlertSystem />
      </div>
    );
  }
}

export default (SchoolMessenger);