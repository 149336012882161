import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import japaneseStrings from 'react-timeago/lib/language-strings/ja';
import chineseStrings from 'react-timeago/lib/language-strings/zh-CN';
import englishStrings from 'react-timeago/lib/language-strings/en';
import moment from 'moment-timezone';


const TimeAgoIntl = props => {

    let timeAgoFormatter = null;
    switch(props.locale) {
        case 'ja':
            timeAgoFormatter = buildFormatter(japaneseStrings);
            break;
        case 'zh':
            timeAgoFormatter = buildFormatter(chineseStrings);
            break;
        case 'en':
            timeAgoFormatter = buildFormatter(englishStrings);
            break;
        default:
            timeAgoFormatter = buildFormatter(englishStrings);
    }

    return (
        <TimeAgo {...props} formatter={timeAgoFormatter} title={moment(props.date).format('lll')}/>
    )
};
export default TimeAgoIntl;