import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  SHOW_ALERT,
  HIDE_ALERT
} from "redux/constants/ActionTypes";

import { initialSettings, rltLocale } from "config/settings";

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case SHOW_ALERT:

      let alertMessageExtra = "";
      if (action.payload.hasOwnProperty("alertMessageExtra")) {
        alertMessageExtra = action.payload.alertMessageExtra;
      }

      return {
        ...state,
        showAlert: true,
        alertType: action.payload.alertType,
        alertMessage: action.payload.alertMessage,
        alertMessageExtra: alertMessageExtra
      };
    case HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
        alertType: "",
        alertMessage: ""
      };

    default:
      return state;
  }
};

export default settings;
