import { all, call, put, takeEvery } from "redux-saga/effects";
import firebase from 'firebase/app';
import {
  POSTS_FETCH_CURRENT_LIST,
  POSTS_CREATE,
  POSTS_UPDATE,
  POSTS_DELETE
} from "redux/constants/ActionTypes";
import {
  postsFetchCurrentListSuccess,
  postsFetchCurrentListFailure ,
  postsCreateSuccess,
  postsCreateFailure,
  postsUpdateSuccess,
  postsUpdateFailure,
  postsDeleteSuccess,
  postsDeleteFailure
} from "../../redux/actions";

import { showAlert } from "../../redux/actions/Setting";
import {getUserIdToken} from "../../util/firebaseHelpers";
import {postApiData2, patchApiData2, deleteApiData2} from "../../util/apiHelpers";

// FUNCTIONS
function* fetchPostsListCurrent({ payload }) {
  const { sid } = payload;

  const nodeRef = firebase.database().ref(`feedPosts/${sid}/posted`).orderByKey().limitToLast(20);

  try {
    const itemData = yield call([nodeRef, nodeRef.once], 'value');

    if(itemData) {
      yield put(postsFetchCurrentListSuccess(itemData.val()));
    } else {
      yield put(postsFetchCurrentListFailure());
    }
  } catch (error) {
    console.log('ERROR: SAGA-fetchPostListCurrent:', error);
    yield put(postsFetchCurrentListFailure());
  }
}


function* createPost({ payload }) {
  let { sid,
    processType,
    type,
    //tokenId,
    //postTo,
    hasFiles,
    formData,
    data
  } = payload;


  try {
    const tokenId = yield call(getUserIdToken);


    const res = yield call(postApiData2, `/school/${sid}/feedPosts/${type}/${processType}`, 'multipart/form-data', tokenId, formData);

    console.log('saga create post', res)

    const successData = {};
    successData[res.data.post.postId] = res.data.post;

    yield put(
      postsCreateSuccess(successData)
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_create_success_with_extra",
        //alertMessageExtra: createdEvent.title
      })
    );

  }
  catch (error) {
    console.log('ERROR: SAGA FeedPosts Create', error)
    yield put(postsCreateFailure({ error }));

    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updatePost({ payload }) {
  let {
    sid,
    //tokenId,
    postId,
    processType,
    type,
    postTo,
    hasFiles,
    formData,
    data
  } = payload;


  try {
    const tokenId = yield call(getUserIdToken);

    //console.log('SAGA-updatePost - payload', payload)

    const res = yield call(patchApiData2, `/school/${sid}/feedPosts/${type}/${processType}/${postId}`, 'multipart/form-data', tokenId, formData);



    if(res.data.hasOwnProperty('images')){
      Object.assign(data, { images: res.data.images });
    }

    const successData = {};
    successData[postId] = data;

    yield put(
      postsUpdateSuccess(data)
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.event_create_success_with_extra",
        //alertMessageExtra: createdEvent.title
      })
    );

  } catch (error) {
    yield put(postsUpdateFailure({ error }));

    console.log('SAGA- postsUpdateFailure', error)
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }

}

function* deletePost({ payload }) {

  const {
    sid,
    //tokenId,
    postId,
    processType,
    type
  } = payload;


  try {

    const tokenId = yield call(getUserIdToken);

    yield call(deleteApiData2, `/school/${sid}/feedPosts/${type}/${processType}/${postId}`, tokenId);

    yield put(
      postsDeleteSuccess(postId)
    );


  }
  catch (error) {
    yield put(postsDeleteFailure({ error }));

    console.log('SAGA- postsDeleteFailure', error)
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }

}


export default function* rootSaga() {
  yield all([
    takeEvery(POSTS_FETCH_CURRENT_LIST, fetchPostsListCurrent),
    takeEvery(POSTS_CREATE, createPost),
    takeEvery(POSTS_UPDATE, updatePost),
    takeEvery(POSTS_DELETE, deletePost)
  ]);
}
