import React from 'react';
import {connect} from 'react-redux';

import { compose } from 'redux';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

import ListItem from './ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';

import {routesManager} from "config/routes";
import { sortBy } from "lodash";

import {
  schoolMessengerSyncConversationList,
  schoolMessengerUnSyncConversationList,
  schoolMessengerOnScrollConversationList,
} from 'redux/actions/SchoolMessenger'

import {Scrollbars} from 'react-custom-scrollbars';


class SchoolMessages extends React.Component {

  constructor() {
    super();
    this.scrollbar = React.createRef();
    this.state = {
      scrollPosition:0
    }
  }

  // Sync data
  componentDidMount() {

    //const limit = this.props.schoolMessenger.conversationListScrollCount;

    // Sync Messenger List only if not on messenger page.
    const currentUrlArray = this.props.location.pathname.split("/");

    switch(currentUrlArray[1]){
      case 'messenger':
        // do nothing
        break;
      case 'dashboard':
        // do nothing
        break;
      default:
        if( !this.props.conversationListSynced && !this.props.conversationListSyncedLoading){
          this.props.schoolMessengerSyncConversationList({schoolId: this.props.auth.user.school, limit:10})
        }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    // Update Sync only if not on Messenger or Dashboard Page.
    const currentUrlArray = this.props.location.pathname.split("/");

    switch(currentUrlArray[1]) {
      case 'messenger':
        // do nothing
        break;
      case 'dashboard':
        // do nothing
        break;
      default:
        // #TODO pagination
        // if (prevProps.conversationListScrollCount !== this.props.conversationListScrollCount) {
        //   this.props.schoolMessengerSyncConversationList({
        //     schoolId: this.props.auth.user.school,
        //     limit: this.props.conversationListScrollCount
        //   })
        // }
    }

    if(prevProps.conversationList.length !== this.props.conversationList.length){
      //console.log('length>>',this.props.conversationList.length)
      //console.log('position>>',this.state.scrollPosition)
      this.scrollbar.current.scrollTop(this.state.scrollPosition)
    }

    if(this.state.scrollPosition !== prevState.scrollPosition){
      //console.log('updating???', this.state.scrollPosition)
    }

  }

  componentWillUnmount() {
    // Unsync conversation list if not on messenger or dashboard page.
    const currentUrlArray = this.props.location.pathname.split("/");

    switch(currentUrlArray[1]){
      case 'messenger':
      // do nothing
      break;
      case 'dashboard':
      // do nothing
      break;
      default:
        // this.props.schoolMessengerUnSyncConversationList();
    }

  }

  onSelectMessengerConversation = () => {
    this.props.close();
  };

  render() {

    const {auth, locale, conversationList, loading, schoolMessagesNewCount} = this.props;


    console.log('header-nav - messengerCount', schoolMessagesNewCount)

    return (
      <div>

        <div className={`messenger-header jr-card-header d-flex align-items-center`}>
          <div className="mr-auto">
            <h4 className="card-heading"><IntlMessages id="common.messages"/></h4>


            {false && <p className="sub-heading">subtitle</p>}
          </div>

          <MailIcon/>


          {
            /*
                  <IconButton className="size-30" onClick={this.onOptionMenuSelect.bind(this)}>
            <i className="zmdi zmdi-chevron-down"/>
          </IconButton>
          <AnnouncementMenu menuState={menuState} anchorEl={anchorEl}
                            handleRequestClose={this.handleRequestClose.bind(this)}/>
             */
          }

        </div>


        <Scrollbars
          autoHide
          renderTrackHorizontal={props => <div style={{display: 'none'}} className="track-horizontal"/>}
          style={{height: 280}}

          className="messages-list button-list scrollbar"
          ref={this.scrollbar}
          onUpdate={(values) => {
            const { scrollTop, top } = values;
            if(top === 1){
              this.props.schoolMessengerOnScrollConversationList(this.props.conversationListScrollCount + 5);
              this.setState({
                scrollPosition: scrollTop
              });
            }

          }}
        >

          {loading
            ? <div className="d-flex align-items-center h-100 justify-content-center">
              <CircularProgress size={20}/>
            </div>
            : conversationList.length === 0 ?
              <div className="d-flex align-items-center h-100 justify-content-center">
                <IntlMessages id="common.noMessages"/>
              </div>
              :
              <ul className="list-unstyled">
                {conversationList.map((conversationItem, index) =>
                  <ListItem key={index} data={conversationItem} locale={locale} authUser={auth} onSelectMessengerConversation={this.onSelectMessengerConversation}/>
                )}
              </ul>
          }

        </Scrollbars>

        <div className={`messenger-footer jr-card-action jr-card-footer d-flex justify-content-center align-items-center`}>
          <Link to={routesManager.messenger}><IntlMessages id="common.viewAll"/></Link>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({settings, auth, school, schoolMessenger, student, classes}) => {

  const { locale } = settings;
  const { listScrollCount: conversationListScrollCount, listLoading: conversationListLoading, listSynced: conversationListSynced} = schoolMessenger;
  const studentListLoading = student.studentListActiveLoading;
  const { studentListActiveSynced } = student;
  const { classListActiveSynced } = classes;
  const {schoolMetadata} = school;
  const schoolMessagesNewCount = schoolMetadata.badges.messengerFamilyCount;
  let dataLoaded = false;

  // Combine conversationList data.
  let conversationList = [];
  let conversationListObj = {};
  if (schoolMessenger.listSynced && student.studentListActiveSynced && classes.classListActiveSynced) {
    const basicConversationListObj = schoolMessenger.list;
    const studentListObj = student.studentListActive;
    const classesListObj = classes.classListActive;

    if (basicConversationListObj && studentListObj && classesListObj) {
      conversationList = Object.keys(basicConversationListObj).map(conversationKey => {
        let v = basicConversationListObj[conversationKey];

        if (v.students) {
          Object.keys(v.students).map(studentKey => {
            let studentData = {};

            if (!studentListObj.hasOwnProperty(studentKey)) {
              return null;
            } else {
              studentData.id = studentKey;
              studentData.profile = studentListObj[studentKey].profile;
              studentData.classes = {};

              if (studentListObj[studentKey].metadata.classes) {
                Object.keys(studentListObj[studentKey].metadata.classes).map(classKey => {
                  if (!classesListObj.hasOwnProperty(classKey)) {
                    return null;
                  }
                  let classObj = {};
                  classObj.id = classesListObj[classKey].metadata.id;
                  classObj.profile = classesListObj[classKey].profile;

                  return (studentData.classes[classKey] = classObj);
                });
              }
            }
            return (v.students[studentKey] = studentData);
          });
        }

        conversationListObj[conversationKey] = v;
        return v;
      });
      dataLoaded = true;
    }
  }

  // Combine studentList data.
  let studentList = [];
  if (student.studentListActiveSynced && classes.classListActiveSynced) {
    const studentListObj = student.studentListActive;
    const classesListObj = classes.classListActive;

    const sortArray = ["profile.lastNameRomaji"];

    if (studentListObj && classesListObj) {
      studentList = sortBy(
        Object.keys(studentListObj).map(key => {
          let v = studentListObj[key];
          v.classes = {};

          if (v.metadata.classes) {
            Object.keys(v.metadata.classes).map(classKey => {
              if (!classesListObj.hasOwnProperty(classKey)) {
                return null;
              }
              return (v.classes[classKey] = classesListObj[classKey]);
            });
          }

          return v;
        }),
        sortArray
      );
    }
  }

  conversationList.sort((a, b) => (a.lastMessage.createdAt < b.lastMessage.createdAt) ? 1 : -1);

  return {
    settings,
    auth,
    locale,
    conversationListLoading,
    conversationListSynced,
    conversationList,
    conversationListScrollCount,
    studentListLoading,
    studentList,
    dataLoaded,
    conversationListObj,
    studentListActiveSynced,
    classListActiveSynced,
    schoolMessagesListSynced: schoolMessenger.listSynced,
    schoolMessagesNewCount
  };
};

export default compose(
    connect(
        mapStateToProps, {
        schoolMessengerSyncConversationList,
        schoolMessengerUnSyncConversationList,
        schoolMessengerOnScrollConversationList,
        }
    ),

)(SchoolMessages)