import React from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { routesDefault } from "config/routes";
import { switchLanguage } from "redux/actions/Setting";
import brandLogo from "assets/images/brandlogo-header-green.svg";
import backgroundPrimary from "assets/images/backgrounds/forest-bg.svg";
import backgroundSecondary from "assets/images/backgrounds/raccoon-bg.svg";
import LanguageSwitcher from "components/LanguageSwitcher";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { showAuthLoader, authRequestPasswordReset, authClearError } from "redux/actions/Auth";
import AlertSystem from "components/AlertSystem";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      langSwitcher: false
    };
  }

  componentDidMount() {
    this.props.authClearError();
  }

  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };

  handleLangRequestClose = () => {
    this.setState({
      langSwitcher: false
    });
  };

  resetPassword = e => {
    e.preventDefault();

    this.props.showAuthLoader();
    this.props.authRequestPasswordReset({ email: this.state.email, locale: this.props.locale });
  };

  render() {
    const { email } = this.state;
    const { loading, locale, error } = this.props;

    const backgroundPrimaryStyle = {
      backgroundImage: `url(${backgroundPrimary})`,
      backgroundSize: "cover",
      display: "flex",
      width: "100%",
      height: "100%"
    };
    const backgroundSecondaryStyle = {
      backgroundImage: `url(${backgroundSecondary})`,
      backgroundSize: "cover",
      display: "flex",
      width: "100%",
      height: "100%"
    };

    if (this.props.auth.loaded) {
      return <Redirect to={"/"} />;
    }

    return (
      <div className="backgroundWrapperPrimary" style={backgroundPrimaryStyle}>
        <div className="backgroundWrapperSecondary" style={backgroundSecondaryStyle}>
          <div className="app-login-wrapper d-flex flex-column justify-content-center align-items-center w-100">
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
              <div className="app-login-main-content">
                <IntlMessages id="auth.forgotPassword_browserTitle">
                  {title => (
                    <Helmet>
                      <title>{title}</title>
                    </Helmet>
                  )}
                </IntlMessages>

                <div className="app-logo-content d-flex align-items-center justify-content-center">
                  <Link className="logo-lg" to="/">
                    <img src={brandLogo} alt={<IntlMessages id="app.name" />} style={{ height: 50 }} />
                  </Link>
                </div>

                <div className="app-login-content">
                  <div className="app-login-header mb-1">
                    <h1>
                      <IntlMessages id="auth.forgotPassword_pageTitle" />
                    </h1>
                  </div>
                  <p>
                    <IntlMessages id="auth.forgotPassword_pageDescription" />
                  </p>

                  <div className="app-login-form">
                    <form onSubmit={this.resetPassword}>
                      <fieldset>
                        <TextField
                          label={<IntlMessages id="common.usernameEmail" />}
                          fullWidth
                          onChange={event => this.setState({ email: event.target.value })}
                          defaultValue={email}
                          margin="normal"
                          className="mt-1 my-sm-3 mb-3"
                        />

                        {loading && (
                          <div className="loading-view">
                            <CircularProgress />
                          </div>
                        )}

                        {error && (
                          <div className="mb-2" style={{ color: "red" }}>
                            <IntlMessages id={`errorMsg.${error}`} />
                          </div>
                        )}

                        <div className="mb-3 d-flex align-items-center justify-content-between">
                          <Button type="submit" variant="contained" color="primary" className="text-white">
                            <IntlMessages id="button.resetPassword" />
                          </Button>

                          <Link to={routesDefault.login}>
                            <IntlMessages id="common.login" />
                          </Link>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>

              <AlertSystem />
            </div>

            <div className="app-login-container animated slideInUpTiny animation-duration-3 mt-2 pr-3">
              <Dropdown className="float-right" isOpen={this.state.langSwitcher} toggle={this.onLangSwitcherSelect.bind(this)}>
                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                  <div className="d-flex align-items-center pointer">
                    <i className={`flag flag-24 flag-${locale.icon}`} />
                    <h4 className="mb-0 ml-1 d-none d-md-block">{locale.name}</h4>
                  </div>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={this.props.switchLanguage} handleRequestClose={this.handleLangRequestClose} />
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { loading, error } = auth;
  return { loading, auth, locale, error };
};

export default connect(
  mapStateToProps,
  {
    switchLanguage,
    authRequestPasswordReset,
    showAuthLoader,
    authClearError
  }
)(ForgotPassword);