import React from 'react';
import {connect} from 'react-redux'
import {logout} from 'redux/actions/Auth';
//import IntlMessages from 'util/IntlMessages';

class UserInfoPopup extends React.Component {
  render() {
    return (
      <div>
        <div className="user-profile">
          <img className="user-avatar border-0 size-40 rounded-circle"
               src="https://via.placeholder.com/150x150"
               alt="User"/>
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">Grow School</h4>
            <small>School Branch</small>
          </div>
        </div>
        <div className="user-profile">
          <img className="user-avatar border-0 size-40 rounded-circle"
               src="https://via.placeholder.com/150x150"
               alt="User"/>
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">Chris Harris</h4>
            <small>Manager</small>
          </div>
        </div>

      </div>
    );
  }
}

export default connect(null, {logout})(UserInfoPopup);


