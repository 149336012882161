import {
  POSTS_DIALOG_ADD_OPEN,
  POSTS_DIALOG_ADD_CLOSE,

  POSTS_DIALOG_EDIT_OPEN,
  POSTS_DIALOG_EDIT_CLOSE,

  POSTS_FETCH_CURRENT_LIST,
  POSTS_FETCH_CURRENT_LIST_SUCCESS,
  POSTS_FETCH_CURRENT_LIST_FAILURE,

  POSTS_CREATE,
  POSTS_CREATE_SUCCESS,
  //POSTS_CREATE_FAILURE,
  POSTS_UPDATE,
  POSTS_UPDATE_SUCCESS,

  POSTS_DELETE,
  POSTS_DELETE_SUCCESS,
  POSTS_DELETE_FAILURE
} from "redux/constants/ActionTypes";

const initialSettings = {
  current: {
    loading: false,
    loaded: false,
    data: null
  },
  draft: {
    loading: false,
    loaded: false,
    data: null
  },
  dialogEdit: {
    loader: false,
    open: false
  },
  dialogAdd: {
    loader: false,
    open: false
  }
};

const SchoolUsers = (state = initialSettings, { type, payload }) => {
  switch (type) {

    case POSTS_DIALOG_ADD_OPEN: {
      return {
        ...state,
        dialogAdd: {
          loader: false,
          open: true
        }
      };
    }

    case POSTS_DIALOG_ADD_CLOSE: {
      return {
        ...state,
        dialogAdd: {
          loader: false,
          open: false
        }
      };
    }

    case POSTS_DIALOG_EDIT_OPEN: {

      return {
        ...state,
        dialogEdit: {
          loader: false,
          open: true
        }
      };
    }

    case POSTS_DIALOG_EDIT_CLOSE: {

      return {
        ...state,
        dialogEdit: {
          loader: false,
          open: false
        }
      };
    }

    case POSTS_FETCH_CURRENT_LIST: {
      return {
        ...state,
        current: {
          loading: true,
          loaded: false
        },
      };
    }

    case POSTS_FETCH_CURRENT_LIST_SUCCESS: {
      return {
        ...state,
        current: {
          loading: false,
          loaded: true,
          data: payload
        },
      };
    }

    case POSTS_FETCH_CURRENT_LIST_FAILURE: {
      return {
        ...state,
        current: {
          loading: false,
          loaded: false
        }
      };
    }

    case POSTS_DELETE: {
      return {
        ...state,
        dialogEdit: {
          ...state.dialogEdit,
          loader: true,
        }
      }
    }

    case POSTS_DELETE_SUCCESS: {

      const data = state.current.data;

      delete data[payload];

      return {
        ...state,
        current: {
          ...state.current,
          data
        },
        dialogEdit: {
          open: false,
          loader: false
        }
      }
    }

    case POSTS_DELETE_FAILURE: {
      return {
        ...state,
        dialogEdit: {
          open: false,
          loader: false
        }
      }
    }

    case POSTS_CREATE: {
      return {
        ...state,
        dialogAdd: {
          ...state.dialogAdd,
          loader: true,
        }
      }
    }
    case POSTS_CREATE_SUCCESS: {


      const data = Object.assign(payload, state.current.data);

      return {
        ...state,
        current: {
          ...state.current,
          data
        },
        dialogAdd: {
          loader: false,
          open: false
        }
      };
    }

    case POSTS_UPDATE: {
      return {
        ...state,
        dialogEdit: {
          ...state.dialogEdit,
          loader: true
        }
      }
    }
    case POSTS_UPDATE_SUCCESS: {

      const data = state.current.data;
      data[payload.postId] = payload;

      return {
        ...state,
        current: {
          ...state.current,
          data
        },
        dialogEdit: {
          loader: false,
          open: false
        }
      };
    }

    default:
      return state;
  }
};

export default SchoolUsers;
