import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SYNC_USER_METADATA,
  AUTH_SYNC_USER_METADATA_SUCCESS,
  AUTH_SYNC_USER_METADATA_FAILURE,
  AUTH_FETCH_USER_PROFILE,
  AUTH_FETCH_USER_PROFILE_SUCCESS,
  AUTH_FETCH_USER_PROFILE_FAILURE,
  AUTH_UPDATE_USER_PROFILE,
  AUTH_UPDATE_USER_PROFILE_SUCCESS,
  AUTH_UPDATE_USER_PROFILE_FAILURE,
  AUTH_FETCH_USER_SETTINGS,
  AUTH_FETCH_USER_SETTINGS_SUCCESS,
  AUTH_FETCH_USER_SETTINGS_FAILURE,
  AUTH_UPDATE_USER_SETTINGS,
  AUTH_UPDATE_USER_SETTINGS_SUCCESS,
  AUTH_UPDATE_USER_SETTINGS_FAILURE,
  AUTH_REAUTH_USER,
  AUTH_REAUTH_USER_SUCCESS,
  AUTH_REAUTH_USER_FAILURE,
  AUTH_REAUTH_USER_REQUIRE,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILURE,
  AUTH_REQUEST_PASSWORD_RESET,
  AUTH_REQUEST_PASSWORD_RESET_SUCCESS,
  AUTH_REQUEST_PASSWORD_RESET_FAILURE,
  AUTH_CLEAR_ERROR
} from "redux/constants/ActionTypes";

/*
const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: localStorage.getItem('user_id'),
};
*/

const INIT_STATE = {
  //showLoader: false,

  initURL: "",

  //status: undefined,
  //watcherLoading: false,
  //watcherLoaded: false,

  loading: false,
  loaded: false,
  alertType: "",
  alertMessage: "",
  showAlert: false,
  user: null,
  userProfile: null,
  userSettings: null,
  userMetadata: null,
  signup: null,
  /*
    signup: {
        processing: false,
        providerId: '',
        inviteCode: '',
        email: '',
        password: '',
        profile: ''
    }*/

  reAuthDone: false,
  reAuthLoading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loading: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loading: false
      };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        loaded: false,
        loading: false,
        user: null,
        userProfile: INIT_STATE.userProfile,
        userSettings: INIT_STATE.userSettings,
        userMetadata: INIT_STATE.userMetadata
      };
    }

    case AUTH_LOGIN: {
      return {
        ...state,
        loading: true,
        error: ""
      };
    }

    case AUTH_LOGIN_SUCCESS: {

      return {
        ...state,
        loading: true,
        loaded: false,
        user: action.payload
      };
    }

    case AUTH_LOGIN_FAILURE: {

      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case AUTH_SYNC_USER_METADATA: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_SYNC_USER_METADATA_SUCCESS: {
      //console.log('REDUCER - USER_FETCH_ALL_SUCCESS', action);

      let loading = true;
      let loaded = false;

      if (state.user && state.userProfile && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userMetadata: action.payload
      };
    }

    case AUTH_SYNC_USER_METADATA_FAILURE: {
      //console.log('REDUCER - USER_FETCH_ALL_SUCCESS', action);
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_FETCH_USER_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_FETCH_USER_PROFILE_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userProfile: action.payload
      };
    }

    case AUTH_FETCH_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_UPDATE_USER_PROFILE: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_UPDATE_USER_PROFILE_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userSettings) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userProfile: action.payload
      };
    }

    case AUTH_UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_FETCH_USER_SETTINGS: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_FETCH_USER_SETTINGS_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userProfile) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userSettings: action.payload
      };
    }

    case AUTH_FETCH_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_UPDATE_USER_SETTINGS: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_UPDATE_USER_SETTINGS_SUCCESS: {
      let loading = true;
      let loaded = false;

      if (state.user && state.userMetadata && state.userProfile) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        userSettings: action.payload
      };
    }

    case AUTH_UPDATE_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case AUTH_REAUTH_USER: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: true,
        reAuthError: ""
      };
    }
    case AUTH_REAUTH_USER_SUCCESS: {
      return {
        ...state,
        reAuthDone: true,
        reAuthLoading: false
      };
    }
    case AUTH_REAUTH_USER_FAILURE: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: action.payload
      };
    }

    case AUTH_REAUTH_USER_REQUIRE: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: ""
      };
    }

    case AUTH_CHANGE_PASSWORD: {
      return {
        ...state,
        reAuthDone: false,
        reAuthLoading: false,
        reAuthError: ""
      };
    }

    case AUTH_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state
      };
    }

    case AUTH_CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET: {
      return {
        ...state,
        loading: true
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: ""
      };
    }

    case AUTH_REQUEST_PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case AUTH_CLEAR_ERROR: {
      return {
        ...state,
        loading: false,
        error: ""
      };
    }

    default:
      return state;
  }
};
