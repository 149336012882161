import {
  SCHOOL_FETCH_SCHOOL_PROFILE,
  SCHOOL_FETCH_SCHOOL_PROFILE_SUCCESS,
  SCHOOL_FETCH_SCHOOL_PROFILE_FAILURE,
  SCHOOL_UPDATE_SCHOOL_PROFILE,
  SCHOOL_UPDATE_SCHOOL_PROFILE_SUCCESS,
  SCHOOL_UPDATE_SCHOOL_PROFILE_FAILURE,
  SCHOOL_FETCH_SCHOOL_SETTINGS,
  SCHOOL_FETCH_SCHOOL_SETTINGS_SUCCESS,
  SCHOOL_FETCH_SCHOOL_SETTINGS_FAILURE,
  SCHOOL_SYNC_SCHOOL_METADATA,
  SCHOOL_SYNC_SCHOOL_METADATA_SUCCESS,
  SCHOOL_SYNC_SCHOOL_METADATA_FAILURE,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
  SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_SUCCESS,
  SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_FAILURE,
  SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_SUCCESS,
  SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_FAILURE,
  SCHOOL_ROOM_FETCH_ROOM_LIST,
  SCHOOL_ROOM_FETCH_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_FETCH_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_ADD_ROOM,
  SCHOOL_ROOM_ADD_ROOM_SUCCESS,
  SCHOOL_ROOM_ADD_ROOM_FAILURE,
  SCHOOL_ROOM_UPDATE_ROOM,
  SCHOOL_ROOM_UPDATE_ROOM_SUCCESS,
  SCHOOL_ROOM_UPDATE_ROOM_FAILURE,
  SCHOOL_ROOM_DELETE_ROOM_LIST,
  SCHOOL_ROOM_DELETE_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_DELETE_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_SYNC_ROOM_LIST,
  SCHOOL_ROOM_SYNC_ROOM_LIST_SUCCESS,
  SCHOOL_ROOM_SYNC_ROOM_LIST_FAILURE,
  SCHOOL_ROOM_UNSYNC_ROOM_LIST,
    SCHOOL_LISTENER_ON_SCHOOL_USERS,
    SCHOOL_LISTENER_EVENT_SCHOOL_USERS,
    SCHOOL_LISTENER_OFF_SCHOOL_USERS,
    SCHOOL_FETCH_SCHOOL_USERS,
    SCHOOL_FETCH_SCHOOL_USERS_SUCCESS,
    SCHOOL_FETCH_SCHOOL_USERS_FAILURE,
} from "redux/constants/ActionTypes";

const initialSettings = {
  loading: false,
  loaded: false,
  metadataSyncing: false,
  metadataSynced: false,
  sid: undefined,
  schoolProfile: {
    "address": {
      "en": {
        "addressCity": "",
        "addressLine1": "",
        "addressLine2": "",
        "addressPostCode": "",
        "addressPrefecture": ""
      },
      "ja": {
        "addressCity": "",
        "addressLine1": "",
        "addressLine2": "",
        "addressPostCode": "",
        "addressPrefecture": ""
      }
    },
    "blog": "",
    "branchNameEn": "",
    "branchNameJa": "",
    "email": "",
    "id": "",
    "map": {
      "latitude": 0,
      "latitudeDelta": 0,
      "longitude": 0,
      "longitudeDelta": 0
    },
    "nameEn": "",
    "nameJa": "",
    "openDays": {
      "friday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "monday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "saturday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "sunday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "thursday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "tuesday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      },
      "wednesday": {
        "closeTime": "",
        "open": false,
        "openTime": ""
      }
    },
    "phoneFax": "",
    "phoneOffice": "",
    "schoolColor": "#000000",
    "socialMedia": {
      "facebook": 0,
      "instagram": "",
      "twitter": "",
      "youtube": ""
    },
    "website": ""
  },
  schoolSettings: null,
  schoolUsers: {
    loading: false,
    loaded: false,
    data: undefined,
  },
  error: "",
  alertType: "",
  alertMessage: "",
  showAlert: false,

  schoolAcademicYearList: null,
  schoolAcademicYearListLoading: false,
  schoolAcademicYearListLoaded: false,
  schoolAcademicYearListSynced: false,

  currentAddedSchoolAcademicYear: null,
  currentAddedSchoolAcademicYearId: null,
  schoolAcademicYearLoading: false,

  schoolRoomListLoading: false,
  schoolRoomListLoaded: false,
  schoolRoomListSynced: false,
  schoolRoomList: null,
  schoolRoomLoading: false
};

const School = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case SCHOOL_FETCH_SCHOOL_USERS: {
      return {
        ...state,
        schoolUsers: {
          loading: true,
          loaded: false,
          data: [],
        }
      };
    }

    case SCHOOL_FETCH_SCHOOL_USERS_SUCCESS: {
      return {
        ...state,
        schoolUsers: {
          loading: false,
          loaded: true,
          data: payload,
        }
      };
    }

    case SCHOOL_FETCH_SCHOOL_USERS_FAILURE: {
      return {
        ...state,
        schoolUsers: {
          loading: false,
          loaded: false,
          data: [],
        }
      };
    }

    case SCHOOL_LISTENER_ON_SCHOOL_USERS: {
      return {
        ...state,
        schoolUsers: {
          ...state,
        }
      };
    }

    case SCHOOL_LISTENER_OFF_SCHOOL_USERS: {
      return {
        ...state,
        schoolUsers: {
          ...state,
          loaded: false,
          data: []
        }
      };
    }

    case SCHOOL_LISTENER_EVENT_SCHOOL_USERS: {
      if (payload.eventType === 'init'){
        return {
          ...state,
          schoolUsers: {
            ...state,
            loading: false,
            loaded: true,
            data: payload.value,
          }
        };
      } else if (payload.eventType === 'child_removed'){
        const data = {
          ...state.schoolUsers.data,
        }
        delete data[payload.key];
        return {
          ...state,
          schoolUsers: {
            ...state,
            data,
          }
        }
      }

      const data = {
        ...state.schoolUsers.data,
        [payload.key]: {
          uid: payload.key,
          disabled: payload.value.hasOwnProperty('disabled') ? payload.disabled : false,
          fid: payload.value.hasOwnProperty('fid') ? payload.value.fid : undefined,
          name: payload.value.name,
          avatar: payload.value.hasOwnProperty('photoUrl') ? { url: {avatar: payload.value.photoUrl}} : undefined,
          type: payload.value.hasOwnProperty('type') ? payload.value.type : undefined,
          students: payload.value.hasOwnProperty('students') ? Object.keys(payload.value.students) : [],
          appVersion: payload.value.hasOwnProperty('presence') ? payload.value.presence.version : undefined,
          lastLogin:  payload.value.hasOwnProperty('presence') ? payload.value.presence.lastOnline : undefined,
          status: payload.value.hasOwnProperty('presence') ? payload.value.presence.status : undefined,
        }
      }
      return {
        ...state,
        schoolUsers: {
          ...state,
          data,
        }
      }

    }

    case SCHOOL_FETCH_SCHOOL_PROFILE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        sid: payload.school,
      };
    }

    case SCHOOL_FETCH_SCHOOL_PROFILE_SUCCESS: {
      let loading = true;
      let loaded = false;
      if (state.userSettings !== null) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        sid: payload.school,
        schoolProfile: payload.schoolProfile
      };
    }

    case SCHOOL_FETCH_SCHOOL_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case SCHOOL_UPDATE_SCHOOL_PROFILE: {
      return {
        ...state,
        updating: true
      };
    }

    case SCHOOL_UPDATE_SCHOOL_PROFILE_SUCCESS: {
      return {
        ...state,
        updating: false,
        schoolProfile: payload.schoolProfile
      };
    }

    case SCHOOL_UPDATE_SCHOOL_PROFILE_FAILURE: {
      return {
        ...state,
        updating: false,
        error: payload.error
      };
    }

    case SCHOOL_FETCH_SCHOOL_SETTINGS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case SCHOOL_FETCH_SCHOOL_SETTINGS_SUCCESS: {
      let loading = true;
      let loaded = false;
      if (state.schoolProfile !== null) {
        loading = false;
        loaded = true;
      }

      return {
        ...state,
        loading: loading,
        loaded: loaded,
        schoolSettings: payload.schoolSettings
      };
    }

    case SCHOOL_FETCH_SCHOOL_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case SCHOOL_SYNC_SCHOOL_METADATA: {
      return {
        ...state,
        metadataSyncing: true,
        metadataSynced: false
      };
    }

    case SCHOOL_SYNC_SCHOOL_METADATA_SUCCESS: {
      return {
        ...state,
        metadataSyncing: false,
        metadataSynced: true,
        schoolMetadata: payload
      };
    }

    case SCHOOL_SYNC_SCHOOL_METADATA_FAILURE: {
      return {
        ...state,
        metadataSyncing: false,
        metadataSynced: false,
        error: payload.error
      };
    }

    case SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST: {
      return {
        ...state,
        schoolAcademicYearListLoading: true,
        schoolAcademicYearListLoaded: false
      };
    }

    case SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_SUCCESS: {
      return {
        ...state,
        schoolAcademicYearListLoading: false,
        schoolAcademicYearListLoaded: true,
        schoolAcademicYearList: payload
      };
    }

    case SCHOOL_ACADEMICYEAR_FETCH_SCHOOL_ACADEMICYEAR_LIST_FAILURE: {
      return {
        ...state,
        schoolAcademicYearListLoading: false,
        schoolAcademicYearListLoaded: false
      };
    }

    case SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST: {
      return {
        ...state,
        schoolAcademicYearListLoading: true,
        schoolAcademicYearListSynced: false
      };
    }

    case SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_SUCCESS: {
      return {
        ...state,
        schoolAcademicYearListLoading: false,
        schoolAcademicYearListSynced: true,
        schoolAcademicYearList: payload
      };
    }

    case SCHOOL_ACADEMICYEAR_SYNC_SCHOOL_ACADEMICYEAR_LIST_FAILURE: {
      return {
        ...state,
        schoolAcademicYearListLoading: false,
        schoolAcademicYearListSynced: false,
        schoolAcademicYearList: null
      };
    }

    case SCHOOL_ACADEMICYEAR_UNSYNC_SCHOOL_ACADEMICYEAR_LIST: {
      return {
        ...state,
        schoolAcademicYearListSynced: false
      };
    }

    case SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR: {
      return {
        ...state,
        schoolAcademicYearLoading: true,
        currentAddedSchoolAcademicYear: null,
      };
    }

    case SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_SUCCESS: {
      return {
        ...state,
        schoolAcademicYearLoading: false,
        currentAddedSchoolAcademicYear: payload.schoolAcademicYear,
        currentAddedSchoolAcademicYearId: payload.schoolAcademicYearId
      };
    }

    case SCHOOL_ACADEMICYEAR_ADD_SCHOOL_ACADEMICYEAR_FAILURE: {
      return {
        ...state,
        schoolAcademicYearLoading: false
      };
    }

    case SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR: {
      return {
        ...state,
        schoolAcademicYearLoading: true
      };
    }

    case SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_SUCCESS: {
      return {
        ...state,
        schoolAcademicYearLoading: false
      };
    }

    case SCHOOL_ACADEMICYEAR_UPDATE_SCHOOL_ACADEMICYEAR_FAILURE: {
      return {
        ...state,
        schoolAcademicYearLoading: false
      };
    }

    case SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR: {
      return {
        ...state,
        schoolAcademicYearLoading: true
      };
    }

    case SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_SUCCESS: {
      return {
        ...state,
        schoolAcademicYearLoading: false
      };
    }

    case SCHOOL_ACADEMICYEAR_DELETE_SCHOOL_ACADEMICYEAR_FAILURE: {
      return {
        ...state,
        schoolAcademicYearLoading: false
      };
    }

    case SCHOOL_ROOM_FETCH_ROOM_LIST: {
      return {
        ...state,
        schoolRoomListLoading: true,
        schoolRoomListLoaded: false
      };
    }

    case SCHOOL_ROOM_FETCH_ROOM_LIST_SUCCESS: {
      return {
        ...state,
        schoolRoomListLoading: false,
        schoolRoomListLoaded: true,
        schoolRoomList: payload
      };
    }

    case SCHOOL_ROOM_FETCH_ROOM_LIST_FAILURE: {
      return {
        ...state,
        schoolRoomListLoading: false,
        schoolRoomListLoaded: false,
        schoolRoomList: null
      };
    }

    case SCHOOL_ROOM_ADD_ROOM: {
      return {
        ...state,
        schoolRoomLoading: true
      };
    }

    case SCHOOL_ROOM_ADD_ROOM_SUCCESS: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_ADD_ROOM_FAILURE: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_UPDATE_ROOM: {
      return {
        ...state,
        schoolRoomLoading: true
      };
    }

    case SCHOOL_ROOM_UPDATE_ROOM_SUCCESS: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_UPDATE_ROOM_FAILURE: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_DELETE_ROOM_LIST: {
      return {
        ...state,
        schoolRoomLoading: true
      };
    }

    case SCHOOL_ROOM_DELETE_ROOM_LIST_SUCCESS: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_DELETE_ROOM_LIST_FAILURE: {
      return {
        ...state,
        schoolRoomLoading: false
      };
    }

    case SCHOOL_ROOM_SYNC_ROOM_LIST: {
      return {
        ...state,
        schoolRoomListLoading: true,
        schoolRoomListLoaded: false
      };
    }

    case SCHOOL_ROOM_SYNC_ROOM_LIST_SUCCESS: {
      return {
        ...state,
        schoolRoomListLoading: false,
        schoolRoomListSynced: true,
        schoolRoomListLoaded: true,
        schoolRoomList: payload
      };
    }

    case SCHOOL_ROOM_SYNC_ROOM_LIST_FAILURE: {
      return {
        ...state,
        schoolRoomListLoading: false,
        schoolRoomListLoaded: false,
        schoolRoomListSynced: false,
        schoolRoomList: null
      };
    }

    case SCHOOL_ROOM_UNSYNC_ROOM_LIST: {
      return {
        ...state,
        schoolRoomListSynced: false,
        schoolRoomListLoaded: false
      };
    }

    default:
      return state;
  }
};

export default School;
