export default {

  // to add more visit https://momentjs.com/docs/#/i18n/changing-locale/

  // en_US momentOveride
  en: {
    calendarTitleFormat: 'MMMM YYYY',
    calendarDateFormat: 'DD',
    calendarLabels: {
      allDay: 'All Day',
      previous: '<',
      next: '>',
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      agenda: 'All',
      date: 'Date',
      time: 'Time',
      event: 'Event',
      showMore: total => `+ more (${total})`
    },
    months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
    weekdaysShort : ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
    yearMonthFormat : 'MMMM YYYY',
    longDateFormat : {
      L: "YYYY/MM/DD"
    },
    week: {
      dow: 1 //Monday is the first day of the week.
    }
  },

  // ja_JP momentOveride
  ja: {
    calendarTitleFormat: 'YYYY年MM月',

    calendarDateFormat: 'D日',
    calendarLabels: {
      allDay: '一日中',
      previous: '<',
      next: '>',
      today: '今日',
      month: '毎月',
      week: '毎週',
      day: '毎日',
      agenda: 'すべて',
      date: '日',
      time: '時間',
      event: 'イベント',
      showMore: total => `+ もっと (${total})`
    },
    months: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    weekdaysShort : ["日","月","火","水","木","金","土"],
    yearMonthFormat : 'YYYY年MM月',
    longDateFormat : {
      L: "YYYY年MM月DD日"
    },
    week: {
      dow: 1 //Monday is the first day of the week.
    }
  },

}
