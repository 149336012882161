import React from 'react';

import IntlMessages from 'util/IntlMessages';

import StudentAvatar from 'components/Avatar/StudentAvatar';


const ConversationRightSidebar = ({data, authUser, locale}) => {
    
    //const studentsArray = Object.values(data.students);
    //const participantsArray = Object.values(data.participantsInfo);


  const participantsArray = [];

  //console.log('data', data)




    return (
      <div className="conversation-sidebar sidebar rightside h-100">

        <div className="student-info">

          <div className="sidebar-header">
            <IntlMessages id="common.student_info"/>
          </div>
          <div className="sidebar-content">
            {Object.values(data.students).map((studentItem, index, array) =>

              studentItem !== true &&
              <div className={array.length >= 1 ? "student-item d-flex mb-3" : "student-item d-flex"} key={index}>
                <StudentAvatar
                  data={studentItem.profile}
                  size={40}
                  classes="mr-2"
                />
                <div className="media-body align-self-center">
                  <div className="d-flex justify-content-between">
                    <div className="heading name text-left">
                      {
                        locale.locale === 'ja' || locale.locale === 'zh'
                          ? `${studentItem.profile.nameFurigana} (${studentItem.profile.lastNameKanji})`
                          : studentItem.profile.nameRomaji
                      }

                    </div>

                  </div>
                  <div className="sub-heading">
                    <p className="sub-heading mb-0 text-left" style={{height:17, overflow: 'hidden'}}>

                      { Object.keys(studentItem.classes).length !== 0 && studentItem.classes.constructor === Object
                        ? Object.values(studentItem.classes).map((classItem, index) => {

                            if(index >= 1){
                              return(
                                locale.locale === 'ja' || locale.locale === 'zh'
                                  ? ' & ' + classItem.profile.nameFurigana
                                  : ' & ' + classItem.profile.nameRomaji
                              )
                            }else{
                              return(
                                locale.locale === 'ja' || locale.locale === 'zh'
                                  ? classItem.profile.nameFurigana
                                  : classItem.profile.nameRomaji
                              )
                            }


                        })
                        : <span><IntlMessages id="common.noClass"/></span>
                      }

                    </p>
                  </div>
                </div>
              </div>

            )}
          </div>

        </div>





      </div>
    )
};

export default ConversationRightSidebar;

/*
<div className="conversation-members-info">
          <div className="sidebar-header">
            <IntlMessages id="common.messengerConversationMembers"/>
          </div>
          <div className="sidebar-content">
            {data.hasGuardians
              ? <div></div>
              : <div><IntlMessages id="common.noGuardians"/></div>
            }


            {participantsArray.map((participantItem, index) =>
              <div className="student-item d-flex mb-3" key={index}>

                  <AvatarDefault
                      src={participantItem.avatarUrlSmall}
                      size={40}
                      classes=" mr-2"
                  />

<div className="media-body align-self-center">
  <p className=" mb-0">{participantItem.nameEn}</p>
  <p className=" mb-0">{participantItem.nameJa}</p>
</div>
</div>
)}
</div>
</div>
    */