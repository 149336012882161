import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import asyncComponent from "util/asyncComponent";
import AlertSystem from "components/AlertSystem";

class AccountRouter extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.auth.user.role !== nextProps.auth.user.role) {
      return true;
    }
    return false;
  }

  render() {
    const { auth } = this.props;

    return (
      <div className="app-main-content">
        <Switch>
          {auth.user.role === "manager" && <Route path={`/account/settings`} component={asyncComponent(() => import("./manager/UserSettings"))} />}
          {auth.user.role === "manager" && <Route path={`/account/security`} component={asyncComponent(() => import("./manager/UserSecurity"))} />}
          {auth.user.role === "manager" && <Route path={`/`} component={asyncComponent(() => import("./manager/userProfile"))} />}

          {auth.user.role === "admin" && <Route path={`/account/settings`} component={asyncComponent(() => import("./admin/UserSettings"))} />}
          {auth.user.role === "admin" && <Route path={`/account/security`} component={asyncComponent(() => import("./admin/UserSecurity"))} />}
          {auth.user.role === "admin" && <Route path={`/`} component={asyncComponent(() => import("./admin/userProfile"))} />}

          {auth.user.role === "teacher" && <Route path={`/`} component={asyncComponent(() => import("./teacher/userProfile"))} />}
          {(auth.user.role === "parent" || auth.user.role === "guardian") && (
            <Route path={`/`} component={asyncComponent(() => import("./guardian/userProfile"))} />
          )}

          <Route component={asyncComponent(() => import("components/Error404"))} />
        </Switch>
        <AlertSystem />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default withRouter(connect(mapStateToProps)(AccountRouter));
