// Admin Routes

export const routesDefault = {
  index: "/",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgotPassword",
  dashboard: "/dashboard",
  error404: "/error",
  unauthorized: "/unauthorized",
  announcements: {
    index: "/announcements"
  },
  messenger: {
    index: "/messenger"
  },
  profile: {
    index: "/profile"
  }
};

export const routesMessenger = {
  index: "/messenger",
  school: "/messenger/s",
  user: "/messenger/u"
};

export const routesAccount = {
  index: "/account",
  profile: {
    index: "/account/profile"
  },
  security: {
    index: "/account/security"
  },
  settings: {
    index: "/account/settings"
  }
};

export const routesManager = {
  index: "/manager",
  overview: {
    index: "/manager/overview"
  },
  posts: {
    index: "/manager/feedPosts"
  },
  announcements: {
    index: "/manager/announcements"
  },
  attendance: {
    index: "/manager/attendance"
  },
  classes: {
    index: "/manager/classes"
  },
  students: {
    index: "/manager/students",
    list: "/manager/students/list",
    todayStudentList: "/manager/students/today",
    tomorrowStudentList: "/manager/students/tomorrow",
    allStudentList: "/manager/students/all",
    absentStudentList: "/manager/students/absent",
    pendingStudentList: "/manager/students/pending",
    archivedStudentList: "/manager/students/archived",
    waitingStudentList: "/manager/students/waiting"
  },
  parents: {
    index: "/manager/parents"
  },
  staff: {
    index: "/manager/staff"
  },
  reports: {
    index: "/manager/reports"
  },
  events: {
    index: "/manager/events"
  },
  invites: {
    index: "/manager/invites"
  },
  messenger: "/messenger",
  school: {
    index: "/manager/school",
    profile: "/manager/school/profile",
    settings: "/manager/school/settings",
    academicYear: "/manager/school/academicyear",
    rooms: "/manager/school/rooms",
  },
  academicyear: {
    index: "/manager/academicyear"
  }
};

export const routesOffice = {
  index: "/office",
  overview: {
    index: "/office/overview"
  },
  announcements: {
    index: "/office/announcements"
  },
  attendance: {
    index: "/office/attendance"
  },
  classes: {
    index: "/office/classes"
  },
  messages: {
    index: "/office/messages"
  }
};