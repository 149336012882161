import { apiUrl } from "../config/firebase";
import axios from "axios";
import {getUserIdToken} from "./firebaseHelpers";


export const postApiData = (url = '', tokenId = '', data = {}) => {

  const fullApiUrl = apiUrl + url;
  return (
    fetch( fullApiUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: new Headers({
        'Authorization': 'Bearer '+tokenId,
        'Content-Type': 'application/json'
      }),
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({ data })
    })
      .then(response => response.json())
      .catch(error => error)
  )
};


export const postApiData2 = (url, type, tokenId, payload) => {

  let headers = {};
  let data = payload;
  if(type === 'multipart/form-data'){
    headers = {
      'Authorization': 'Bearer '+tokenId,
      'Accept': 'application/json',
    };
    data = payload;

  }else{
    headers = {
      'Authorization': 'Bearer '+tokenId,
      'Content-Type': 'application/json',
    };
    data = JSON.stringify(payload);
  }

  const config = {
    baseURL: apiUrl,
    headers: headers
  };

  return axios.post(url, data, config);
};

export const patchApiData2 = (url, type, tokenId, payload) => {

  let headers = {};
  let data = payload;
  if(type === 'multipart/form-data'){
    headers = {
      'Authorization': 'Bearer '+tokenId,
      'Accept': 'application/json',
    };
    data = payload;

  }else{
    headers = {
      'Authorization': 'Bearer '+tokenId,
      'Content-Type': 'application/json',
    };
    data = JSON.stringify(payload);
  }

  const config = {
    baseURL: apiUrl,
    headers: headers
  };

  return axios.patch(url, data, config);
};



export const deleteApiData2 = (url, tokenId) => {

  let headers = {
    'Authorization': 'Bearer '+tokenId,
    'Accept': 'application/json',
  };

  const config = {
    baseURL: apiUrl,
    headers: headers
  };

  return axios.delete(url, config)
    .then(response => {
      return response;
    })
    .catch(response => {
      return response.response.data;
    })
};

/*
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    route: string,
    token: string | null,
    data: string | object = null,
    isFile: boolean = false,
    onUploadProgress?: (val) => void
 */
export const apiCall = async (
    method,
    route,
    token= null,
    data= null,
    isFile = false,
    onUploadProgress
) => {
  const onSuccess = function (response) {
    return response.data;
  };
  const onError = function (error) {
    return Promise.reject(
        error.response.data.error || error.response.data || error.message
    );
  };

  if(!token){
    token = await getUserIdToken();
  }
  let headers;

  if(isFile){
    headers = {
      'Authorization': 'Bearer '+ token,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    };
  }else{
    headers = {
      'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/json',
    };
  }


  return axios.request({
    method,
    baseURL: apiUrl,
    url: route,
    data: data,
    headers: headers,
    responseType: "json",
    transformRequest: (data) => {
      const stringifyData = data ? JSON.stringify(data) : null;
      return isFile ? data : stringifyData;
    },
    onUploadProgress: onUploadProgress
  })
      .then(onSuccess)
      .catch(onError);
};
