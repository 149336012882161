// import appLocaleData from 'react-intl/locale-data/ja';
import jaMessages from '../locales/ja_JP.json';

const JaLang = {
    messages: {
        ...jaMessages
    },
    locale: 'ja-JP',
    // data: appLocaleData
};
export default JaLang;
