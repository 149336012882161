import {
    //FIXED_DRAWER,
    //MINI_DRAWER,
    COLLAPSED_DRAWER,
    INSIDE_THE_HEADER,
    //BELOW_THE_HEADER,
    VERTICAL_NAVIGATION,
    //HORIZONTAL_NAVIGATION
} from 'redux/constants/ActionTypes';
import {
    //DARK_DEEP_ORANGE,
    DARK_GREEN,
    //GREEN
} from 'redux/constants/ThemeColors';
import {languageFallback, languageSwitcherList} from './language';

import packageData from "../../package.json";

function timestampNow() {
    const now = new Date();
    return now.getTime();
}


const rltLocale = ['ar'];
const initialSettings = {
    navCollapsed: true,
    drawerType: COLLAPSED_DRAWER,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: VERTICAL_NAVIGATION,
    //navigationStyle: HORIZONTAL_NAVIGATION,
    horizontalNavPosition: INSIDE_THE_HEADER,
    //horizontalNavPosition: BELOW_THE_HEADER,
    locale: languageSwitcherList[languageFallback],
    tourStart: false,
    themeColor: DARK_GREEN,
    sideNavColor: '',
    darkTheme: false,
    timeStampNow: timestampNow(),
    showAlert: false,
    alertType: '',
    alertMessage: '',
    alertMessageExtra: ''
};

const colorSettings = {
    defaultColor: 'dark-green',
    managerColor: 'dark-deep-purple',
    teacherColor: 'dark-blue',
    parentColor: 'dark-orange',
};

/*
  "version": "2.0.1",
  "description": "2020.1.26",
 */
const version = {
    date: packageData.description,
    version: packageData.version
}

export {
    version,
    initialSettings,
    rltLocale,
    colorSettings
};