import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import asyncComponent from "util/asyncComponent";
import { routesManager } from "config/routes";

class ManagerRouter extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('manager.index.js did update')
  };


  render() {
    //const {auth} = this.props;

    return (
      <div className="app-main-content">
        <Switch>
          <Route exact path={routesManager.classes.index} component={asyncComponent(() => import("./ClassesManager"))} />
          <Route exact path={routesManager.school.index} component={asyncComponent(() => import("./SchoolManager/SchoolProfile"))} />
          <Route exact path={routesManager.school.profile} component={asyncComponent(() => import("./SchoolManager/SchoolProfile"))} />
          <Route exact path={routesManager.school.settings} component={asyncComponent(() => import("./SchoolManager/SchoolSettings"))} />
          <Route exact path={routesManager.school.academicYear} component={asyncComponent(() => import("./SchoolManager/AcademicYearManager"))} />
          <Route exact path={routesManager.school.rooms} component={asyncComponent(() => import("./SchoolManager/Rooms"))} />

          <Route exact path={routesManager.posts.index} component={asyncComponent(() => import("./PostsManager"))} />
          <Route exact path={routesManager.announcements.index} component={asyncComponent(() => import("./AnnouncementManager"))} />
          <Route exact path={routesManager.students.index} component={asyncComponent(() => import("./StudentManager"))} />
          <Route exact path={routesManager.attendance.index} component={asyncComponent(() => import("./AttendanceManager"))} />
          <Route exact path={routesManager.events.index} component={asyncComponent(() => import("./EventsManager"))} />
          <Route exact path={'/manager/users'} component={asyncComponent(() => import("./UserManager"))} />

          <Route component={asyncComponent(() => import("components/Error404"))} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default withRouter(connect(mapStateToProps)(ManagerRouter));
