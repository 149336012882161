import { all, call, fork, put, takeEvery, take, cancel } from "redux-saga/effects";
import { rsf } from "config/firebase";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import moment from "moment";
import {deleteApiData2} from 'util/apiHelpers';
import {
  deleteDBRequest,
  getUserIdToken,
  setDBRequest,
} from "../../util/firebaseHelpers";

import {
  STUDENT_FETCH_STUDENT_LIST_ACTIVE,
  STUDENT_SYNC_STUDENT_LIST_ACTIVE,
  STUDENT_UNSYNC_STUDENT_LIST_ACTIVE,
  STUDENT_SYNC_STUDENT_LIST_ARCHIVED,
  STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED,
  STUDENT_CREATE_STUDENT,
  STUDENT_UPDATE_STUDENT,
  STUDENT_DELETE_STUDENTS,
  STUDENT_ARCHIVE_STUDENTS,
  STUDENT_UNARCHIVE_STUDENTS,
  STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST,
} from "redux/constants/ActionTypes";

import {
  studentShowAlertMessage,
  studentFetchStudentListActiveSuccess,
  studentFetchStudentListActiveFailure,
  studentSyncStudentListActiveSuccess,
  studentSyncStudentListActiveFailure,
  studentSyncStudentListArchivedSuccess,
  studentSyncStudentListArchivedFailure,
  studentCreateStudentSuccess,
  studentCreateStudentFailure,
  studentDeleteStudentsSuccess,
  studentDeleteStudentsFailure,
  studentUpdateStudentSuccess,
  studentUpdateStudentFailure,
  studentArchiveStudentsSuccess,
  studentArchiveStudentsFailure,
  studentUnArchiveStudentsSuccess,
  studentUnArchiveStudentsFailure,
  studentFetchSelectedStudentParentListSuccess,
  studentFetchSelectedStudentParentListFailure
} from "redux/actions/Student";
import { showAlert } from "redux/actions/Setting";
import {apiCall} from "../../util/apiHelpers";

// FUNCTIONS
function* fetchStudentListActive({ payload }) {
  const { schoolId } = payload;
  try {
    const studentListActive = yield call(
      rsf.database.read,
      firebase
        .database()
        .ref(`/student/${schoolId}`)
        .orderByChild("metadata/active")
        .equalTo(true)
    );
    yield put(
      studentFetchStudentListActiveSuccess({
        studentListActive: studentListActive
      })
    );
  } catch (error) {
    const alertNotification = {
      alertType: "error",
      alertMessage: "alertMessage.common_error"
    };
    yield put(studentShowAlertMessage(alertNotification));
    yield put(studentFetchStudentListActiveFailure({ error }));
    console.error("SAGA-Student-fetchStudentListActive:", error);
  }
}


function* createStudent({ payload }) {
  let { cid, schoolId, createdStudent } = payload;
  try {
    const studentId = yield call(rsf.database.create, `/student/${schoolId}/`, {});
    createdStudent["id"] = studentId;
    createdStudent["metadata"]["id"] = studentId;
    createdStudent["cid"] = cid;
    createdStudent["metadata"]["cid"] = cid;
    yield call(rsf.database.update, `/student/${schoolId}/${studentId}`, { ...createdStudent });
    yield put(
      studentCreateStudentSuccess({
        createdStudent
      })
    );
    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_create_success"
      })
    );

  } catch (error) {
    yield put(studentCreateStudentFailure({ error }));
    console.log("ERROR:", error);
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* updateStudent({ payload }) {
  let { studentId, schoolId, updatedStudent, avatarImage, addRemoveClasses, prevAvatar } = payload;
  try {
    const tokenId = yield call(getUserIdToken);
    if (avatarImage) {
      const formData = new FormData();
      formData.append('avatar', avatarImage.file);
      const apiUrl = `/v1/file/${schoolId}/upload/avatar`;
      const fileData = yield call(apiCall, 'POST', apiUrl, tokenId, formData, true);
      if(fileData.files.length > 0){
        updatedStudent.profile.avatar = fileData.files[0];
      }
    }

    yield call(rsf.database.patch, `/student/${schoolId}/${studentId}`, {
      cid: updatedStudent.cid,
      id: updatedStudent.id,
      medical: updatedStudent.medical,
      metadata: updatedStudent.metadata,
      profile: updatedStudent.profile,
    });

    if (addRemoveClasses.addList.length > 0) {
      yield all(addRemoveClasses.addList.map(key => call(setDBRequest, `classes/${schoolId}/${key}/metadata/students/${studentId}`, true)));
    }

    if (addRemoveClasses.removeList.length > 0) {
      yield all(addRemoveClasses.removeList.map(key => call(deleteDBRequest, `classes/${schoolId}/${key}/metadata/students/${studentId}`)));
    }

    yield put(
      studentUpdateStudentSuccess({
        ...updatedStudent
      })
    );

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_update_success"
      })
    );

    updatedStudent.metadata.active && payload.callback && payload.callback();
  } catch (error) {
    yield put(studentUpdateStudentFailure({ error }));
    console.log("ERROR:", error);
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* deleteStudents({ payload }) {
  const { sid, studentsList } = payload;



  //console.log('payload', payload)

  //console.log('studentsList', studentsList)

  //deleteApiData2
  //url, tokenId
  //yield call(patchApiData, `/school/${sid}/family/${fid}/profile`, 'multipart/form-data', tokenId, formProfileData);

  try {
    const tokenId = yield call(getUserIdToken);

    //yield call(deleteApiData2, `/school/${sid}/student/${studentId}`, tokenId);


    const responses = yield all(studentsList.map(studentItem => {
    const studentId = studentItem.id;
      return call(deleteApiData2, `/school/${sid}/student/${studentId}`, tokenId)
    }));


    //console.log('responses',responses)

   yield put(studentDeleteStudentsSuccess({}));






    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_delete_success"
      })
    );
  } catch (error) {
    yield put(studentDeleteStudentsFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }


  /*

  const deletedStudents = studentsList.reduce(
    (acc, student) =>
      Object.assign(acc, {
        [student.id]: null
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/student/${schoolId}/`, deletedStudents);

    yield put(studentDeleteStudentsSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_delete_success"
      })
    );
  } catch (error) {
    yield put(studentDeleteStudentsFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
  */



}

function* archiveStudents({ payload }) {
  const { schoolId, studentsList } = payload;

  const archived_at = moment()
    .utc()
    .valueOf();

  const archivedStudents = studentsList.reduce(
    (acc, student) =>
      Object.assign(acc, {
        [student.id]: {
          ...student,
          metadata: {
            ...student.metadata,
            active: false,
            archived_at
          }
        }
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/student/${schoolId}/`, archivedStudents);

    yield put(studentArchiveStudentsSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_archive_success"
      })
    );
  } catch (error) {
    yield put(studentArchiveStudentsFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

function* unArchiveStudents({ payload }) {
  const { schoolId, studentsList } = payload;

  const unArchivedStudents = studentsList.reduce(
    (acc, student) =>
      Object.assign(acc, {
        [student.id]: {
          ...student,
          metadata: {
            ...student.metadata,
            active: true,
            archived_at: ""
          }
        }
      }),
    {}
  );

  try {
    yield call(rsf.database.patch, `/student/${schoolId}/`, unArchivedStudents);

    yield put(studentUnArchiveStudentsSuccess({}));

    yield put(
      showAlert({
        alertType: "success",
        alertMessage: "alertMessage.student_unarchive_success"
      })
    );
  } catch (error) {
    yield put(studentUnArchiveStudentsFailure({ error }));
    yield put(
      showAlert({
        alertType: "error",
        alertMessage: "alertMessage.common_error"
      })
    );
  }
}

// EXPORTS

// SYNCS
function* syncStudentListActive({ payload }) {
  const { schoolId } = payload;
  const sync = yield fork(
    rsf.database.sync,
    firebase
      .database()
      .ref(`/student/${schoolId}`)
      .orderByChild("metadata/active")
      .equalTo(true),
    {
      successActionCreator: studentSyncStudentListActiveSuccess,
      failureActionCreator: studentSyncStudentListActiveFailure
    }
  );

  // On unsync request, cancel sync.
  yield take(STUDENT_UNSYNC_STUDENT_LIST_ACTIVE);
  yield cancel(sync);
}

function* syncStudentListArchived({ payload }) {
  const { schoolId } = payload;

  const sync = yield fork(
    rsf.database.sync,
    firebase
      .database()
      .ref(`/student/${schoolId}`)
      .orderByChild("metadata/active")
      .equalTo(false),
    {
      successActionCreator: studentSyncStudentListArchivedSuccess,
      failureActionCreator: studentSyncStudentListArchivedFailure
    }
  );
  // On unsync request, cancel sync.
  yield take(STUDENT_UNSYNC_STUDENT_LIST_ARCHIVED);
  yield cancel(sync);
}





//STUDENT_PARENT_LIST




function* fetchSelectedStudentParentList({ payload }) {

  const { schoolId, parentIdList } = payload
  const parentIdListArray = Object.keys(parentIdList)

  try {

    const selectedStudentParentIdListArrayPromises = parentIdListArray.map(async id => {
      let result = [];
      await firebase
        .database()
        .ref(`/schoolUsers/${schoolId}/${id}`)
        .once('value', s => {
          result = s.val()
        });
      return result;
    });

    const selectedStudentParentIdListArray = yield Promise.all(selectedStudentParentIdListArrayPromises)

    console.log("selectedStudentParentIdListArray", selectedStudentParentIdListArray)

    yield put(
      studentFetchSelectedStudentParentListSuccess({
        parentList: selectedStudentParentIdListArray
      })
    );
  } catch (error) {
    const alertNotification = {
      alertType: "error",
      alertMessage: "alertMessage.common_error"
    };
    yield put(studentShowAlertMessage(alertNotification));
    yield put(studentFetchSelectedStudentParentListFailure({ error }));
    console.error("SAGA-Student-fetchSelectedStudentParentList:", error);
  }
}

// WATCHERS
export default function* rootSaga() {
  yield all([
    takeEvery(STUDENT_FETCH_STUDENT_LIST_ACTIVE, fetchStudentListActive),
    takeEvery(STUDENT_SYNC_STUDENT_LIST_ACTIVE, syncStudentListActive),
    takeEvery(STUDENT_SYNC_STUDENT_LIST_ARCHIVED, syncStudentListArchived),
    takeEvery(STUDENT_CREATE_STUDENT, createStudent),
    takeEvery(STUDENT_DELETE_STUDENTS, deleteStudents),
    takeEvery(STUDENT_UPDATE_STUDENT, updateStudent),
    takeEvery(STUDENT_ARCHIVE_STUDENTS, archiveStudents),
    takeEvery(STUDENT_UNARCHIVE_STUDENTS, unArchiveStudents),
    takeEvery(STUDENT_FETCH_SELECTED_STUDENT_PARENT_LIST, fetchSelectedStudentParentList),


  ]);
}
