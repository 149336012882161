import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import {routesManager, routesDefault, routesAccount} from 'config/routes';
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';

class SidemenuContent extends Component {
    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.history.location.pathname !== prevProps.history.location.pathname){

            const {history} = this.props;
            const pathname = `${history.location.pathname}`;// get current path

            const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
            try {
                const activeNav = this.closest(activeLi, 'ul'); // select closest ul
                if (activeNav.classList.contains('sub-menu')) {
                    this.closest(activeNav, 'li').classList.add('open');
                } else {
                    this.closest(activeLi, 'li').classList.add('open');
                }
            } catch (error) {

            }
        }
    }



    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {

        let role = null;
        if(this.props.auth.user){
            role = this.props.auth.user.role;
        }


        return (
            <CustomScrollbars className="scrollbar">
                <ul className="nav-menu">
                    {
                        /*
                         <li className="nav-header"><IntlMessages id="sidebar.main"/></li>
                        */

                    }


                    {(role === 'manager') &&
                    <li className="nav-header">Manager</li>
                    }
                    {(role === 'parent' || role === 'guardian') &&
                    <li className="nav-header">parent</li>
                    }

                    <li className="menu no-arrow">
                        <NavLink to={routesDefault.dashboard}>
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="sidemenu.myDashboard"/></span>
                        </NavLink>
                    </li>

                    <li className="menu no-arrow">
                        <NavLink to={'/messenger'}>
                            <i className="zmdi zmdi-email zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="sidemenu.messages"/></span>
                        </NavLink>
                    </li>


                    {(role === 'admin2' || role === 'sysadmin') &&
                    <li className="menu">
                        <Button className="void">
                            <i className="zmdi zmdi-folder zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="appManager.menu.title"/></span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.overview.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.index.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.announcements.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.announcements.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.attendance.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.attendance.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li className="menu">

                                <Button className="void" >
                                    <span className="nav-text"><IntlMessages id="manager.classesManager_menuTitle"/></span>
                                </Button>
                                <ul className="sub-menu">
                                    <li>
                                        <NavLink className="prepend-icon" to={routesManager.classes.index}>
                                            <span className="nav-text">Overview</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/app">
                                            <span className="nav-text">Today</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/app">
                                            <span className="nav-text">By Week</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/app">
                                            <span className="nav-text">By Month</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/app">
                                            <span className="nav-text">My Schedule</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.students.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.students.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.parents.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.parents.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.staff.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.staff.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.reports.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.reports.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.events.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.events.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.invites.index}>
                                    <span className="nav-text"><IntlMessages id="appManager.invites.menu.title"/></span>
                                </NavLink>
                            </li>
                            <li className="menu">
                                <Button className="void" >
                                    <span className="nav-text">School Settings</span>
                                </Button>
                                <ul className="sub-menu">
                                    <li>
                                        <NavLink className="prepend-icon" to="/admin/school/profile">
                                            <span className="nav-text">School Profile</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/admin/app">
                                            <span className="nav-text">School Rooms</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="prepend-icon" to="/admin/app">
                                            <span className="nav-text">Vacation Schedule</span>
                                        </NavLink>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </li>
                    }


                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.posts.index}>
                            <i className="zmdi zmdi-book-image zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.postsManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }

                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.announcements.index}>
                            <i className="zmdi zmdi-smartphone-ring zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.announcementManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }

                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.attendance.index}>
                            <i className="zmdi zmdi-badge-check zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.attendanceManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }

                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.students.index}>
                            <i className="zmdi zmdi-accounts-list zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.studentManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }



                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.classes.index}>
                            <i className="zmdi zmdi zmdi-library zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.classesManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }

                    {(role === 'manager' || role === 'admin') &&
                    <li className="menu no-arrow">
                        <NavLink className="prepend-icon" to={routesManager.events.index}>
                            <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.eventsManager_menuTitle"/></span>
                        </NavLink>
                    </li>
                    }




                    <li className="menu">
                        <Button className="void">
                            <i className="zmdi zmdi-graduation-cap zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="manager.schoolManager_menuTitle"/></span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.school.index}>
                                    <i className="zmdi zmdi-account-box"/>
                                    <span className="nav-text"><IntlMessages id="manager.schoolManager_profile_menuTitle"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.school.rooms}>
                                    <i className="zmdi zmdi-bookmark"></i>
                                    <span className="nav-text"><IntlMessages id="manager.schoolManager_rooms_menuTitle"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.school.academicYear}>
                                    <i className="zmdi zmdi-calendar-check"/>
                                    <span className="nav-text"><IntlMessages id="manager.academicYearManager_menuTitle"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={'/manager/users'}>
                                    <i className="zmdi zmdi-accounts-list"/>
                                    <span className="nav-text"><IntlMessages id="manager.userManager_menuTitle"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesManager.school.settings}>
                                    <i className="zmdi zmdi-settings"/>
                                    <span className="nav-text"><IntlMessages id="sidemenu.myAccount_settings"/></span>
                                </NavLink>
                            </li>

                        </ul>
                    </li>

                    <li className="menu">
                        <Button className="void">
                            <i className="zmdi zmdi-account-circle zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="sidemenu.myAccount"/></span>
                        </Button>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to={routesAccount.profile.index}>
                                    <i className="zmdi zmdi-account-box"/>
                                    <span className="nav-text"><IntlMessages id="sidemenu.myAccount_profile"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesAccount.security.index}>
                                    <i className="zmdi zmdi-shield-security"></i>
                                    <span className="nav-text"><IntlMessages id="sidemenu.myAccount_security"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={routesAccount.settings.index}>
                                    <i className="zmdi zmdi-settings"/>
                                    <span className="nav-text"><IntlMessages id="sidemenu.myAccount_settings"/></span>
                                </NavLink>
                            </li>

                        </ul>
                    </li>

                    {
                        /*
                        <li className="menu">
                        <Button className="void" >
                            <i className="zmdi zmdi-folder zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="sidemenu.manager_sectionTitle"/></span>
                        </Button>
                        <ul className="sub-menu">

                            <li>
                                <NavLink className="prepend-icon" to={routesOffice.messages.index}>
                                    <span className="nav-text"><IntlMessages id="sidemenu.messages"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.announcements.index}>
                                    <span className="nav-text"><IntlMessages id="manager.announcementManager_menuTitle"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.attendance.index}>
                                    <span className="nav-text"><IntlMessages id="manager.attendanceManager_menuTitle"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.students.index}>
                                    <span className="nav-text"><IntlMessages id="manager.studentManager_menuTitle"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.school.index}>
                                    <span className="nav-text"><IntlMessages id="manager.schoolManager_menuTitle"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.classes.index}>
                                    <span className="nav-text"><IntlMessages id="manager.classesManager_menuTitle"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon" to={routesManager.events.index}>
                                    <span className="nav-text"><IntlMessages id="manager.eventsManager_menuTitle"/></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                         */
                    }











                </ul>
            </CustomScrollbars>
        );
    }
}


const mapStateToProps = ({auth}) => {
    return {auth}
};

export default withRouter(connect(mapStateToProps, {

})(SidemenuContent));

