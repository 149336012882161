import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {logout} from 'redux/actions/Auth';
import IntlMessages from 'util/IntlMessages';

import {NavLink} from 'react-router-dom';
import {routesAccount} from 'config/routes';

class SidemenuHeader extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {

    const {auth, locale} = this.props;

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {auth.userProfile.avatarUrl || auth.userProfile.avatarUrl === ''
          ? <Avatar
            src={auth.userProfile.avatarUrl}
            className="user-avatar "
          />
          : <Avatar className="user-avatar">{auth.userProfile.initials}</Avatar>
        }

        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {locale.locale === 'ja'
              ? auth.userProfile.nameFurigana
              : auth.userProfile.nameRomaji
            }
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
          </h4>
        </div>
        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}
        >

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink className="prepend-icon" to={routesAccount.profile.index}>
              <i className="zmdi zmdi-account-box zmdi-hc-fw mr-2"/>
              <IntlMessages id="sidemenu.myAccount_profile"/>
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink className="prepend-icon" to={routesAccount.security.index}>
              <i className="zmdi zmdi-shield-security zmdi-hc-fw mr-2"></i>
             <IntlMessages id="sidemenu.myAccount_security"/>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={this.handleRequestClose}>
            <NavLink className="prepend-icon" to={routesAccount.settings.index}>
              <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
              <IntlMessages id="sidemenu.myAccount_settings"/>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.logout()
          }}>
            <NavLink className="prepend-icon" to="/#">
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
              <IntlMessages id="common.logout"/>
            </NavLink>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale} = settings;
  return {locale, auth}
};
export default connect(mapStateToProps, {logout})(SidemenuHeader);


