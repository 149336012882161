import firebase from "firebase/app";
import ReduxSagaFirebase from "redux-saga-firebase";

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABSE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const apiUrl = process.env.REACT_APP_FIREBASE_APIURL;

let firebaseApp = null;

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(fbConfig);
}

const rsf = new ReduxSagaFirebase(firebaseApp);

export { firebase, rsf, fbConfig, apiUrl };
