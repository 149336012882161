const languageFallback = "ja";

const languageSwitcherList = {
  en: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
    region: "US",
    translation: {
      ja: "英語",
      en: "English"
    },
    active: true
  },
  ja: {
    languageId: "japanese",
    locale: "ja",
    name: "日本語",
    icon: "jp",
    region: "JP",
    translation: {
      ja: "日本語",
      en: "Japanese"
    },
    active: true
  },
  zh: {
    languageId: "chinese",
    locale: "zh",
    name: "Chinese",
    icon: "cn",
    region: "CN",
    translation: {
      ja: "Chinese",
      en: "Chinese"
    },
    active: false
  }
};

const languageSwitcherDropdownStyle = {
  height: 80
};

export { languageFallback, languageSwitcherList, languageSwitcherDropdownStyle };
