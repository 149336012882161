import {
  CLASS_FETCH_CLASS,
  CLASS_FETCH_CLASS_SUCCESS,
  CLASS_FETCH_CLASS_FAILURE,
  CLASS_LOAD_CLASS,
  CLASS_LOAD_CLASS_SUCCESS,
  CLASS_LOAD_CLASS_FAILURE,
  CLASS_CREATE_CLASS,
  CLASS_CREATE_CLASS_SUCCESS,
  CLASS_CREATE_CLASS_FAILURE,
  CLASS_UPDATE_CLASS,
  CLASS_UPDATE_CLASS_SUCCESS,
  CLASS_UPDATE_CLASS_FAILURE,
  CLASS_DELETE_CLASS,
  CLASS_DELETE_CLASS_SUCCESS,
  CLASS_DELETE_CLASS_FAILURE,
  CLASS_FETCH_CLASS_LIST_TODAY,
  CLASS_FETCH_CLASS_LIST_TODAY_SUCCESS,
  CLASS_FETCH_CLASS_LIST_TODAY_FAILURE,
  CLASS_FETCH_CLASS_LIST_ACTIVE,
  CLASS_FETCH_CLASS_LIST_ACTIVE_SUCCESS,
  CLASS_FETCH_CLASS_LIST_ACTIVE_FAILURE,
  CLASS_SYNC_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ACTIVE_SUCCESS,
  CLASS_SYNC_CLASS_LIST_ACTIVE_FAILURE,
  CLASS_UNSYNC_CLASS_LIST_ACTIVE,
  CLASS_SYNC_CLASS_LIST_ARCHIVED,
  CLASS_SYNC_CLASS_LIST_ARCHIVED_SUCCESS,
  CLASS_SYNC_CLASS_LIST_ARCHIVED_FAILURE,
  CLASS_UNSYNC_CLASS_LIST_ARCHIVED,
  CLASS_SYNC_CLASS_STUDENT_LIST,
  CLASS_SYNC_CLASS_STUDENT_LIST_SUCCESS,
  CLASS_SYNC_CLASS_STUDENT_LIST_FAILURE,
  CLASS_UNSYNC_CLASS_STUDENT_LIST
} from "redux/constants/ActionTypes";

const initialSettings = {
  loaded: false,
  loading: false,
  selectedClass: {},

  classListActiveLoading: {},
  classListActiveLoaded: {},
  classListActive: {},

  classListActiveSynced: false,

  classListArchivedLoading: {},
  classListArchivedLoaded: {},
  classListArchived: {},

  classListArchivedSynced: false,

  classListTodayLoading: false,
  classListTodayLoaded: false,
  classListToday: {},

  alertType: "",

  alertMessage: "",
  showAlert: false
  /*
  loaded: false,
  loading: false,
  selectedClass: {
    classProfile: null,
    classMetadata: null,
    studentList: {},
    teacherList: {}
  },
  classListActive: {}
  classListArchived: {}
  alertType: '',
  alertMessage: '', (error-message),
  showAlert: false
  */
};

const Classes = (state = initialSettings, { type, payload }) => {
  switch (type) {
    case CLASS_FETCH_CLASS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CLASS_FETCH_CLASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        selectedClass: payload.selectedClass
      };
    }

    case CLASS_FETCH_CLASS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case CLASS_LOAD_CLASS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CLASS_LOAD_CLASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        selectedClass: payload.selectedClass
      };
    }

    case CLASS_LOAD_CLASS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        alertMessage: payload.error
      };
    }

    case CLASS_CREATE_CLASS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CLASS_CREATE_CLASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
        // selectedClass: payload.createdClass
      };
    }

    case CLASS_CREATE_CLASS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case CLASS_UPDATE_CLASS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CLASS_UPDATE_CLASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
        // updatedClass: payload.updatedClass
      };
    }

    case CLASS_UPDATE_CLASS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case CLASS_DELETE_CLASS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case CLASS_DELETE_CLASS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
        // selectedClass: payload.selectedClass
      };
    }

    case CLASS_DELETE_CLASS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payload.error
      };
    }

    case CLASS_FETCH_CLASS_LIST_TODAY: {
      return {
        ...state,
        classListTodayLoading: true,
        classListTodayLoaded: false
      };
    }
    case CLASS_FETCH_CLASS_LIST_TODAY_SUCCESS: {
      return {
        ...state,
        classListTodayLoading: false,
        classListTodayLoaded: true,
        classListToday: payload.classListToday
      };
    }

    case CLASS_FETCH_CLASS_LIST_TODAY_FAILURE: {
      return {
        ...state,
        classListTodayLoading: false,
        classListTodayLoaded: false,
        classListToday: {}
      };
    }

    case CLASS_FETCH_CLASS_LIST_ACTIVE: {
      return {
        ...state,
        classListActiveLoading: true,
        classListActiveLoaded: false
      };
    }

    case CLASS_FETCH_CLASS_LIST_ACTIVE_SUCCESS: {
      return {
        ...state,
        classListActiveLoading: false,
        classListActiveLoaded: true,
        classListActive: payload.classListActive
      };
    }

    case CLASS_FETCH_CLASS_LIST_ACTIVE_FAILURE: {
      return {
        ...state,
        classListActiveLoading: false,
        classListActiveLoaded: false,
        error: payload.error
      };
    }

    case CLASS_SYNC_CLASS_LIST_ACTIVE: {
      return {
        ...state,
        classListActiveLoading: true,
        classListActiveLoaded: false
      };
    }

    case CLASS_SYNC_CLASS_LIST_ACTIVE_SUCCESS: {
      return {
        ...state,
        classListActiveLoading: false,
        classListActiveLoaded: true,
        classListActiveSynced: true,
        classListActive: payload
      };
    }

    case CLASS_SYNC_CLASS_LIST_ACTIVE_FAILURE: {
      return {
        ...state,
        classListActiveLoading: false,
        classListActiveLoaded: false,
        classListActiveSynced: false
      };
    }

    case CLASS_UNSYNC_CLASS_LIST_ACTIVE: {
      return {
        ...state,
        classListActiveLoading: false,
        classListActiveLoaded: false,
        classListActiveSynced: false
      };
    }

    case CLASS_SYNC_CLASS_LIST_ARCHIVED: {
      return {
        ...state,
        classListArchivedLoading: true,
        classListArchivedLoaded: false
      };
    }

    case CLASS_SYNC_CLASS_LIST_ARCHIVED_SUCCESS: {
      return {
        ...state,
        classListArchivedLoading: false,
        classListArchivedLoaded: true,
        classListArchivedSynced: true,
        classListArchived: payload
      };
    }

    case CLASS_SYNC_CLASS_LIST_ARCHIVED_FAILURE: {
      return {
        ...state,
        classListArchivedLoading: false,
        classListArchivedLoaded: false,
        classListArchivedSynced: false
      };
    }

    case CLASS_UNSYNC_CLASS_LIST_ARCHIVED: {
      return {
        ...state,
        classListArchivedLoading: false,
        classListArchivedLoaded: false,
        classListArchivedSynced: false
      };
    }

    case CLASS_SYNC_CLASS_STUDENT_LIST: {
      return {
        ...state,
        classStudentListLoading: true,
        classStudentListLoaded: false
      };
    }

    case CLASS_SYNC_CLASS_STUDENT_LIST_SUCCESS: {
      return {
        ...state,
        classStudentListLoading: false,
        classStudentListLoaded: true,
        selecetedClass: {
          ...state.selecetedClass,
          classStudentList: payload
        }
      };
    }

    case CLASS_SYNC_CLASS_STUDENT_LIST_FAILURE: {
      return {
        ...state,
        classStudentListLoading: false,
        classStudentListLoaded: false
      };
    }

    case CLASS_UNSYNC_CLASS_STUDENT_LIST: {
      return {
        ...state,
        classStudentListLoading: false,
        classStudentListLoaded: false
      };
    }

    default:
      return state;
  }
};

export default Classes;
