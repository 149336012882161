import enLang from './entries/en_US';
import jaLang from './entries/ja_JP';
//import {addLocaleData} from 'react-intl';

const AppLocale = {
  en: enLang,
  ja: jaLang
};

//addLocaleData(AppLocale.ja.data);
//addLocaleData(AppLocale.en.data);

export default AppLocale;
